<div class="col-12">
  <div class="card">
    <div class="filters">
    </div>
    <div class="table-responsive">
      <div class="row">

        <div class="col-12">
          <table class="table card-table table-vcenter text-nowrap table-hover">
            <thead>
            <tr>
              <th>Счет</th>
              <th>Наименование товара</th>
              <th>Количество</th>
              <th>Отгружено</th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isLoading">
              <td colspan="5">
                <app-loading-message>
                  Получение списка позиций
                </app-loading-message>
              </td>
            </tr>
            <ng-container *ngIf="!isLoading">
              <ng-container *ngFor="let item of orderLines; let i = index">
                <tr>
                  <td (click)="navigateOrderDetail(item.order.id)">
                    <i *ngIf="item.is_with_issues" class="fas fa-exclamation text-red"></i>
                    {{item.order.order_number}}
                  </td>
                  <td>
                    {{item.product.title}}
                  </td>
                  <td>
                    {{item.quantity}}
                  </td>
                  <td>
                    {{item.shipped_quantity}}
                  </td>
                </tr>
              </ng-container>
              <tr *ngIf="!orderLines.length">
                <td class="text-center">
                  Список пуст
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>

      </div>
    </div>

    <ngb-pagination class="d-flex justify-content-center" (pageChange)="paginationPage = $event" [pageSize]="pagination.per_page" [(page)]="pagination.current_page" [collectionSize]="pagination.count" [maxSize]="5"></ngb-pagination>

  </div>
</div>
