import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingMessageModule} from '../../shared/loading-message/loading-message.module';
import {NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { PaymentsListComponent } from './payments-list/payments-list.component';
import {PaymentsListRoutingModule} from './payments-list-routing.module';
import {PaymentsCreateModalComponent} from './payments-create-modal/payments-create-modal.component';
import {DirectivesModule} from '../../shared/directives/directives.module';
import {PaymentsFilterComponent} from './payments-filter/payments-filter.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {PaymentsUploadModalComponent} from './payments-upload-modal/payments-upload-modal.component';

@NgModule({
  imports: [
    CommonModule,
    LoadingMessageModule,
    DirectivesModule,
    FormsModule,
    PaymentsListRoutingModule,
    NgbPaginationModule,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
  ],
  declarations: [
    PaymentsListComponent,
    PaymentsCreateModalComponent,
    PaymentsUploadModalComponent,
    PaymentsFilterComponent,
  ],
})
export class PaymentsListModule {
}
