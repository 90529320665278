import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {LoadingMessageComponent} from './loading-message.component';

@NgModule({
  declarations: [
    LoadingMessageComponent,
  ],
  exports: [
    LoadingMessageComponent,
  ],
  imports: [
    CommonModule,
  ],
})
export class LoadingMessageModule {
}
