import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {NotifyMessageService} from './notify-message.service';
import {NotifyMessageComponent} from './notify-message.component';

@NgModule({
  declarations: [
    NotifyMessageComponent
  ],
  exports: [
    NotifyMessageComponent,
  ],
  providers: [
    NotifyMessageService,
  ],
  imports: [
    CommonModule,
  ]
})
export class NotifyMessageModule { }
