<div class="col-12">
  <div class="card">

    <div class="filters">
      <app-issue-filter></app-issue-filter>
    </div>

    <div class="card-header justify-content-between">
      <h3 class="card-title">Проблемы</h3>
      <div class="card-options">
        <button (click)="openCreateModal()" type="button" class="btn btn-outline-info mr-4">
          <i class="fe fe-plus-square"></i> Добавить запись
        </button>
      </div>

    </div>
    <div class="table-responsive">
      <table class="table card-table table-vcenter text-nowrap w-100">
        <thead>
        <tr>
          <th>Дата создания</th>
          <th>Описание</th>
          <th>Счет</th>
          <th>Контрольная дата</th>
          <th>Автор</th>
          <th>Исполнитель</th>
          <th>Поставщик</th>
          <th>Статус</th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="isLoading">
          <td colspan="5">
            <app-loading-message>
              Загрузка...
            </app-loading-message>
          </td>
        </tr>
        <ng-container *ngIf="!isLoading">
          <ng-container *ngFor="let item of issuesList; let i = index">
            <tr [ngClass]="{
            'alert-danger': is_issue_overdue(item) && item.state != 'RESOLVED'
            }">
              <td>
                {{item.created_at | date: 'dd.MM.yyyy'}}
              </td>
              <td style="max-width:250px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;" title="{{item.description}}">
                {{item.description}}
              </td>
              <td>
                {{item.order.order_number}}
              </td>
              <td>
                {{item.control_date | date: 'dd.MM.yyyy'}}
              </td>
              <td>
                {{item.creator.username}}
              </td>
              <td>
                {{item.performer}}
              </td>
              <td>
                {{item.shipper?.title}}
              </td>
              <td>
                {{item.stateTitle}}
              </td>
              <td>
                <div ngbDropdown>
                  <i class="btn fas fa-ellipsis-v" ngbDropdownToggle></i>
                  <div ngbDropdownMenu>
                    <button ngbDropdownItem (click)="openUpdateModal(item)">Изменить</button>
                    <div class="dropdown-divider"></div>
                    <button class="text-red" ngbDropdownItem (click)="deleteIssueModalOpen(deleteIssueModal, item)">Удалить</button>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="!issuesList.length">
            <td class="text-center">
              Список пуст
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ngb-pagination class="d-flex justify-content-center"
                [(page)]="paginationPage"
                [pageSize]="pageSize"
                [collectionSize]="collectionSize"
                (pageChange)="updatePage($event)"
                [maxSize]="5">
</ngb-pagination>


<ng-template #deleteIssueModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Удаление записи</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Удалить запись ?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modal.close()">Удалить</button>
  </div>
</ng-template>
