<div class="col-12">
  <div class="card">
    <div class="table-responsive">
      <div class="row">

        <div class="col-12 mb-2">
          <h3 class="card-title text-center mt-1 mb-1 text-red">Просроченные позиции</h3>
          <table class="table card-table table-vcenter text-nowrap table-hover">
            <thead>
            <tr>
              <th>Наименование товара</th>
              <th>Дата заказа</th>
              <th>Ожидаемая дата</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isOverdueLoading">
              <td colspan="5">
                <app-loading-message>
                  Получение списка позиций
                </app-loading-message>
              </td>
            </tr>
            <ng-container *ngIf="!isOverdueLoading">
              <ng-container *ngFor="let item of overdueOrderLines; let i = index">
                <tr>
                  <td (click)="navigateOrderDetail(item.order_line.order_id)">
                    {{item.order_line.product.name}}
                  </td>
                  <td>
                    {{item.created_at | date: 'dd.MM.yyyy' }}
                  </td>
                  <td>
                    {{item.expected_date | date: 'dd.MM.yyyy' }}
                  </td>
                  <td>
                    <button type="button"
                            class="btn btn-outline-danger mr-2"
                            (click)="closeProductOrder(item)">Закрыть
                    </button>
                  </td>
                </tr>
              </ng-container>
              <tr *ngIf="!overdueOrderLines.length">
                <td class="text-center">
                  Список пуст
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>

        </div>
    </div>
  </div>
</div>
