<div class="row">
  <div class="col s6 offset-s3">
    <div class="card">
      <div class="card-image">
        <img src="/assets/img/403.png" style="height:300px;width:300px;">

      </div>
      <div class="card-content">
        <span class="card-title" style="color:#039be5">403 - ACCESS DENIED</span>
        <p>You don't have the permission to access this resource.</p>
      </div>
    </div>
  </div>
</div>
