import {Component, HostListener, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {formatDate, Location} from '@angular/common';
import {catchError, debounceTime, distinctUntilChanged, finalize, first, map, switchMap, tap} from 'rxjs/operators';

import {DataProviderService} from '../../../../services/data/data-provider.service';
import {Client, DictItem, Order, OrderLine} from '../../../../models/sale';
import {NotifyMessageService} from '../../../../shared/notify-message/notify-message.service';
import {NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../../services/auth/auth.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DEFAULT_VALUE, OrderStatus} from '../../../../lib/constants';
import {CalculatedOrder, SaleService} from '../../../../services/sale/sale.service';
import {concat, Observable, of, Subject} from 'rxjs';
import {ProductService} from '../../product/product.service';
import {Currency, Payment, Unit} from '../../../../models/types';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n, toApiDate} from '../../../../shared/datepicker';
import {StockRecordAddressDetail} from '../../../../models/stock';

@Component({
  selector: 'app-order-detail',
  templateUrl: './stock-record-address-detail.component.html',
  styleUrls: ['./stock-record-address-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class StockRecordAddressDetailComponent implements OnInit {

  public address: StockRecordAddressDetail;
  public defaultVal = DEFAULT_VALUE;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private dataProvider: DataProviderService,
    public productService: ProductService,
  ) {
  }

  loading = true;

  ngOnInit() {
    this.route.params.subscribe(() => this.init());
  }

  private getAddress(id, params?): void {
    this.loading = true;

    this.dataProvider.getStockAddressDetail(id, params).pipe(
      first()
      )
      .subscribe(
        resp => {
          this.address = resp;
          this.loading = false;
        }
      );
  }

  public goBack(): void {
    this.location.back();
  }

  private init() {
    this.getAddress(this.route.snapshot.paramMap.get('id'));
  }
}
