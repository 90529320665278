import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import {StockService} from '../../services/stock/stock.service';
import {ProductList} from '../../models/stock';
import {DataProviderService} from '../../services/data/data-provider.service';
import {Filters} from '../../lib/constants';
import {FiltersService} from '../../shared/filters/services/filters.service';
import {concat, Observable, of, Subject, Subscription} from 'rxjs';
import {FormGroup, FormControl, FormBuilder} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalCreateComponent} from './product/modal-create/modal-create.component';
import {Unit} from '../../models/types';
import {ProductService} from './product/product.service';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'app-stock',
  templateUrl: './stock-record.component.html',
  styleUrls: ['./stock-record.component.scss'],
})

export class StockRecordComponent implements OnInit, OnDestroy {
  public title: string;
  public products: ProductList[];
  public displayingIndexes: boolean[];
  public error: string;
  public loading = true;

  public pagination: any = {
    count: 10,
    per_page: 50,
    current_page: 1
  };

  private _subscription: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private stockService: StockService,
    private dataProvider: DataProviderService,
    private productService: ProductService,
    private stockFiltersService: FiltersService,
    private modalService: NgbModal
  ) {
    this._subscription = new Subscription();
    this.setFilterParameters();
  }

  ngOnInit() {
    this.title = this.route.snapshot.data['title'];
    this.subscribe();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  get paginationPage() {
    return this.pagination.current_page;
  }

  set paginationPage(page: number | string) {
    if (Number(page) === Number(this.paginationPage) || !page) {
      return;
    }
    this.pagination.current_page = Number(page);
    this.stockFiltersService.updateQueryParameter(Filters.PAGE, String(page));
  }

  private setFilterParameters(): void {
    const queryParams = this.stockFiltersService.getQueryParameters();
    this.pagination.current_page = queryParams[Filters.PAGE] || 1;
    // this.getProductList(queryParams);
  }

  private subscribe() {

    this._subscription.add(
      this.stockService.loading.subscribe(loading => {
        setTimeout(() => {
          this.loading = loading;
        });
      })
    );

    this._subscription.add(
      // this.stockFiltersService.filterParams.subscribe(params => {
      //   // const queryParams = this.stockFiltersService.getQueryParameters();
      //   // if (Object.keys(params).length === 0) {
      //   //   return;
      //   // }

      this.stockFiltersService.filterParams.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(params => this.dataProvider.getProductList(params).pipe(
          map(products => {
              this.products = products.results;
              this.pagination.count = products.count;
              this.displayingIndexes = new Array(this.products.length);
              this.displayingIndexes.fill(true);
              this.loading = false;
            }
          )))
      ).subscribe());
  }

  toggleEdit(index: number) {
    this.displayingIndexes[index] = !this.displayingIndexes[index];
  }

  isRowDisplay(index: number) {
    return this.displayingIndexes[index];
  }

  createModalOpen() {
    const modalRef = this.modalService.open(ModalCreateComponent);
    modalRef.result.then(result => {
      }, () => { console.log('Backdrop click'); });
  }
}
