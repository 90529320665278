import { Injectable } from '@angular/core';
import {DataProviderService} from '../../services/data/data-provider.service';


@Injectable({
  providedIn: 'root'
})
export class OrderedProductsService {

  constructor(
    private dataProvider: DataProviderService,
  ) { }

  public getOverdueOrderProducts(params) {
    return this.dataProvider.getOverdueOrderProducts(params);
  }

}
