<div class="col-12">
  <div class="card">
    <div class="filters">
      <app-order-filter></app-order-filter>
    </div>
    <div class="table-responsive">
      <div class="row">

        <div class="col-12">
          <table class="table card-table table-vcenter text-nowrap table-hover">
            <thead>
            <tr>
              <th>Наименование товара</th>
              <th>Кол-во в заказе</th>
              <th>Всего заказано</th>
              <th>Поставщик</th>
              <th>Дата заказа</th>
              <th>Коментарий к заказу</th>
              <th>Статус</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isLoading">
              <td colspan="5">
                <app-loading-message>
                  Получение списка позиций
                </app-loading-message>
              </td>
            </tr>
            <ng-container *ngIf="!isLoading">
              <ng-container *ngFor="let item of productOrders; let i = index">
                <tr>
                  <td (click)="navigateOrderDetail(item.order_line.order_id)">
                    {{item.order_line.product.name}}
                  </td>
                  <td>
                    {{item.quantity}}
                  </td>
                  <td>
                    {{item.order_line.total_ordered_quantity}}
                  </td>
                  <td>
                    {{item.shipper_title}}
                  </td>
                  <td>
                    {{item.created_at | date: 'dd.MM.yyyy' }}
                  </td>
                  <td>
                    {{item.comment}}
                  </td>
                  <td>
                    {{item.stateTitle}}
                  </td>
                  <td>
                    <div ngbDropdown class="dropdown">
                      <button ngbDropdownToggle class="btn btn-secondary btn-sm">...</button>

                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button ngbDropdownItem (click)="openUpdateModal(item)" class="dropdown-item">Изменить</button>
                        <button ngbDropdownItem (click)="setProductOrderAtWork(item)" class="dropdown-item">В работе</button>
                        <button ngbDropdownItem (click)="openConfirmCloseModal(item)" class="dropdown-item text-gray-dark">Закрыть</button>
                        <button ngbDropdownItem (click)="openConfirmDeleteModal(item)" class="dropdown-item text-red">Удалить</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </ng-container>
              <tr *ngIf="!productOrders.length">
                <td class="text-center">
                  Список пуст
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>

      </div>
    </div>

    <ngb-pagination class="d-flex justify-content-center" (pageChange)="paginationPage = $event" [pageSize]="pagination.per_page" [(page)]="pagination.current_page" [collectionSize]="pagination.count" [maxSize]="5"></ngb-pagination>

  </div>
</div>
