import {Injectable} from '@angular/core';
import {DataProviderService} from '../../services/data/data-provider.service';
import {first, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Client} from '../../models/sale';


@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(
    private dataProvider: DataProviderService,
  ) {
  }

  public getClients(params?): Observable<Client[]> {
    return this.dataProvider.getClients(params)
      .pipe(
        first(),
        map(resp => resp.map(
          (item: any) => {
            item.clientInfo = `${item.legal_name} (${item.city} ИНН: ${item.INN})`;
            return item;
          })));
  }

}
