import {
  ChangeDetectorRef,
  Component,
  OnInit
} from '@angular/core';
import {NgbActiveModal, NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { DataProviderService } from '../../../services/data/data-provider.service';
import {requiredFileType} from '../../../shared/directives/validators';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map, share,
  switchMap,
  tap
} from 'rxjs/operators';
import {toFormData} from '../../../shared/utils';
import {NotifyMessageService} from '../../../shared/notify-message/notify-message.service';
import {concat, Observable, of, Subject} from 'rxjs';
import {Order} from '../../../models/sale';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n, toApiDate} from '../../../shared/datepicker';
import * as moment from 'moment';


@Component({
  selector: 'app-payments-create-modal',
  templateUrl: './payments-upload-modal.component.html',
  styleUrls: ['./payments-upload-modal.component.scss'],
  providers: [
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    ],
})
export class PaymentsUploadModalComponent implements OnInit {
  public paymentParserCodes = [
    {'title': 'АльфаБанк', 'code': 'ALFA'},
    {'title': 'Банк Санкт-Петербург', 'code': 'SPB'},
    {'title': 'Райффайзен Банк', 'code': 'RAIFFEISEN'},
  ];
  public isLoading = false;
  public isChecked = false;
  public checkedPayments: any;
  public errorMessage: string;
  public uploadPaymentsForm: FormGroup;
  public orderList$: Observable<Order[]>;
  public orderInput$ = new Subject<string>();
  public selectLoading = false;
  public today = this.calendar.getToday();

  constructor(public activeModal: NgbActiveModal,
              private notifierService: NotifyMessageService,
              private calendar: NgbCalendar,
              private formBuilder: FormBuilder,
              private dataProvider: DataProviderService,
              private cd: ChangeDetectorRef
  ) {
  }

  public ngOnInit() {
    this.init();
  }

  public onCancel() {
    this.activeModal.close();
  }

  private init() {
    this.uploadPaymentsForm = this.formBuilder.group({
      file: [null, [Validators.required, requiredFileType(['csv', 'dbf'])]],
      code: [null, Validators.required],
    });
    this.getOrders();
  }

  public uploadCheck() {
    this.isLoading = true;
    this.dataProvider.checkUploadPayments(toFormData(this.uploadPaymentsForm.value)).pipe(
      first(),
      finalize(() => {
          this.uploadPaymentsForm.reset();
          this.isLoading = false;
          this.isChecked = true;
        }
      ))
      .subscribe(resp => {
          this.checkedPayments = resp;
          this.checkedPayments.forEach(payment => {
            if (moment(payment.payment_date, 'YYYY-MM-DD', true).isValid()) {
              payment.payment_date = moment(payment.payment_date, 'YYYY-MM-DD', true).format('DD.MM.YYYY');
            }
            this.setCurrency(payment.order_currency, payment);
          });
          this.errorMessage = '';
        },
        err => {
          this.errorMessage = err['error']['error'];
        },
      );
  }

  public checkPayments() {
    this.isLoading = true;
    const data = this.checkedPayments;
    this.checkedPayments.forEach(payment => {
      payment.payment_date = toApiDate(payment.payment_date);
    });
    this.dataProvider.checkPayments(data).pipe(
      first(),
      finalize(() => {
          this.isLoading = false;
        }
      ))
      .subscribe(resp => {
          this.checkedPayments = resp;
          this.errorMessage = '';
          this.checkedPayments.forEach(payment => {
            if (moment(payment.payment_date, 'YYYY-MM-DD', true).isValid()) {
              payment.payment_date = moment(payment.payment_date, 'YYYY-MM-DD', true).format('DD.MM.YYYY');
            }
          });
        },
        err => {
          this.errorMessage = err['error']['error'];
        },
      );
  }

  public createPayments() {
    this.isLoading = true;
    const data = this.checkedPayments;
    this.checkedPayments.forEach(payment => {
      payment.payment_date = toApiDate(payment.payment_date);
    });
    this.dataProvider.createPaymentsByOrderNumber(data).pipe(
      first(),
      finalize(() => {
          // this.checkedPayments = [];
          this.isLoading = false;
          this.isChecked = false;
        }
      ))
      .subscribe(resp => {
          this.notifierService.notify('Платежи успешно загружены', 'success');
          this.errorMessage = '';
          this.onCancel();
        },
        err => {
          this.errorMessage = err['error']['error'];
        },
      );
  }

  public isReadyToCreatePayments() {
    const hasFails = this.checkedPayments.find(item => item.status === 'fail');
    return !hasFails;
}

  public handleFileInput(event) {
    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.uploadPaymentsForm.patchValue({
        file: file
      });

      // need to run CD since file load runs outside of zone
      this.cd.markForCheck();
    }
  }

  public removePaymentFromList(index) {
    this.checkedPayments.splice(index, 1);
  }

  public setCurrency(currency, payment) {
    payment.order_currency = currency;
    if (currency === 'RUB' || !payment.order_currency) {
      payment.exchange_rate = 0;
      return;
    }
    this.setExchangeRate(payment);
  }

  public setExchangeRate(payment) {
    const currency = payment.order_currency;
    const payment_date = payment.payment_date;
    if (!currency || !payment_date) {
      return;
    }
    console.log(payment_date);
    this.dataProvider.getExchangeRates({'request_date': toApiDate(payment_date)}).pipe(
      first()).subscribe(resp => {
        payment.exchange_rate = resp['rates'][currency]['value'].toFixed(4);
      }
    );
  }

  public getOrders() {
    this.orderList$ = concat(
      of([]), // default items
      this.orderInput$.pipe(
        filter(term => {
          return term !== null && term.length >= 3;
        }),
        distinctUntilChanged(),
        debounceTime(400),
        share(),
        tap(() => this.selectLoading = true),
        switchMap(term => this.dataProvider.getOrderList({'order_number': term}).pipe(
          map(resp => {
            return resp.results;
          }),
          catchError(() => of([])), // empty list on error
          tap(() => this.selectLoading = false),
        )),
        share(),
      ),
    );
  }

}
