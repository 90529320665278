import {Directive, ElementRef, HostListener} from '@angular/core';
import {FormControl} from '@angular/forms';

@Directive({
  selector: '[appOnlyNumber]'
})
export class OnlyNumberDirective {

  private regex: RegExp = new RegExp(/^[0-9]+([.,][0-9]*){0,1}$/g);
  private navigationKeys = [
    'Backspace',
    'Delete',
    'Tab',
    'Escape',
    'Enter',
    'Home',
    'End',
    'ArrowLeft',
    'ArrowRight',
    'Clear',
    'Copy',
    'Paste'
  ];
  inputElement: HTMLElement;
  constructor(public el: ElementRef) {
    this.inputElement = el.nativeElement;
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (
      this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
      (['a', 'ф'].includes(e.key) && e.ctrlKey === true) || // Allow: Ctrl+A
      (['c', 'с'].includes(e.key) && e.ctrlKey === true) || // Allow: Ctrl+C
      (['v', 'м'].includes(e.key) && e.ctrlKey === true) || // Allow: Ctrl+V
      (['x', 'ч'].includes(e.key) && e.ctrlKey === true) || // Allow: Ctrl+X
      (e.key === 'a' && e.metaKey === true) || // Allow: Cmd+A (Mac)
      (e.key === 'c' && e.metaKey === true) || // Allow: Cmd+C (Mac)
      (e.key === 'v' && e.metaKey === true) || // Allow: Cmd+V (Mac)
      (e.key === 'x' && e.metaKey === true) // Allow: Cmd+X (Mac)
    ) {
      // let it happen, don't do anything
      return;
    }

    const current: string = this.el.nativeElement.value;
    // We need this because the current value on the DOM element
    // is not yet updated with the value from this event
    const next: string = current.concat(e.key);
    if (next && !String(next).match(this.regex)) {
      e.preventDefault();
    }
    if (next && e.key === ',') {
      e.preventDefault();
      document.execCommand('insertText', false, '.');
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData
      .getData('text/plain')
      .replace(/\D\.,/g, '')
      .replace(',', '.');
    document.execCommand('insertText', false, pastedInput);
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent) {
    event.preventDefault();
    const textData = event.dataTransfer.getData('text').replace(/\D\.,/g, '')
    .replace(',', '.');
    this.inputElement.focus();
    document.execCommand('insertText', false, textData);
  }
}

export function requiredFileType( types: string[] ) {
  return function (control: FormControl) {
    const file = control.value;
    if ( file ) {
      const extension = file.name.split('.').pop();
      console.log();
      if ( !extension || !(types.filter((type) => type.toLowerCase().includes(extension.toLowerCase()))) ) {
        return {
          requiredFileType: true
        };
      }

      return null;
    }

    return null;
  };
}
