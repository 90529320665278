<div class="modal-header">
  <h4 class="modal-title">Обновление записи</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">x
  </button>
</div>
<div class="modal-body">

  <div class="create-form">
    <form [formGroup]="updateIssueForm">

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="description">Описание</label>
        <div class="col-8">
          <textarea formControlName="description"
                    class="form-control"
                    placeholder="Введите описание"
                    type="text"
                    id="description">
          </textarea>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="control_date">Контрольная дата</label>
        <div class="col-8 input-group">
          <input formControlName="control_date"
                 class="form-control"
                 placeholder="Введите дату"
                 type="text"
                 id="control_date"
                 ngbDatepicker
                 [footerTemplate]="footerTemplate"
                 #d="ngbDatepicker"/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar"
                    type="button"
                    (click)="d.toggle()">
              <i class="fe fe-calendar"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label">Статус</label>
        <div class="col-8">
          <ng-select [items]="this.issueStates$ | async"
                     class="form-control custom-select w-auto"
                     [required]="true"
                     [searchable]="true"
                     [clearable]="false"
                     formControlName="state"
                     bindLabel="title"
                     bindValue="code"
                     required>
          </ng-select>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label">Номер счета</label>
        <div class="col-8 input-group align-content-center">
          {{issue.order.order_number}}
        </div>
      </div>

      <div class="row justify-content-end mr-0">
        <button type="button"
                class="btn btn-outline-danger mr-2"
                [disabled]="isLoading"
                (click)="onCancel()">Отменить
        </button>
        <button type="submit"
                class="btn btn-outline-success"
                [disabled]="isLoading"
                (click)="updateIssue()">Сохранить
        </button>
      </div>
    </form>

    <ng-template #footerTemplate>
      <hr class="my-0">
      <button class="btn btn-primary btn-sm m-2 float-left"
              (click)="updateIssueForm.controls.payment_date.setValue(today); d.close()">Сегодня
      </button>
      <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d.close()">Закрыть</button>
    </ng-template>

  </div>
</div>

