import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataProviderService} from '../../../../services/data/data-provider.service';
import {finalize} from 'rxjs/operators';

import {Product, ProductList} from '../../../../models/stock';
import {ProductService} from '../product.service';
import {Unit} from '../../../../models/types';
import {Observable} from 'rxjs';

@Component({
  selector: '[app-table-edit]',
  templateUrl: './table-edit.component.html',
  styleUrls: ['./table-edit.component.scss']
})
export class TableEditComponent implements OnInit {
  @Input() product: ProductList;
  @Output() toggleDisplay = new EventEmitter<any>();
  loading = false;
  error = '';
  public unitTypes$: Observable<Unit[]>;

  constructor(
    private dataProvider: DataProviderService,
    public productService: ProductService,
    ) { }

  ngOnInit() {
    this.productService.getCategories();
    this.productService.getManufacturers();
    this.unitTypes$ = this.productService.getUnitTypes();
  }

  toggleToDisplay() {
    this.toggleDisplay.emit();
  }

  saveProduct(id, data): void {
    this.loading = true;
    const modifiedData = Object.assign({}, data);
    modifiedData.category = modifiedData.category.id;
    modifiedData.manufacturer = modifiedData.manufacturer.id;
    this.dataProvider.changeProduct(id, modifiedData).pipe(
      finalize(() => {
        this.loading = false;
        this.toggleDisplay.emit();
      }),
    )
      .subscribe(
        res => {
          console.log(res);
        },
        err => {this.error = 'Ошибка сохранения'; console.log(err); },
      );
  }


}
