import {Component, OnInit} from '@angular/core';
import {FiltersComponentDirective} from '../../../shared/filters/components/filters-component.directive';
import {DataProviderService} from '../../../services/data/data-provider.service';
import {FiltersService} from '../../../shared/filters/services/filters.service';
import {FormBuilder, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {DictItem} from '../../../models/sale';
import {formatDate} from '@angular/common';
import {NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n} from '../../../shared/datepicker';
import {OrderService} from '../order.service';
import {User} from '../../../models/user';
import {CompanyService} from '@app/components/sale/company.service';
import {Company} from '@app/models/types/company.interface';

@Component({
  selector: 'app-order-filter',
  templateUrl: './order-filter.component.html',
  styleUrls: ['./order-filter.component.scss'],
  providers: [
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class OrderFilterComponent extends FiltersComponentDirective implements OnInit {
  public orderStatuses: DictItem[] = [];
  public userList: User[] = [];
  public companies: Company[] = [];
  public today = formatDate(new Date(), 'dd.MM.yyyy', 'en');
  public created_at_gte: NgbDate;
  public created_at_lte: NgbDate | null = null;
  public updated_at_gte: NgbDate;
  public updated_at_lte: NgbDate | null = null;
  public hoveredDate: NgbDate | null = null;
  public hoveredUpdatedDate: NgbDate | null = null;

  constructor(
    private dataProvider: DataProviderService,
    private orderService: OrderService,
    private companyService: CompanyService,
    private stockFiltersService: FiltersService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    filtersService: FiltersService,
    formBuilder: FormBuilder,
  ) {
    super(
      filtersService,
      formBuilder,
    );
    this.created_at_gte = calendar.getToday();
    this.created_at_lte = calendar.getNext(calendar.getToday(), 'd', 10);
    this.updated_at_gte = calendar.getToday();
    this.updated_at_lte = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getStatuses();
    this.getUsers();
    this.getCompanies();
  }

  private getStatuses() {
    this.dataProvider.getOrderStatuses().pipe(
      first(),
    )
      .subscribe(resp => {
          this.orderStatuses = resp;
        }
      );
  }

  private getUsers() {
    this.orderService.getUsers().pipe(
      first(),
    )
      .subscribe(resp => {
          this.userList = resp;
        }
      );
  }

  private getCompanies() {
    this.companyService.getCompanies()
      .subscribe(
        response => {
          this.companies = response;
        }
      );
  }

  getFiltersForm() {
    return this.formBuilder.group({
      product_name: ['', Validators.required],
      order_number: ['', Validators.required],
      client_legal_name: ['', Validators.required],
      client_inn: ['', Validators.required],
      status: [null, Validators.required],
      with_issues: ['', Validators.required],
      creator: [null, Validators.required],
      company: [null, Validators.required],
      created_at_gte: ['', Validators.required],
      created_at_lte: ['', Validators.required],
      updated_at_gte: ['', Validators.required],
      updated_at_lte: ['', Validators.required],
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.created_at_gte && !this.created_at_lte) {
      this.created_at_gte = date;
    } else if (this.created_at_gte && !this.created_at_lte && date.after(this.created_at_gte)) {
      this.created_at_lte = date;
    } else if (this.created_at_gte && !this.created_at_lte && date.equals(this.created_at_gte)) {
      this.created_at_lte = this.created_at_gte;
    } else {
      this.created_at_lte = null;
      this.created_at_gte = date;
    }
    this.filtersForm.controls.created_at_gte.setValue(this.formatter.format(this.created_at_gte));
    this.filtersForm.controls.created_at_lte.setValue(this.formatter.format(this.created_at_lte));
  }

  isHovered(date: NgbDate) {
    return this.created_at_gte &&
      !this.created_at_lte &&
      this.hoveredDate &&
      date.after(this.created_at_gte) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.created_at_lte && date.after(this.created_at_gte) && date.before(this.created_at_lte);
  }

  isRange(date: NgbDate) {
    return date.equals(this.created_at_gte) ||
      (this.created_at_lte && date.equals(this.created_at_lte)) ||
      this.isInside(date) ||
      this.isHovered(date);
  }

  onUpdatedDateSelection(date: NgbDate) {
    if (!this.updated_at_gte && !this.updated_at_lte) {
      this.updated_at_gte = date;
    } else if (this.updated_at_gte && !this.updated_at_lte && date.after(this.updated_at_gte)) {
      this.updated_at_lte = date;
    } else if (this.updated_at_gte && !this.updated_at_lte && date.equals(this.updated_at_gte)) {
      this.updated_at_lte = this.updated_at_gte;
    } else {
      this.updated_at_lte = null;
      this.updated_at_gte = date;
    }
    this.filtersForm.controls.updated_at_gte.setValue(this.formatter.format(this.updated_at_gte));
    this.filtersForm.controls.updated_at_lte.setValue(this.formatter.format(this.updated_at_lte));
  }

  isUpdatedHovered(date: NgbDate) {
    return this.updated_at_gte &&
      !this.updated_at_lte &&
      this.hoveredUpdatedDate &&
      date.after(this.updated_at_gte) && date.before(this.hoveredUpdatedDate);
  }

  isUpdatedInside(date: NgbDate) {
    return this.updated_at_lte && date.after(this.updated_at_gte) && date.before(this.updated_at_lte);
  }

  isUpdatedRange(date: NgbDate) {
    return date.equals(this.updated_at_gte) ||
      (this.updated_at_lte && date.equals(this.updated_at_lte)) ||
      this.isUpdatedInside(date) ||
      this.isUpdatedHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

}
