<div class="modal-header">
  <h4 class="modal-title">Добавление заказа. Счет № {{ order?.order_number }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">x
  </button>
</div>
<div class="modal-body">

  <div class="create-form">
    <form [formGroup]="createOrderedProductsForm">

      <div class="form-group row mb-2">
        <div class="col-8 row">
          <label class="col-4">Клиент</label>
          <div class="col-8">
            {{ order?.client.legal_name }}
          </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-8 row">
          <label class="col-4">Товар</label>
          <div class="col-8">
            {{ orderLine.product.name }}
          </div>
        </div>
        <div class="col-4 row">
          <label class="col-8">В счете</label>
          <div class="col-4">
            {{ orderLine.quantity }}
          </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-8 row">
          <label class="col-4">В заказе</label>
          <div class="col-8 input-group">
            {{ orderLine.ordered_quantity }}
          </div>
        </div>
        <div class="col-4 row">
          <label class="col-8">В заказах</label>
          <div class="col-4">
            {{ orderLine.total_ordered_quantity }}
          </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-8 row">
          <label class="col-4">В резерве</label>
          <div class="col-8 input-group">
            {{ orderLine.reserved_quantity }}
          </div>
        </div>
        <div class="col-4 row">
          <label class="col-8">Излишек</label>
          <div class="col-4">
            {{ orderLine.over_ordered_quantity }}
          </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-8 row">
          <label class="col-4">На складе</label>
          <div class="col-8 input-group text-red">
            {{ orderLine.product.quantity }} / {{ orderLine.product.external_quantity }}
          </div>
        </div>
        <div class="col-4 row">
          <label class="col-8">Доступно</label>
          <div class="col-4">
            {{ orderLine.product.available_quantity || 0 }}
          </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-8 row">
          <label class="col-4">Отгружено</label>
          <div class="col-8 input-group">
            {{ orderLine.shipped_quantity }}
          </div>
        </div>
        <div class="col-4 row">
          <label style="white-space:pre-line" class="col-12">{{ getRecommendedPrice() }}</label>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-8 row">
          <label class="col-4">К заказу</label>
          <div class="col-8 input-group">
            {{ getOrderEstimateWithoutMinus() }}
          </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-8 row">
          <label class="col-4">На склад</label>
          <div class="col-8 input-group">
            {{ getOrderToStock() }}
          </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <div class="col-6">
          <div class="text-center">Резерв</div>
          <div [formGroup]="createReservedProductsForm">
            <div class="mb-2">
              <ng-select [items]="stockRecordList"
                         class="form-control custom-select"
                         id="stock_record_id"
                         placeholder="Наименование"
                         bindLabel="bind_label"
                         bindValue="id"
                         formControlName="stock_record_id"
                         [clearable]="false"
                         [hideSelected]="true"
                         [loading]="selectLoading"
                         [typeahead]="stockRecordInput$">
              </ng-select>
            </div>

            <div class="mb-2">
              <input formControlName="quantity"
                     class="form-control"
                     placeholder="Количество"
                     type="text"
                     id="reserved_quantity"
                     appOnlyNumber/>
            </div>
          </div>
        </div>

        <div class="col-6">
          <div class="text-center">Заказ</div>
          <div class="mb-2 ">
            <ng-select [items]="shipperList"
                       class="form-control custom-select"
                       id="shipper"
                       placeholder="Поставщик"
                       bindLabel="title"
                       bindValue="id"
                       formControlName="shipper"
                       autofocus="true"
                       [clearable]="false"
                       [hideSelected]="true"
                       [loading]="selectLoading"
                       [typeahead]="shipperInput$">
            </ng-select>
          </div>
          <div class="mb-2">
            <input formControlName="quantity"
                   class="form-control"
                   placeholder="Количество"
                   type="text"
                   id="quantity"
                   appOnlyNumber/>
          </div>
          <div class="input-group">
            <input formControlName="expected_date"
                   class="form-control"
                   placeholder="Введите ожидаемую дату"
                   type="text"
                   id="expected_date"
                   ngbDatepicker
                   [footerTemplate]="footerTemplate"
                   #d="ngbDatepicker"/>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar"
                      type="button"
                      (click)="d.toggle()">
                <i class="fe fe-calendar"></i>
              </button>
            </div>
          </div>
        </div>


        <div class="col-12">
          <div class="mb-2">
            <textarea formControlName="comment"
                   class="form-control"
                   placeholder="Коментарий к заказу"
                   type="text"
                   id="comment">
            </textarea>
          </div>
        </div>


      </div>

      <div>
        <label>
          <input type="checkbox"
                 [(ngModel)]="isProductOrderNotRequired"
                 (change)="isProductOrderRequiredChange()"
                 [ngModelOptions]="{standalone: true}">
          Заказ не требуется
        </label>
      </div>

      <div class="row justify-content-end mr-0">
        <button type="button"
                class="btn btn-outline-danger mr-2"
                [disabled]="isLoading"
                (click)="activeModal.dismiss('Close click')">Отменить
        </button>
        <button *ngIf="!isProductOrderNotRequired" type="submit"
                class="btn btn-outline-success"
                [disabled]="isLoading || !isFormsValid()"
                (click)="createProductOrder()">Добавить
                <img *ngIf="isLoading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpK
            SkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2M
            IOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlF
            UYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmK
            twHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0
            R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6Twf
            wyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSl
            KAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKA
            AAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
        </button>

        <button *ngIf="isProductOrderNotRequired" type="submit"
                class="btn btn-outline-primary"
                [disabled]="isLoading"
                (click)="changeProductOrderRequired()">Сохранить
          <img *ngIf="isLoading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpK
            SkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2M
            IOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlF
            UYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmK
            twHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0
            R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6Twf
            wyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSl
            KAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKA
            AAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
        </button>

      </div>
    </form>

    <ng-template #footerTemplate>
      <hr class="my-0">
      <button class="btn btn-primary btn-sm m-2 float-left" (click)="createOrderedProductsForm.controls.payment_date.setValue(today); d.close()">Сегодня</button>
      <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d.close()">Закрыть</button>
    </ng-template>

  </div>
</div>

