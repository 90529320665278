<div class="header py-4">
  <div class="container">

    <div class="d-flex">
      <a routerLink="/" class="header-brand" href="">
        <img *ngIf="logoUrl" [src]="logoUrl" class="header-brand-img" alt="logo">
      </a>
      <div class="d-flex order-lg-2 ml-auto">
        <div class="dropdown d-none d-md-flex">
          <a class="nav-link icon" data-toggle="dropdown">
            <i class="fe fe-bell"></i>
            <span class="nav-unread"></span>
          </a>
          <div class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
            <a href="#" class="dropdown-item d-flex">
              <span class="avatar mr-3 align-self-center"></span>
              <div>
                <strong>Nathan</strong> pushed new commit: Fix page load performance issue.
                <div class="small text-muted">10 minutes ago</div>
              </div>
            </a>
            <a href="#" class="dropdown-item d-flex">
              <span class="avatar mr-3 align-self-center"></span>
              <div>
                <strong>Alice</strong> started new task: Tabler UI design.
                <div class="small text-muted">1 hour ago</div>
              </div>
            </a>
            <a href="#" class="dropdown-item d-flex">
              <span class="avatar mr-3 align-self-center"></span>
              <div>
                <strong>Rose</strong> deployed new version of NodeJS REST Api V3
                <div class="small text-muted">2 hours ago</div>
              </div>
            </a>
            <div class="dropdown-divider"></div>
            <a href="#" class="dropdown-item text-center text-muted-dark">Mark all as read</a>
          </div>
        </div>

        <div ngbDropdown class="dropdown">
          <a ngbDropdownToggle href="javascript:void(0)" class="nav-link pr-0 leading-none" data-toggle="dropdown">
            <span class="avatar"></span>
            <span class="ml-2 d-none d-lg-block">
                      <span
                        class="text-default">{{ authService.currentUser.first_name }} {{ authService.currentUser.last_name }}</span>
              <!--                      <small class="text-muted d-block mt-1">{{ authService.currentUser.groups[0].name || '' }}</small>-->
                      <small class="text-muted d-block mt-1">{{authService.getFirstGroup()}}</small>
                    </span>
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
            <!--            <a class="dropdown-item" href="#">-->
            <!--              <i class="dropdown-icon fe fe-user"></i> Profile-->
            <!--            </a>-->
            <!--            <a class="dropdown-item" href="#">-->
            <!--              <i class="dropdown-icon fe fe-settings"></i> Settings-->
            <!--            </a>-->
            <!--            <a class="dropdown-item" href="#">-->
            <!--              <span class="float-right"><span class="badge badge-primary">6</span></span>-->
            <!--              <i class="dropdown-icon fe fe-mail"></i> Inbox-->
            <!--            </a>-->
            <!--            <a class="dropdown-item" href="#">-->
            <!--              <i class="dropdown-icon fe fe-send"></i> Message-->
            <!--            </a>-->
            <div class="dropdown-divider"></div>
            <a (click)="logOut()" class="dropdown-item" href="javascript:void(0)">
              <i class="dropdown-icon fe fe-log-out"></i> Выйти
            </a>
          </div>
        </div>
      </div>
      <a href="#" class="header-toggler d-lg-none ml-3 ml-lg-0" (click)="isMenuCollapsed = !isMenuCollapsed">
        <span class="header-toggler-icon"></span>
      </a>

    </div>
  </div>
</div>

<div [ngbCollapse]="isMenuCollapsed" class="header collapse d-lg-flex p-0" id="headerMenuCollapse">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg order-lg-first">
        <ul class="nav nav-tabs border-0 flex-column flex-lg-row">
          <li class="nav-item">
            <a routerLinkActive="active" routerLink="/" [routerLinkActiveOptions]="{exact:true}"
               href="javascript:void(0)" class="nav-link"><i class="fe fe-home"></i> Главная</a>
          </li>
          <li ngbDropdown class="nav-item">
            <a ngbDropdownToggle href="javascript:void(0)" class="nav-link"><i class="fe fe-box"></i> Склад</a>
            <div ngbDropdownMenu>
              <a *ngFor="let link of links"
                 routerLinkActive="active"
                 [routerLink]="link.path"
                 class="dropdown-item"
                 (click)="isMenuCollapsed = true">
                {{ link.title }}
              </a>
            </div>
          </li>
          <li class="nav-item">
            <a routerLinkActive="active"
               routerLink="/sale"
               href="javascript:void(0)"
               class="nav-link"
               (click)="isMenuCollapsed = true">
              <i class="fe fe-file-text"></i> Счета
            </a>
          </li>
          <li class="nav-item">
            <a routerLinkActive="active"
               routerLink="/payments"
               href="javascript:void(0)"
               class="nav-link"
               (click)="isMenuCollapsed = true">
              <i class="fe fe-file-text"></i> Платежи
            </a>
          </li>
          <li class="nav-item">
            <a routerLinkActive="active"
               routerLink="/issues"
               href="javascript:void(0)"
               class="nav-link"
               (click)="isMenuCollapsed = true">
              <i class="fe fe-file-text"></i> Проблемы
            </a>
          </li>
          <li ngbDropdown class="nav-item">
            <a ngbDropdownToggle href="javascript:void(0)" class="nav-link">
              <i class="fe fe-box"></i>
              Заказы
            </a>
            <div ngbDropdownMenu>
              <a routerLinkActive="active"
                 routerLink="/product-orders/ordered"
                 href="javascript:void(0)"
                 class="dropdown-item"
                 (click)="isMenuCollapsed = true">
                Все заказы
              </a>
              <a routerLinkActive="active"
                 routerLink="/product-orders/required-to-order"
                 href="javascript:void(0)"
                 class="dropdown-item"
                 (click)="isMenuCollapsed = true">
                Список позиций к заказу
              </a>
              <a routerLinkActive="active"
                 routerLink="/product-orders/overdue"
                 href="javascript:void(0)"
                 class="dropdown-item"
                 (click)="isMenuCollapsed = true">
                Просроченные позиции
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
<div class="container">
  <div class="row align-items-center">
    <div class="col-lg">
      <ul class="nav nav-tabs border-0 flex-column flex-lg-row">
        <li class="nav-item">
          <a routerLinkActive="active" routerLink="sale/order/create" class="nav-link icon" href="javascript:void(0)">
            <i class="fe fe-plus-square"></i>Создать счет
          </a>
        </li>
      </ul>
    </div>
  </div>
</div>
