import { Injectable, Input, OnInit, Directive } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime} from 'rxjs/operators';

import {Notification} from './notify-message.helper';

@Directive()
@Injectable()
// tslint:disable-next-line:directive-class-suffix
export class NotifyMessageService implements OnInit {
  private notify_success$ = new BehaviorSubject<string>(null);
  notification$: Subject<Notification> = new Subject<Notification>();

  @Input() type: string;

  successMessage: string;

  ngOnInit() {
    this.notify_success$.subscribe((message) => {this.successMessage = message; });
    this.notify_success$.pipe(
      debounceTime(1000)
    ).subscribe(() => this.successMessage = null);
  }

  notify(message: string, style?: string, duration?: number) {
    this.notification$.next(new Notification(message, style, duration));
  }

}
