<form [formGroup]="filtersForm" action="">
<!--  <ng-select formControlName="product" [items]="products$ | async"-->
<!--             placeholder="Название"-->
<!--             bindLabel="name"-->
<!--             bindValue="id"-->
<!--             [addTag]="false"-->
<!--             [hideSelected]="true"-->
<!--             [loading]="loading"-->
<!--             [typeahead]="productsInput$">-->
<!--  </ng-select>-->
  <div class="row">
    <div class="col-6 form-group">
      <div class="input-icon mb-3">
        <input formControlName="clean_name" type="text" class="form-control" placeholder="Наименование">
        <span class="input-icon-addon"><i class="fe fe-search"></i></span>
      </div>
    </div>
    <div class="col-6 form-group">
      <div class="input-icon mb-3">
        <input formControlName="manufacturer" type="text" class="form-control" placeholder="Производитель">
        <span class="input-icon-addon"><i class="fe fe-search"></i></span>
      </div>
    </div>
  </div>
</form>
