<button (click)="goBack()" class="btn btn-primary">Назад</button>

<app-loading-message *ngIf="loading">
  Загрузка данных о складе
</app-loading-message>

<div *ngIf="!loading"
     class="my-3 my-md-5 col-12 card">

  <div class="page-header">
    <h1 class="page-title">
      <span class="product-name">{{ product.name }}</span>
      <span class="product-tnved">
      <span>ТНВЭД:</span>
      <span class="tnved-number">{{ product.tnved && product.tnved.code || defaultVal }}</span>
      </span>
    </h1>

    <ul class="page-info">
      <li>
        <span class="label text-muted">Категория:</span>
        <span class="info">{{product.category.name || defaultVal}}</span>
      </li>
      <li>
        <span class="label text-muted">Производитель:</span>
        <span class="info">{{product.manufacturer.name || defaultVal}}</span>
      </li>
    </ul>
  </div>

  <div class="table-responsive">
    <table class="table card-table table-vcenter text-nowrap">
      <thead>
      <tr>
        <th>Количество</th>
        <th>Единица измерения</th>
        <th>Адрес</th>
        <th>Страна</th>
        <th>Код ГТД</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of stockRecords">
        <td>{{item.quantity || defaultVal}}</td>
        <td>{{product.unit || defaultVal}}</td>
        <td>{{item.address.name || defaultVal}}</td>
        <td>{{item.country.code || defaultVal}}</td>
        <td>{{item.gtd || defaultVal}}</td>
        <td>
          <div ngbDropdown class="dropdown">
            <button ngbDropdownToggle class="btn btn-secondary btn-sm">Действия</button>

            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <button ngbDropdownItem (click)="modalChangeStockRecordOpen(changeQuantityModal, item, stockRecordAction.CHANGE)" class="dropdown-item">Изменить количество</button>
              <button ngbDropdownItem (click)="modalChangeStockRecordOpen(changeAddressModal, item, stockRecordAction.MOVE)" class="dropdown-item">Переместить</button>
            </div>
          </div>
        </td>
      </tr>

      <tr *ngIf="stockRecords && stockRecords.length">
        <td><b>Общее количество:</b> {{product.quantity}}</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>

        <td></td>
      </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #changeQuantityModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Изменение количества</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="changeStockRecordForm" (keyup.enter)="changeStockRecord()">
      <div class="form-group">
        <input formControlName="quantity" class="form-control" placeholder="Количество" type="number" required>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="submitted" (click)="changeStockRecord()">Изменить</button>
  </div>
</ng-template>

<ng-template #changeAddressModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Изменение адреса</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="changeStockRecordForm" (keyup.enter)="changeStockRecord()">
      <div class="form-group">
        <input formControlName="quantity" class="form-control" placeholder="Количество" type="number" required>
      </div>
      <label class="form-label">Адрес на складе</label>
      <ng-select formControlName="address"
                 [items]="stockRecordAddresses"
                 placeholder="Адрес на складе"
                 bindLabel="name"
                 bindValue="id"
                 [addTag]="false"
                 [hideSelected]="true">
      </ng-select>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="submitted" (click)="changeStockRecord()">Изменить</button>
  </div>
</ng-template>


