import {
  Component,
  OnInit
} from '@angular/core';
import {NgbActiveModal, NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { DataProviderService } from '../../../services/data/data-provider.service';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, first, map, share, switchMap, tap} from 'rxjs/operators';
import {concat, Observable, of, Subject} from 'rxjs';
import {Order} from '../../../models/sale';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n, toApiDate} from '../../../shared/datepicker';
import {DatePipe, formatDate} from '@angular/common';


@Component({
  selector: 'app-payments-create-modal',
  templateUrl: './payments-create-modal.component.html',
  styleUrls: ['./payments-create-modal.component.scss'],
  providers: [
    DatePipe,
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class PaymentsCreateModalComponent implements OnInit {
  public currencyTypes = [
    {'title': 'Рубли', 'code': 'RUB'},
    {'title': 'Доллары', 'code': 'USD'},
    {'title': 'Евро', 'code': 'EUR'},
  ];
  public isLoading = false;
  public createPaymentForm: FormGroup;
  public orderList$: Observable<Order[]>;
  public orderInput$ = new Subject<string>();
  public selectLoading = false;
  public today = formatDate(new Date(), 'dd.MM.yyyy', 'en');

  constructor(public activeModal: NgbActiveModal,
              private calendar: NgbCalendar,
              private formBuilder: FormBuilder,
              private dataProvider: DataProviderService,
              private datePipe: DatePipe,
  ) {
  }

  public ngOnInit() {
    this.init();
  }

  public createPayment() {
    this.isLoading = true;
    const data = this.createPaymentForm.value;
    data.payment_date = toApiDate(data.payment_date);
    this.dataProvider.createPaymentByOrderNumber(data).pipe(
      first(),
      finalize(() => {
          this.isLoading = false;
        }
      ))
      .subscribe(() => {
          this.createPaymentForm.reset();
          this.activeModal.close();
        }
      );
  }

  public onCancel() {
    this.activeModal.close();
  }

  public getOrders() {
    this.orderList$ = concat(
      of([]), // default items
      this.orderInput$.pipe(
        filter(term => {
          return term !== null && term.length >= 3;
        }),
        distinctUntilChanged(),
        debounceTime(400),
        share(),
        tap(() => this.selectLoading = true),
        switchMap(term => this.dataProvider.getOrderList({'order_number': term}).pipe(
          map(resp => {
            return resp.results;
          }),
          catchError(() => of([])), // empty list on error
          tap(() => this.selectLoading = false),
        ))
      ),
    );
  }

  public setCurrency(currency) {
    this.createPaymentForm.controls.order_currency.setValue(currency);
    if (currency === 'RUB') {
      this.createPaymentForm.controls['exchange_rate'].setValue(0);
      return;
    }
    this.setExchangeRate();
  }

  public setExchangeRate() {
    const currency = this.createPaymentForm.get('order_currency').value;
    const payment_date = this.createPaymentForm.get('payment_date').value;
    if (!currency || !payment_date) {
      return;
    }
    this.dataProvider.getExchangeRates({'request_date': toApiDate(payment_date)}).pipe(
      first()).subscribe(resp => {
        this.createPaymentForm.controls['exchange_rate'].setValue(resp['rates'][currency]['value'].toFixed(4));
      }
    );
  }

  private init() {
    this.getOrders();
    this.createPaymentForm = this.formBuilder.group({
      order_number: [null, Validators.required],
      number: [null, Validators.required],
      amount: [null, Validators.required],
      payment_date: [this.today, Validators.required],
      order_currency: [this.currencyTypes[0]['code'], Validators.required],
      exchange_rate: [0],
    });
  }

}
