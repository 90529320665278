    <div class="card">
      <div class="card-header">
        <h3 class="card-title">Создание счета</h3>
        <div class="card-options">
          <button (click)="openAddProductModal()"
                  type="button"
                  class="btn btn-outline-info mr-4">
            <i class="fe fe-plus-square"></i> Добавить товар
          </button>
          <button type="button" class="btn btn-primary mr-4" onclick="window.print();"><i
            class="si si-printer"></i> Печать
          </button>
          <button (click)="createOrder()" type="button" class="btn btn-success" [disabled]="!createOrderForm.valid">
            <i class="fe fe-plus-square"></i> Создать
          </button>
        </div>
      </div>
      <div class="card-body">
          <ng-container [formGroup]="createOrderForm">
            <ng-select [items]="currencyTypes"
                       class="form-control custom-select"
                       [searchable]="true"
                       [clearable]="false"
                       bindLabel="title"
                       bindValue="code"
                       id="currency"
                       formControlName="currency"
                       required>
            </ng-select>
          </ng-container>

          <div class="row my-6">
            <div class="col-6 mb-3">
              <div class="row col-6">

                <ng-container [formGroup]="createOrderForm">
                  <ng-select [items]="companies"
                             class="form-control custom-select"
                             [searchable]="true"
                             [clearable]="false"
                             [hideSelected]="true"
                             bindLabel="name"
                             bindValue="id"
                             id="company"
                             formControlName="company"
                             required>
                  </ng-select>
                </ng-container>
              </div>
            </div>
            <div class="col-6 text-right">

              <button *ngIf="!client" (click)="modalClientOpen(clientSelectModal)" type="button" class="btn btn-outline-info mr-4">
                <i class="fe fe-plus-square"></i> Добавить клиента
              </button>
              <div *ngIf="client" class="client-address" (click)="modalClientOpen(clientSelectModal)">
                <p class="h3">{{ client.legal_name }}</p>
                <address>
                  {{ client.legal_address }}
                </address>
              </div>
            </div>
            <div class="col-6">
              <button type="button" class="btn btn-outline-primary" (click)="infoIsCollapsed = !infoIsCollapsed"
                      [attr.aria-expanded]="!infoIsCollapsed" aria-controls="collapseInfo">
                Примечания
              </button>
              <ng-container>

                <div class="btn-group btn-group-toggle radio-delivery" ngbRadioGroup [(ngModel)]="selectedDeliveryTime">
                  <label *ngFor="let item of deliveryTimeList" ngbButtonLabel class="btn-outline-primary">
                    <input ngbButton type="radio" [value]="item"> {{item.title}}
                  </label>
                </div>
              </ng-container>
            </div>
            <input *ngIf="isCustomDeliveryTimeVisible()" class="form-control delivery-time-input" [(ngModel)]="customDeliveryTime" placeholder="Срок поставки">
          </div>

          <div id="collapseInfo" [ngbCollapse]="infoIsCollapsed">
            <div class="form-check">
              <input class="form-check-input"
                     type="checkbox"
                     value=""
                     id="includeOfertaCheckbox"
                     (change)="toggleIncludeOfertaParameter($event)"
                     checked>
              <label class="form-check-label" for="includeOfertaCheckbox">
                Отображать 2 пункт оферты в счете
              </label>
            </div>
            <div class="card" [formGroup]="createOrderForm">
                <textarea formControlName="addition_info" rows="3" class="form-control" placeholder="Примечания к счету"></textarea>
            </div>
          </div>

          <div class="table-responsive push">
            <table class="table table-bordered table-hover">
              <tr>
                <th class="text-center" style="width: 1%"></th>
                <th>Наименование</th>
                <th class="text-right">Коментарий</th>
<!--                <th class="text-center" style="width: 1%; white-space: nowrap;">Производитель</th>-->
                <th class="text-center" style="width: 1%; white-space: nowrap;">Ед. изм.</th>
                <th class="text-center" style="width: 15%; white-space: nowrap;">Кол-во</th>
                <th class="text-center" style="width: 15%; white-space: nowrap;">Цена</th>
                <th class="text-right" style="width: 1%">Сумма</th>
              </tr>
              <ng-container *ngIf="orderProducts.length">
                <tr class="row-index" *ngFor="let item of orderProducts; let i = index">
                  <td class="text-center"><span class="span-index">{{ i + 1 }}</span>
                    <a class="remove-btn" href="javascript:void(0)">
                      <i class="fa fa-minus-circle text-red" (click)="removeProductFromOrder(i)"></i>
                    </a>
                  </td>
                  <td>
                    <p class="font-w600 mb-1">
                      <ng-container *ngIf="!isLineChangeProductEditable(item)">
                        {{ item.product.name }} ({{ item.product.category.name }})
                      </ng-container>
                      <a *ngIf="!isLineChangeProductEditable(item)" href="javascript:void(0)" class="icon" (click)="toggleLineChangeProductEditable(item)"><i class="fe fe-edit float-right"></i></a>
                      <ng-select *ngIf="isLineChangeProductEditable(item)" [items]="productService.products$ | async"
                                 autofocus
                                 placeholder="Товар"
                                 bindLabel="name"
                                 [clearable]="false"
                                 [hideSelected]="true"
                                 [loading]="productService.selectLoading"
                                 [typeahead]="productService.searchQuery$"
                                 (change)="changeOrderLine(item)"
                                 [(ngModel)]="item.product">
                      </ng-select>
                    </p>
                  </td>
<!--                  <td>-->
<!--                    <p class="font-w600 mb-1">{{ item.product.manufacturer.name }}</p>-->
<!--                  </td>-->
                  <textarea class="form-control" type="text" [(ngModel)]="item.comment"></textarea>
                  <td>
                    <p class="text-center">{{ getUnitTitle(item.product.unit) }}</p>
                  </td>
                  <td class="text-center">
                    <input class="qty"
                           type="number"
                           (ngModelChange)="orderChange(item)"
                           [(ngModel)]="item.quantity">
                    <div class="outer-button">
                      <div (click)="quantityInc(item)" class="inc button"><i class="fa fa-plus"></i></div>
                      <div (click)="quantityDec(item)" class="dec button"><i class="fa fa-minus"></i></div>
                    </div>
                  </td>
                  <td class="text-right">
                    <input class="form-control"
                           type="text"
                           (change)="orderChange(item)"
                           (ngModelChange)="item.price=$event"
                           [ngModel]="item.price"
                           appOnlyNumber>
                  </td>
                  <td class="text-right">{{ item.cost| number:'1.2-2' }}</td>
                </tr>
                <tr>
                  <td colspan="6" class="font-w600 text-right">Итого</td>
                  <td class="text-right">{{ calculatedOrder.orderSubTotal| number:'1.2-2' }}</td>
                </tr>
                <tr>
                  <td colspan="6" class="font-w600 text-right">НДС</td>
                  <td class="text-right">{{ saleService.vatValue | number:'1.2-2' }}%</td>
                </tr>
                <tr>
                  <td colspan="6" class="font-w600 text-right">Итого НДС</td>
                  <td class="text-right">{{ calculatedOrder.vatTotal | number:'1.2-2'  }}</td>
                </tr>
                <tr>
                  <td colspan="6" class="font-weight-bold text-uppercase text-right">Итого к оплате</td>
                  <td class="font-weight-bold text-right">{{ calculatedOrder.orderTotal | number:'1.2-2' }}</td>
                </tr>
              </ng-container>
            </table>
            <button (click)="createOrder()" type="button" class="btn btn-success float-right" [disabled]="!createOrderForm.valid">
              <i class="fe fe-check"></i> Создать
            </button>
          </div>

      </div>
    </div>


<ng-template #clientSelectModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Добавление клиента</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-select [items]="clientList"
               autofocus
               placeholder="Клиент"
               bindLabel="clientInfo"
               [addTag]="false"
               [hideSelected]="true"
               [loading]="selectLoading"
               [typeahead]="clientInput$"
               [(ngModel)]="client">
    </ng-select>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modalClientOpen(clientCreateModal)">Создать нового</button>
    <button type="button" class="btn btn-success" [disabled]="!client" (click)="modal.close(client)">Выбрать</button>
  </div>

</ng-template>

<ng-template #clientCreateModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Добавление клиента</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-client-create
      (OnCancel)="onClientCreateCancel(modal)"
      (OnClientCreate)="onClientCreate($event, modal)">
    </app-client-create>
  </div>

</ng-template>
