import { DOCUMENT } from '@angular/common';
import {
  Inject,
  Injectable,
} from '@angular/core';
import { LogoConfiguration } from '@app/services/configuration/configurations';
import { ConfigurationsService } from '@app/services/configuration/configurations.service';

@Injectable()
export class FaviconSetterService {

  constructor(
    private configurationsService: ConfigurationsService,
    @Inject(DOCUMENT)
    private document: Document,
  ) {
  }

  public async setFavicon(): Promise<void> {
    const favicon = await this.configurationsService.getValueAsync(LogoConfiguration);
    this.applyFavicon(favicon);
  }

  private applyFavicon(url: string): void {
    const faviconElement = this.document.head.querySelector<HTMLLinkElement>('link[rel="icon"]');
    if (!faviconElement) {
      return;
    }
    faviconElement.href = url;
  }
}
