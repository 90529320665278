<button (click)="goBack()" class="btn btn-primary">Назад</button>

<button *ngIf="order?.is_with_issues" (click)="goToIssues()" class="btn ml-5 btn-warning text-black-50">Перейти к
  проблемам
</button>


<app-loading-message *ngIf="loading">
  Загрузка данных о счете
</app-loading-message>

<div *ngIf="!loading" class="card">
  <div class="card-header">
    <div class="d-flex flex-column">
      <h3 class="card-title"><b>Счет № {{order.order_number}}</b> от
        {{order.created_at | date: 'dd.MM.yyyy'}} ({{order.creator.last_name}} {{order.creator.first_name}})
      </h3>
      <div [ngClass]="{'client-editable': isChangeMode}" (click)="isChangeMode && openClientModal(clientSelectModal)">
        {{client.legal_name}} ({{client.city}} {{client.INN}})
      </div>
    </div>
    <div class="card-options">
      <button (click)="copyOrder()" type="button" class="form-control btn btn-outline-info mr-4">
        <i class="fe fe-copy"></i> Копировать
      </button>
      <button (click)="allowChangeOrder()"
              type="button"
              class="form-control btn btn-outline-info mr-4"
              [disabled]="!isOrderEditable()">
        <i class="fe fe-edit"></i> Изменить
      </button>
      <button (click)="openAddProductModal()"
              type="button"
              class="form-control btn btn-outline-info mr-4"
              [disabled]="!isOrderEditable()">
        <i class="fe fe-plus-square"></i> Добавить товар
      </button>
      <select class="form-control" (change)="changeStatus($event.target.value)">
        <option *ngFor="let item of orderStatuses" [selected]="item.code === order.status"
                value="{{ item.code }}">{{ item.title }}</option>
      </select>
    </div>
    <div>
    </div>
  </div>

  <div class="card-body">

    <button type="button" class="btn btn-outline-primary mb-1" (click)="infoIsCollapsed = !infoIsCollapsed"
            [attr.aria-expanded]="!infoIsCollapsed" aria-controls="collapseInfo">
      Примечания
    </button>
    <button type="button" class="btn btn-outline-success ml-2 mb-1" (click)="paymentsIsCollapsed = !paymentsIsCollapsed"
            [attr.aria-expanded]="!paymentsIsCollapsed" aria-controls="collapsePayments">
      Платежи
    </button>

    <button type="button" class="btn btn-outline-secondary ml-2 mb-1"
            (click)="productOrdersIsCollapsed = !productOrdersIsCollapsed"
            aria-controls="collapseExample">
      Отобразить заказы
    </button>

    <button type="button" class="btn btn-outline-danger ml-2 mb-1"
            (click)=openCreateIssueModal()
            aria-controls="collapseExample">
      Создать проблему
    </button>

    <button *ngIf="isOrderEditable()" type="button" class="btn btn-outline-primary float-right ml-2 mb-1"
            (click)=openRecalculateModal()
            aria-controls="collapseExample">
      Пересчитать счет
    </button>
    <ng-container *ngIf="isOrderEditable()" [formGroup]="changeOrderForm">
      <ng-select [items]="this.currencyTypes$ | async"
                 class="form-control custom-select w-auto d-inline-block float-right"
                 [required]="true"
                 [searchable]="true"
                 [clearable]="false"
                 (change)="isOrderChanged = true"
                 formControlName="currency"
                 bindLabel="title"
                 bindValue="code"
                 required>
      </ng-select>
    </ng-container>

    <div *ngIf="!isOrderEditable()" class="d-inline-block float-right">
      {{ order.currency }}
    </div>

    <div id="collapsePayments" [ngbCollapse]="paymentsIsCollapsed">
      <div class="card">
        <table class="table table-striped">
          <thead>
          <tr>
            <th scope="col" (click)="openPaymentModal(createPaymentModal)"><i class="fe fe-plus text-green"></i></th>
            <th scope="col">Номер п/п</th>
            <th scope="col">Курс</th>
            <th scope="col">Дата</th>
            <th scope="col">Сумма</th>
          </tr>
          </thead>
          <tbody>
          <tr class="row-index" *ngFor="let payment of paymentsList; index as i">
            <td class="text-center"><span class="span-index">{{ i + 1 }}</span>
              <a class="remove-btn" href="javascript:void(0)">
                <i class="fa fa-minus-circle text-red" (click)="deletePaymentModalOpen(payment)"></i>
              </a>
            </td>
            <td>{{ payment.number }}</td>
            <td>{{ payment.exchange_rate }}</td>
            <td>{{ payment.payment_date|date: 'dd.MM.yyyy' }}</td>
            <td [ngClass]="{
                    'alert-danger': payment.payment_type == paymentType.REVERSING
                    }">
              <ng-container *ngIf="payment.payment_type == paymentType.REVERSING">-</ng-container>
              {{ payment.amount }}
            </td>

          </tr>

          <ng-container *ngIf="!this.paymentsList">
            <tr>
              <td colspan="5">
                <app-loading-message>
                  Получение списка платежей
                </app-loading-message>
              </td>
            </tr>
          </ng-container>
          <ng-container *ngIf="this.paymentsList">
            <tr>
              <td [attr.colspan]="4" class="font-weight-bold text-uppercase text-right">Итого оплачено</td>
              <td class="font-weight-bold">{{ getTotalPayments() | number:'1.2-2' }}</td>
            </tr>
            <tr>
              <td [attr.colspan]="4" class="font-weight-bold text-uppercase text-right">Остаток</td>
              <td class="font-weight-bold">{{ leftToPay() | number:'1.2-2' }}</td>
            </tr>
            <tr>
              <td [attr.colspan]="4" class="font-weight-bold text-uppercase text-right">Итого возвращено</td>
              <td class="font-weight-bold">{{ getReversingTotalPayments() | number:'1.2-2' }}</td>
            </tr>
          </ng-container>

          </tbody>
        </table>
      </div>
    </div>

    <div id="collapseInfo" [ngbCollapse]="infoIsCollapsed">
      <div class="form-check">
        <input class="form-check-input"
               type="checkbox"
               value=""
               id="includeOfertaCheckbox"
               (change)="toggleIncludeOfertaParameter($event)"
               [checked]="(this.order.parameters.include_oferta === undefined) ? true: this.order.parameters.include_oferta"
               [disabled]="!isOrderEditable()">
        <label class="form-check-label" for="includeOfertaCheckbox">
          Отображать 2 пункт оферты в счете
        </label>
      </div>

      <div class="card" [formGroup]="changeOrderForm">
            <textarea class="form-control"
                      formControlName="addition_info"
                      (change)="isOrderChanged = true"
                      rows="3"
                      placeholder="Примечания к счету">
            </textarea>
      </div>
    </div>


    <table class="table table-bordered table-hover">
      <tr>
        <th *ngIf="isChangeMode" class="text-center" style="width: 1%">Удалить</th>
        <th *ngIf="!isChangeMode" class="text-center actions" style="width: 1%">Отгрузить</th>
        <th *ngIf="isChangeMode" class="text-center" style="width: 1%">Коментарий</th>
        <th class="text-center" style="width: 1%">No.</th>
        <th>Наименование</th>
        <th class="text-center" style="width: 1%; white-space: nowrap;">Кол-во</th>
        <th class="text-center price" style="width: 1%; white-space: nowrap;">Цена</th>
        <th class="text-center price" style="width: 1%; white-space: nowrap;">Стоимость</th>
        <th class="text-center" style="width: 1%; white-space: nowrap;">Склад/резерв</th>
        <th class="text-center" style="width: 1%; white-space: nowrap;">Уд. склад</th>
        <th class="text-center" style="width: 1%; white-space: nowrap;">В пути</th>
        <th class="text-center" style="width: 1%; white-space: nowrap;">Отгружено</th>
        <th></th>
      </tr>
      <ng-container *ngFor="let item of orderLines; let i = index">
        <tr [ngClass]="{
                  'alert-success': item.is_shipped,
                  'alert-warning': item.shipped_quantity != 0 && !item.is_shipped,
                  'alert-danger': item.removed,
                  'alert-info': item.is_new,
                  'alert-dark': item.is_canceled
                }" class="col-2">

          <td *ngIf="isChangeMode" class="text-center">
            <a *ngIf="isLineRemoveAvaliable(item)" class="remove-btn" href="javascript:void(0)">
              <i class="fa fa-minus-circle text-red" (click)="removeProductFromOrder(item)"></i>
            </a>
          </td>

          <td *ngIf="!isChangeMode" class="actions">
            <ng-container *ngIf="isSelectToSnipmentActionVisible(item)">
              <label class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" checked=""
                       [(ngModel)]="item.is_selected_to_shipment">
                <span class="custom-control-label"></span>
              </label>
            </ng-container>
            <ng-container *ngIf="isSnipmentActionVisible(item)">
              <button class="btn btn-primary"
                      type="button"
                      [disabled]="order.status == orderStatus.CLOSED || item.is_shipped == true"
                      (click)="shipmentModalOpen(item)">
                Отгрузить
              </button>
            </ng-container>
          </td>
          <td *ngIf="isChangeMode" class="text-center">
            <input class="form-control"
                   type="text"
                   (change)="orderChange(item)"
                   [(ngModel)]="item.comment">
          </td>
          <td class="text-center">{{ i + 1 }}</td>
          <td>
            <p class="mb-1">
              <ng-container *ngIf="!isLineChangeProductEditable(item)">

                <i *ngIf="item.comment" class="fa fa-info-circle mr-2"
                   placement="left"
                   [ngbPopover]="productInfoContent"
                   popoverClass="info-popover">
                </i>

                <ng-template #productInfoContent>
                  {{ item.comment }}
                </ng-template>

                {{ item.product.name }} {{ item.product.category.name }}
              </ng-container>
              <ng-container *ngIf="isLineFieldsEditable(item)">
                <a *ngIf="!isLineChangeProductEditable(item)" href="javascript:void(0)" class="icon"
                   (click)="toggleLineChangeProductEditable(item)"><i class="fe fe-edit float-right"></i></a>
                <ng-select *ngIf="isLineChangeProductEditable(item)" [items]="productService.products$ | async"
                           autofocus
                           placeholder="Товар"
                           bindLabel="name"
                           [clearable]="false"
                           [hideSelected]="true"
                           [loading]="productService.selectLoading"
                           [typeahead]="productService.searchQuery$"
                           (change)="isOrderChanged = true"
                           [(ngModel)]="item.product">
                </ng-select>
              </ng-container>
            </p>
          </td>
          <td>
            <p *ngIf="!isLineFieldsEditable(item)" class="mb-1">{{ item.quantity | number:'1.2-2' }}</p>
            <ng-container *ngIf="isLineFieldsEditable(item)">
              <input class="qty"
                     type="number"
                     (change)="orderChange(item)"
                     [(ngModel)]="item.quantity">
              <div class="outer-button">
                <div (click)="quantityInc(item)" class="inc button"><i class="fa fa-plus"></i></div>
                <div (click)="quantityDec(item)" class="dec button"><i class="fa fa-minus"></i></div>
              </div>
            </ng-container>
          </td>
          <td>
            <p *ngIf="!isLineFieldsEditable(item)"
               class="font-w600 mb-1">{{ item.price | number:'1.2-2' }}</p>
            <ng-container *ngIf="isLineFieldsEditable(item)">
              <input (change)="orderChange(item)"
                     [(ngModel)]="item.price"
                     class="qty"
                     type="text"
                     appOnlyNumber>
            </ng-container>
          </td>
          <td>{{ item.cost| number:'1.2-2' }}</td>
          <td>
            <p class="font-w600 mb-1">{{ item.product.available_quantity || 0 }}/{{item.reserved_quantity}}
              <i *ngIf="item.reserved_quantity"
                 class="fa fa-trash text-red btn"
                 (click)="removeReservation(item)">
              </i>
            </p>
          </td>
          <td>
            <p class="font-w600 mb-1">{{ item.product.external_quantity }}
            </p>
          </td>
          <td>
            <p class="font-w600 mb-1">{{ item.product.transit_quantity || 0 }}</p>
          </td>
          <td>
            <p class="font-w600 mb-1">{{ item.shipped_quantity }}</p>
          </td>

          <td>
            <i class="fa fa-info-circle mr-2"
               [ngClass]="{
                      'text-green': getTotalOrdered(item) >= item.quantity || !item.is_product_order_required,
                      'text-yellow': getTotalOrdered(item) > 0 && getTotalOrdered(item) < item.quantity
                      }"
               placement="left"
               [ngbPopover]="infoContent"
               popoverClass="info-popover">
            </i>

            <i class="fe fe-plus-square"
               (click)="openCreateOrderModal(item)">
            </i>

            <ng-template #infoContent>
              <table>
                <tr>
                  <th class="text-center" style="width: 1%; white-space: nowrap;">В заказе</th>
                  <th class="text-center" style="width: 1%; white-space: nowrap;">Поставщик</th>
                  <th class="text-center" style="width: 1%; white-space: nowrap;">Ожидаемая дата</th>
                </tr>
                <tr *ngFor="let productOrder of item.product_orders; index as i">
                  <td>
                    <p class="font-w600 mb-1">{{ productOrder.quantity || 0 }}</p>
                  </td>
                  <td>
                    <p class="font-w600 mb-1">{{ productOrder.shipper_title || '-' }}</p>
                  </td>
                  <td>
                    <p class="font-w600 mb-1">{{ productOrder.expected_date || '-' }}</p>
                  </td>
                </tr>
              </table>
            </ng-template>
          </td>
        </tr>

        <tr id="productOrdersIsCollapsed"
            [ngbCollapse]="productOrdersIsCollapsed"
            *ngIf="item.overallProductOrders">
          <td [attr.colspan]="isChangeMode ? 11 : 10">
            <table>
              <tr>
                <th class="text-center" style="width: 1%; white-space: nowrap;">№</th>
                <th class="text-center" style="width: 1%; white-space: nowrap;">В заказе</th>
                <th class="text-center" style="width: 1%; white-space: nowrap;">Поставщик</th>
                <th class="text-center" style="width: 1%; white-space: nowrap;">Ожидаемая дата</th>
              </tr>
              <tr *ngFor="let productOrder of item.overallProductOrders; index as i">
                <td>
                  <p class="font-w600 mb-1">{{ i + 1 }}</p>
                </td>
                <td>
                  <p class="font-w600 mb-1">{{ productOrder.quantity || 0 }}</p>
                </td>
                <td>
                  <p class="font-w600 mb-1">{{ productOrder.shipper_title || '-' }}</p>
                </td>
                <td>
                  <p class="font-w600 mb-1">{{ productOrder.expected_date || '-' }}</p>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </ng-container>

      <tr>
        <td [attr.colspan]="isChangeMode ? 11 : 10" class="font-w600 text-right">Итого</td>
        <td class="text-right">{{ calculatedOrder.orderSubTotal | number:'1.2-2' }}</td>
      </tr>
      <tr>
        <td [attr.colspan]="isChangeMode ? 11 : 10" class="font-w600 text-right">НДС</td>
        <td class="text-right">{{ saleService.vatValue }}%</td>
      </tr>
      <tr>
        <td [attr.colspan]="isChangeMode ? 11 : 10" class="font-w600 text-right">Итого НДС</td>
        <td class="text-right">{{ calculatedOrder.vatTotal | number:'1.2-2' }}</td>
      </tr>
      <tr>
        <td [attr.colspan]="isChangeMode ? 11 : 10" class="font-weight-bold text-uppercase text-right">Итого к оплате
        </td>
        <td class="font-weight-bold text-right">{{ calculatedOrder.orderTotal | number:'1.2-2' }}</td>
      </tr>
    </table>
    <button (click)="selectToShipment(orderLines)"
            type="button"
            class="btn btn-primary">Отметить к отгрузке
    </button>
    <button [disabled]=!isOrderChanged (click)="saveOrder()" type="button" class="btn btn-success float-right">
      <i class="fe fe-check"></i> Сохранить
    </button>
  </div>

</div>

<ng-template #clientSelectModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Изменение клиента</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ng-select [items]="clientList$ | async"
               autofocus
               placeholder="Клиент"
               bindLabel="clientInfo"
               [clearable]="false"
               [addTag]="false"
               [hideSelected]="true"
               [loading]="selectLoading"
               [typeahead]="clientInput$"
               [(ngModel)]="client">
    </ng-select>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="!client" (click)="modal.close(client)">Выбрать</button>
  </div>

</ng-template>


<ng-template #createPaymentModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Добавление платежа</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <form [formGroup]="createPaymentForm">

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label">Номер счета</label>
        <div class="col-8 input-group align-content-center">{{ order.order_number }}</div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label">Тип платежа</label>
        <div class="col-8">
          <ng-select [items]="this.paymentTypes$ | async"
                     class="form-control custom-select w-auto"
                     [required]="true"
                     [searchable]="true"
                     [clearable]="false"
                     formControlName="payment_type"
                     bindLabel="title"
                     bindValue="code"
                     required>
          </ng-select>
        </div>
      </div>


      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="number">Номер п/п</label>
        <div class="col-8">
          <input formControlName="number"
                 class="form-control"
                 placeholder="Введите номер п/п"
                 type="text"
                 id="number"/>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="amount">Сумма</label>
        <div class="col-8">
          <input formControlName="amount"
                 class="form-control"
                 placeholder="Введите сумму"
                 type="text"
                 id="amount"
                 appOnlyNumber/>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="payment_date">Дата платежа</label>
        <div class="col-8 input-group">
          <input formControlName="payment_date"
                 class="form-control"
                 placeholder="Введите дату платежа"
                 type="text"
                 id="payment_date"
                 ngbDatepicker
                 (dateSelect)="setExchangeRate()"
                 [footerTemplate]="footerTemplate"
                 #d="ngbDatepicker"/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar"
                    type="button"
                    (click)="d.toggle()">
              <i class="fe fe-calendar"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label">Валюта счета</label>
        <div class="col-8 input-group align-content-center">{{ order.currency }}</div>
      </div>

      <div *ngIf="order.currency !== 'RUB'" class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="exchange_rate">Курс валюты</label>
        <div class="col-8 input-group">
          <input formControlName="exchange_rate"
                 class="form-control"
                 placeholder="Введите текущий курс"
                 type="text"
                 id="exchange_rate"
                 appOnlyNumber>
        </div>
      </div>
    </form>
    <ng-template #footerTemplate>
      <hr class="my-0">
      <button class="btn btn-primary btn-sm m-2 float-left"
              (click)="createPaymentForm.controls.payment_date.setValue(today); d.close()">Сегодня
      </button>
      <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d.close()">Закрыть</button>
    </ng-template>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" [disabled]="!createPaymentForm.valid" (click)="modal.close()">
      Добавить
    </button>
  </div>

</ng-template>
