import { Injectable } from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {

  public filterParams: BehaviorSubject<any>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.filterParams = new BehaviorSubject(this.getQueryParameters());
  }

  public updateQueryParameter(title: string, value: string) {
    const queryParams = this.getQueryParameters();
    queryParams[title] = value;

    Object.keys(queryParams).forEach(param => {
      if (!queryParams[param]) {
        queryParams[param] = null;
      }
    });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams
    });
    this.filterParams.next(
      Object.assign(queryParams, {
        [title]: value,
      })
    );
  }

  public updateQueryParameters(params: any) {
    const queryParams = this.getQueryParameters();
    const newParams = Object.assign(queryParams, params);

    Object.keys(newParams).forEach(param => {
      if (!newParams[param]) {
        delete newParams[param];
        // newParams[param] = null;
      }
    });

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: newParams
    });
    this.filterParams.next(newParams);
  }

  public getQueryParameters() {
    return Object.assign({}, this.route.queryParams['value']);
  }

}
