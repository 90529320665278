import {Component, OnInit} from '@angular/core';

import {DataProviderService} from '../../../services/data/data-provider.service';
import {StockShipment} from '../../../models/stock';
import {concat, of} from 'rxjs';
import {Router} from '@angular/router';
import {StockShipmentService} from './stock-shipment.service';
import {first, map} from 'rxjs/operators';

@Component({
  selector: 'app-stock-shipment',
  templateUrl: './stock-shipment.component.html',
  styleUrls: ['./stock-shipment.component.scss']
})
export class StockShipmentComponent implements OnInit {
  stockShipments: StockShipment[];
  loading = false;

  constructor(
    private router: Router,
    private dataProvider: DataProviderService,
    private stockShipmentService: StockShipmentService,
  ) {
  }

  private getStockShipments() {
    this.stockShipmentService.getStockShipments().pipe(
      first())
      .subscribe(resp => {
          this.stockShipments = resp;
        }
      );
  }

  navigateStockAddressDetail(id): void {
    this.router.navigate(['/stock', 'shipments', id]);
  }

  ngOnInit() {
    this.getStockShipments();
  }

}
