import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NavComponent} from './components/nav/nav.component';
import {HomeComponent} from './components/home/home.component';
import {StockRecordComponent} from './components/stock/stock-record.component';
import {LoadingMessageModule} from './shared/loading-message/loading-message.module';
import {LoginComponent} from './components/login/login.component';
import {TokenInterceptor} from './interceptors/token.interceptor';
import {TableEditComponent} from './components/stock/product/table-edit/table-edit.component';
import {TableDisplayComponent} from './components/stock/product/table-display/table-display.component';
import {ModalCreateComponent} from './components/stock/product/modal-create/modal-create.component';
import {SaleComponent} from './components/sale/sale.component';
import {OrderCreateComponent} from './components/sale/order-create/order-create.component';
import {ModalAddStockRecordComponent} from './components/stock/product/table-display/modal-add-stock-record/modal-add-stock-record.component';
import {StockRecordAddressComponent} from './components/stock/stock-address/stock-record-address.component';
import {ProductFilterComponent} from './components/stock/product/product-filter/product-filter.component';
import {ProductDetailComponent} from './components/stock/product/product-detail/product-detail.component';
import {OrderFilterComponent} from './components/sale/order-filter/order-filter.component';
import {OrderDetailComponent} from './components/sale/order-detail/order-detail.component';
import {NotifyMessageModule} from './shared/notify-message/notify-message.module';
import {ModalShipmentComponent} from './components/sale/order-detail/modal-shipment/modal-shipment.component';
import {ForbiddenComponent} from './components/forbidden/forbidden.component';
import {AddProductModalComponent} from './components/sale/add-product-modal/add-product-modal.component';
import {ProductCreateComponent} from './components/stock/product/product-create/product-create.component';
import {ClientCreateComponent} from './components/sale/client/client-create/client-create.component';
import {ManufacturersListModule} from './modules/manufacturers-list/manufacturers-list.module';
import {PaymentsListModule} from './modules/payments-list/payments-list.module';
import {DirectivesModule} from './shared/directives/directives.module';
import {ModalDeletePaymentComponent} from './components/sale/order-detail/modal-delete-payment/modal-delete-payment.component';
import {OrderedProductsListModule} from './modules/ordered-products-list/ordered-products-list.module';
import {StockRecordAddressDetailComponent} from './components/stock/stock-address/stock-address-detail/stock-record-address-detail.component';
import {ShipmentListModule} from './modules/shipment-list/shipment-list.module';
import {ModalCreateOrderComponent} from './components/sale/order-detail/modal-create-order/modal-create-order.component';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {IssuesModule} from './modules/issues/issues.module';
import {ModalIssuesCreateComponent} from './components/sale/order-detail/modal-issues-create/modal-issues-create.component';
import {StockShipmentComponent} from './components/stock/stock-shipment/stock-shipment.component';
import {StockShipmentDetailComponent} from './components/stock/stock-shipment/stock-shipment-detail/stock-shipment-detail.component';
import {ModalRecalculateComponent} from './components/sale/order-detail/modal-recalculate/modal-recalculate.component';
import {ConfigurationsService} from './services/configuration/configurations.service';
import {FaviconSetterModule} from '@app/modules/favicon-setter/favicon-setter.module';
import localeRu from '@angular/common/locales/ru';
import {registerLocaleData} from '@angular/common';


registerLocaleData(localeRu);

export function ConfigurationsServiceFactory(configurationsService: ConfigurationsService) {
  return async () => {
    await configurationsService.updateConfigurations();
  };
}


@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    StockRecordComponent,
    LoginComponent,
    TableEditComponent,
    TableDisplayComponent,
    ModalCreateComponent,
    SaleComponent,
    OrderCreateComponent,
    ModalAddStockRecordComponent,
    StockRecordAddressComponent,
    StockRecordAddressDetailComponent,
    StockShipmentComponent,
    StockShipmentDetailComponent,
    ProductFilterComponent,
    ProductDetailComponent,
    OrderFilterComponent,
    OrderDetailComponent,
    ModalShipmentComponent,
    ModalCreateOrderComponent,
    ModalDeletePaymentComponent,
    ModalIssuesCreateComponent,
    ModalRecalculateComponent,
    ForbiddenComponent,
    AddProductModalComponent,
    ProductCreateComponent,
    ClientCreateComponent,
  ],
  imports: [
    FaviconSetterModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    NgSelectModule,
    LoadingMessageModule,
    DirectivesModule,
    NotifyMessageModule,
    ManufacturersListModule,
    PaymentsListModule,
    OrderedProductsListModule,
    ShipmentListModule,
    IssuesModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'ru'},
    HttpClientModule,
    ConfigurationsService,
    {
      provide: APP_INITIALIZER,
      deps: [
        ConfigurationsService,
      ],
      useFactory: ConfigurationsServiceFactory,
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
