export namespace STORAGE_NAME {
  export const CURRENT_USER_TOKEN = 'authToken';
  export const CURRENT_USER = 'currentUser';
}

export enum Constants {
  VAT_CONSTANT = 20
}

export enum Filters {
  PERIOD = 'period',
  STATUS = 'status',
  PAGE = 'page',
  NAME = 'name',
  PRODUCT_ID = 'id'
}

export enum OrderStatus {
  CREATED = 'CREATED',
  PARTLY_PAYED = 'PARTLY_PAYED',
  PAYED = 'PAYED',
  CANCELED = 'CANCELED',
  CLOSED = 'CLOSED',
  OVER_PAYED = 'OVER_PAYED'
}

export enum StockRecordAction {
  CHANGE = 'CHANGE',
  MOVE = 'MOVE',
}

export const DEFAULT_VALUE = '-';
