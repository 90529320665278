<div class="page-header">
  <div class="col-12">
    <div class="card">
      <div class="card-header justify-content-between">
        <h3 class="card-title">Список счетов</h3>
        <div class="card-options">
          <button (click)="exportOrdersTo1C()" type="button" class="btn btn-outline-info mr-4">
            <i class="fe fe-plus-square"></i> Выгрузить в 1С
          </button>
          <button [routerLink]="['/sale', 'order', 'create']" type="button" class="btn btn-outline-info mr-4">
            <i class="fe fe-plus-square"></i> Создать счет
          </button>
        </div>
      </div>
      <div class="filters">
        <app-order-filter></app-order-filter>
      </div>
      <div class="table-responsive">
        <table class="table table-hover card-table table-vcenter text-nowrap">
          <thead>
          <tr>
            <th class="w-1">No.</th>
            <th>Компания</th>
            <th>Клиент</th>
            <th>Статус</th>
            <th>Дата создания</th>
            <th>Валюта</th>
            <th>Сумма счета</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="loading">
            <td colspan="5">
              <app-loading-message>
                Загрузка счетов
              </app-loading-message>
            </td>
          </tr>
          <ng-container *ngIf="!loading">
            <ng-container *ngFor="let order of orders; let i = index">
              <tr (click)="navigateOrderDetail(order.id)"
                    [ngClass]="{
                    'alert-success': order.status == orderStatus.PAYED,
                    'alert-warning': order.status == orderStatus.CANCELED || order.status == orderStatus.OVER_PAYED,
                    'alert-primary': order.status == orderStatus.PARTLY_PAYED,
                    'alert-secondary': order.status == orderStatus.CLOSED
                    }">
                <td>
                  <i *ngIf="order.is_with_issues" class="fas fa-exclamation text-red"></i> {{order.order_number}}
                </td>
                <td>
                  {{order.company.name}}
                </td>
                <td>
                  {{order.client.legal_name}}
                </td>
                <td>
                  {{order.statusTitle}}
                </td>
                <td>
                  {{order.created_at|date: 'dd.MM.yyyy'}}
                </td>
                <td>
                  {{order.currency}}
                </td>
                <td>
                  {{order.total}}
                </td>
                <td>
                  <a class="mr-4" href="javascript:void(0)" (click)="$event.stopPropagation();exportOrder(order.id, {'output_format': 'xlsx'})"><img height="35px" src="assets/img/excel-icon.png" alt=""></a>
                  <a href="javascript:void(0)" (click)="$event.stopPropagation();exportOrder(order.id, {'output_format': 'pdf'})"><img height="25px" src="assets/img/pdf-icon.png" alt=""></a>
                </td>
              </tr>
            </ng-container>
          </ng-container>
            <tr>
              <td [attr.colspan]="6" class="font-weight-bold text-uppercase text-right">Итого по счетам RUB</td>
              <td class="font-weight-bold">{{ getTotalPayments('RUB') | number:'1.2-2' }}</td>
            </tr>
            <tr>
              <td [attr.colspan]="6" class="font-weight-bold text-uppercase text-right">Итого по счетам EUR</td>
              <td class="font-weight-bold">{{ getTotalPayments('EUR') | number:'1.2-2' }}</td>
            </tr>
            <tr>
              <td [attr.colspan]="6" class="font-weight-bold text-uppercase text-right">Итого по счетам USD</td>
              <td class="font-weight-bold">{{ getTotalPayments('USD') | number:'1.2-2' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <ngb-pagination class="d-flex justify-content-center" (pageChange)="paginationPage = $event" [pageSize]="pagination.per_page" [(page)]="pagination.current_page" [collectionSize]="pagination.count" [maxSize]="5"></ngb-pagination>

  </div>

</div>
