import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {DataProviderService} from '../../services/data/data-provider.service';
import {Product} from '../../models/stock';
import {AuthService} from '../../services/auth/auth.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {NotifyMessageService} from '../../shared/notify-message/notify-message.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  products: Product[];
  changedProduct: Product;
  title: string;
  loading = true;
  createTnvedForm: FormGroup;
  submitted = false;
  tnvedModal: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private modalService: NgbModal,

    private authService: AuthService,
    private dataProvider: DataProviderService,
    private notifierService: NotifyMessageService,
  ) { }

  ngOnInit() {
    this.title = this.route.snapshot.data['title'];
    this.getProductsWithoutTnved({'empty_tnved': true, 'payed': true});
    this.createTnvedForm = this.formBuilder.group({
      code: ['', Validators.required],
      tax: ['', Validators.required],
      graph31: ['', Validators.required],
      product: ['', Validators.required],
    });
  }

  get f() {
    return this.createTnvedForm.controls;
  }

  getProductsWithoutTnved(params?): void {
    this.loading = true;

    this.dataProvider.getProductList(params)
      .subscribe(
        res => {
          this.products = res.results;
          this.loading = false;
        }
      );
  }

  submitTnvedForm() {
    this.submitted = true;
    if (this.createTnvedForm.invalid) {
      this.submitted = false;
      return;
    }
    this.createTnved(this.createTnvedForm.value);
  }

  createTnved(data): void {
    if (!this.changedProduct) {
      return;
    }
    this.loading = true;
    this.dataProvider.createTnved(data).pipe(
      finalize(() => {
        this.loading = false;
        this.submitted = false;
      }),
    )
      .subscribe(
        tnved => {
          this.dataProvider.changeProduct(this.changedProduct.id, {'tnved': tnved.id})
            .subscribe(
              order => {
                // this.notifierService.notify('ТН ВЭД добавлен', 'success');
              },
              err => {
                console.log(err);
                // this.notifierService.notify('Ошибка добавления ТН ВЭД', 'danger');
              },
            );
          this.tnvedModal.close();
          this.products.splice(this.products.indexOf(this.changedProduct), 1);
          delete this.changedProduct;
          this.submitted = false;
          this.notifierService.notify('ТН ВЭД добавлен', 'success');
        },
        err => {
          console.log(err);
          this.notifierService.notify('Ошибка добавления ТН ВЭД', 'danger');
        },
      );
  }

  modalTnvedOpen(content, product) {

    this.tnvedModal = this.modalService.open(content, {'backdrop': 'static'});
    this.changedProduct = product;
    this.createTnvedForm.controls['product'].setValue(product.id);
    //
    // this.tnvedModal.result.then((result) => {
    //   this.dataProvider.changeProduct(product.id, {'tnved': result})
    //     .subscribe(
    //       order => {
    //         this.notifierService.notify('ТН ВЭД добавлен', 'success');
    //       },
    //       err => {
    //         console.log(err);
    //         this.notifierService.notify('Ошибка добавления ТН ВЭД', 'danger');
    //       },
    //     );
    // }, (reason) => {
    //   console.log(reason);
    // });
  }
}
