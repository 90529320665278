import {Injectable} from '@angular/core';
import {DataProviderService} from '@app/services/data/data-provider.service';
import {first, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Company} from '@app/models/types/company.interface';


@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  constructor(
    private dataProvider: DataProviderService,
  ) {
  }

  public getCompanies(params?): Observable<Company[]> {
    return this.dataProvider.getCompanies(params)
      .pipe(
        first()
      );
  }

}
