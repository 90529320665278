import {Component, Input, OnInit} from '@angular/core';
import {first} from 'rxjs/operators';
import {DataProviderService} from '../../../../services/data/data-provider.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProductOrderDetail} from '../../../../models/sale';
import {FormBuilder} from '@angular/forms';
import {NotifyMessageService} from '../../../../shared/notify-message/notify-message.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {ProductOrderStatusEnum} from '../../../../models/enums';

@Component({
  selector: 'app-modal-shipping',
  templateUrl: './modal-close-product-order.component.html',
  styleUrls: ['./modal-close-product-order.component.scss']
})
export class ModalCloseProductOrderComponent implements OnInit {
  @Input() productOrder: ProductOrderDetail;

  loading = false;
  error = '';

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,

    private authService: AuthService,
    private notifierService: NotifyMessageService,
    private dataProvider: DataProviderService,
    ) { }

  ngOnInit() {
  }

  public closeProductOrder(): void {
    this.loading = true;
    const data = {'state': ProductOrderStatusEnum.CLOSED};
    this.dataProvider.updateProductOrder(this.productOrder.id, data).pipe(
      first()).subscribe( () => this.activeModal.close()
    );
  }

}
