import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {first, map} from 'rxjs/operators';
import {OrderLine, ProductOrder, ProductOrderDetail} from '../../../models/sale';
import {ModalCloseProductOrderComponent} from './modal-close-product-order/modal-close-product-order.component';
import {OrderedProductsService} from '../ordered-products.service';

@Component({
  selector: 'app-payments-list',
  templateUrl: './overdue-order-list.component.html',
  styleUrls: ['./overdue-order-list.component.scss']
})
export class OverdueOrderListComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public isOverdueLoading = false;
  public overdueOrderLines: ProductOrderDetail[] = [];
  // public pageSize = 50;
  // public collectionSize = undefined;
  private _subscription: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private modalConfig: NgbModalConfig,
    private orderedProductsService: OrderedProductsService,
  ) {
    this._subscription = new Subscription();
    modalConfig.backdrop = 'static';
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private subscribe() {
    this._subscription.add(
      this.getOverdueList().subscribe()
    );
  }

  public closeProductOrder(productOrder: ProductOrder): void {
    const modalRef = this.modalService.open(ModalCloseProductOrderComponent);
    modalRef.componentInstance.productOrder = productOrder;
    modalRef.result.then(result => {
      this.getOverdueList().pipe(first()).subscribe();
    }, () => {});
  }

  private getOverdueList(params?): Observable<any> {
    this.isOverdueLoading = true;
    return this.orderedProductsService.getOverdueOrderProducts(params)
      .pipe(
        first(),
        map(resp => {
          this.overdueOrderLines = resp;
          this.isOverdueLoading = false;
        }));
  }

  public navigateOrderDetail(id): void {
    this.router.navigate(['/sale', 'order', id]);
  }

}
