<div class="page-header">

  <div class="col-12">
    <div class="card">
      <div class="card-header justify-content-between">
        <h3 class="card-title">Посылки</h3>

      </div>
      <div class="table-responsive">
        <table class="table card-table table-vcenter text-nowrap table-hover">
          <thead>
          <tr>
            <th>Название</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="loading">
            <td colspan="5">
              <app-loading-message>
                Получение записей
              </app-loading-message>
            </td>
          </tr>
          <ng-container *ngIf="!loading">
            <ng-container *ngFor="let item of stockShipments; let i = index">
              <tr (click)="navigateStockAddressDetail(item.id)">
                <td>
                  {{item.number}}
                </td>
              </tr>
            </ng-container>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>

  </div>

</div>
