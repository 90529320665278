<div class="modal-header">
  <h4 class="modal-title">Добавление производителя</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">x
  </button>
</div>
<div class="modal-body">

  <div class="create-form">
    <form [formGroup]="createManufacturerForm">

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="name">Название</label>
        <div class="col-8">
          <input formControlName="name"
                 class="form-control"
                 placeholder="Введите название"
                 type="text"
                 id="name"/>
        </div>
      </div>

      <div class="row justify-content-end mr-0">
        <button type="button"
                class="btn btn-outline-danger mr-2"
                [disabled]="isLoading"
                (click)="onCancel()">Отменить
        </button>
        <button type="submit"
                class="btn btn-outline-success"
                [disabled]="isLoading"
                (click)="createManufacturer()">Добавить
        </button>
      </div>
    </form>
  </div>
</div>

