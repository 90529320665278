import {Component, HostListener, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Location} from '@angular/common';

import {DataProviderService} from '../../../../services/data/data-provider.service';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {AuthService} from '../../../../services/auth/auth.service';
import {DEFAULT_VALUE} from '../../../../lib/constants';
import {ProductService} from '../../product/product.service';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n, toApiDate} from '../../../../shared/datepicker';
import {StockShipment, StockShipmentDetail} from '../../../../models/stock';
import {first, map} from 'rxjs/operators';
import {StockShipmentService} from '../stock-shipment.service';

@Component({
  selector: 'app-stock-shipment-detail',
  templateUrl: './stock-shipment-detail.component.html',
  styleUrls: ['./stock-shipment-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class StockShipmentDetailComponent implements OnInit {
  public isSticky = false;
  public shipment: StockShipmentDetail;
  public defaultVal = DEFAULT_VALUE;
  public loading = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authService: AuthService,
    private dataProvider: DataProviderService,
    private stockShipmentService: StockShipmentService,
  ) {
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    this.isSticky = window.pageYOffset >= 250;
  }

  ngOnInit() {
    this.route.params.subscribe(() => this.init());
  }

  private getStockShipments(id, params?) {
    this.stockShipmentService.getStockShipmentDetail(id).pipe(
      first(),
      map((item: StockShipmentDetail) => {
          item.records.forEach(record => {
            const lines = record.product.order_lines.filter(
              line => (Number(line.quantity - line.reserved_quantity - line.shipped_quantity) === Number(record.quantity))
            );
            if (lines.length > 0) {
              lines[0].is_selected_to_letter = true;
              lines[0].qty_to_letter = record.quantity;
            }
          });
          return item;
        }
      ))
      .subscribe(resp => {
          this.shipment = resp;
          this.loading = false;
        }
      );
  }

  public goBack(): void {
    this.location.back();
  }

  public exportLetter(): void {
    const data = [];
    this.shipment.records.forEach(record => {
      const export_lines = record.product.order_lines.filter(line => line.is_selected_to_letter);
      export_lines.forEach(line => data.push({'id': line.id, 'quantity': line.qty_to_letter}));
    });
    this.stockShipmentService.exportShipmentLetter({'export_lines': data, 'shipment_id': this.shipment.id})
      .subscribe(
        response => {
          const headers = response.headers;
          const contentType = headers.get('content-type');
          const options = {type: contentType};
          const filename = StockShipmentService.getFileNameFromHttpResponse(response);
          StockShipmentService.downloadFile(response.body, options, filename);
        },
        error => {
          console.log('Error downloading the file.' + JSON.stringify(error));
        }
      );
  }

  private init() {
    this.getStockShipments(this.route.snapshot.paramMap.get('id'));
  }
}
