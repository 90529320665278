import { NgModule } from '@angular/core';
import {
  RouterModule,
  Routes
} from '@angular/router';
import { ManufacturersListComponent } from './manufacturers-list/manufacturers-list.component';

const routes: Routes = [
  {
    path: '',
    component: ManufacturersListComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes)
  ],
  exports: [
    RouterModule,
  ],
})
export class ManufacturersListRoutingModule {

}
