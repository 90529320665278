<button (click)="goBack()" class="btn btn-primary">Назад</button>

<app-loading-message *ngIf="loading">
  Загрузка данных
</app-loading-message>

<div *ngIf="!loading"
     class="my-3 my-md-5 col-12 card">

  <div class="page-header">
    <h1 class="page-title">
      <span class="product-name">{{ address.name }}</span>
      <span class="product-tnved">
      </span>
    </h1>
  </div>

  <div class="table-responsive">
    <table class="table card-table table-vcenter text-nowrap">
      <thead>
      <tr>
        <th>Наименование</th>
        <th>Количество</th>
        <th>Единица измерения</th>
        <th>Категория</th>
        <th>Страна</th>
        <th>Код ГТД</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let item of address.stock_records">
        <td>{{item.product.name}}</td>
        <td>{{item.quantity}}</td>
        <td>{{item.product.unit || defaultVal}}</td>
        <td>{{item.product.category.name || defaultVal}}</td>
        <td>{{item.country.code || defaultVal}}</td>
        <td>{{item.gtd || defaultVal}}</td>
      </tr>

      </tbody>
    </table>
  </div>
</div>
