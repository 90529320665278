import {Component, OnInit} from '@angular/core';
import {FiltersComponentDirective} from '../../../shared/filters/components/filters-component.directive';
import {DataProviderService} from '../../../services/data/data-provider.service';
import {FiltersService} from '../../../shared/filters/services/filters.service';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbDate, NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n} from '../../../shared/datepicker';
import {formatDate} from '@angular/common';
import {first} from 'rxjs/operators';
import {DictItem} from '../../../models/sale';
import {ProductOrderState} from '@app/models/types';

@Component({
  selector: 'app-order-filter',
  templateUrl: './ordered-products-filter.component.html',
  styleUrls: ['./ordered-products-filter.component.scss'],
  providers: [
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class OrderedProductsFilterComponent extends FiltersComponentDirective implements OnInit {
  public today = formatDate(new Date(), 'dd.MM.yyyy', 'en');
  public expected_date_gte: NgbDate;
  public expected_date_lte: NgbDate | null = null;
  public hoveredDate: NgbDate | null = null;
  public productOrderStates: ProductOrderState[] = [];

  constructor(
    private dataProvider: DataProviderService,
    private stockFiltersService: FiltersService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    filtersService: FiltersService,
    formBuilder: FormBuilder,
  ) {
    super(
      filtersService,
      formBuilder,
    );
    this.expected_date_gte = calendar.getToday();
    this.expected_date_lte = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getProductOrderStates();
  }

  private getProductOrderStates() {
    this.dataProvider.getProductOrderStates().pipe(
      first(),
    )
      .subscribe(resp => {
          this.productOrderStates = resp;
        }
      );
  }

  getFiltersForm() {
    return this.formBuilder.group({
      order_number: ['', Validators.required],
      product_title: ['', Validators.required],
      state: [null, Validators.required],
      expected_date_lte: ['', Validators.required],
      expected_date_gte: ['', Validators.required],
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.expected_date_gte && !this.expected_date_lte) {
      this.expected_date_gte = date;
    } else if (this.expected_date_gte && !this.expected_date_lte && date.after(this.expected_date_gte)) {
      this.expected_date_lte = date;
    } else if (this.expected_date_gte && !this.expected_date_lte && date.equals(this.expected_date_gte)) {
      this.expected_date_lte = this.expected_date_gte;
    } else {
      this.expected_date_lte = null;
      this.expected_date_gte = date;
    }
    this.filtersForm.controls.expected_date_gte.setValue(this.formatter.format(this.expected_date_gte));
    this.filtersForm.controls.expected_date_lte.setValue(this.formatter.format(this.expected_date_lte));
  }

  isHovered(date: NgbDate) {
    return this.expected_date_gte &&
      !this.expected_date_lte &&
      this.hoveredDate &&
      date.after(this.expected_date_gte) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.expected_date_lte && date.after(this.expected_date_gte) && date.before(this.expected_date_lte);
  }

  isRange(date: NgbDate) {
    return date.equals(this.expected_date_gte) ||
      (this.expected_date_lte && date.equals(this.expected_date_lte)) ||
      this.isInside(date) ||
      this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

}
