import { Injectable } from '@angular/core';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {DictItem, ProductList} from '../../../models/stock';
import {DataProviderService} from '../../../services/data/data-provider.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public products$: Observable<ProductList[]>;
  public searchQuery$ = new Subject<string>();
  public categories$: Observable<ProductList[]>;
  public categoryList: DictItem[];
  public manufacturerList: DictItem[];
  public selectLoading = false;

  constructor(
    private dataProvider: DataProviderService,
  ) { }

  public getProducts() {
    this.products$ = concat(
      of([]), // default items
      this.searchQuery$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.selectLoading = true),
        switchMap(term => this.dataProvider.getProductList({'clean_name': term}).pipe(
          map(res => res.results.map(
            (product: any) => {
              product.name = `${product.name} (${product.quantity || 0} / ${product.external_quantity} ) (${product.manufacturer.name})`;
              product.orderQuantity = 1;
              product.price = 0;
              product.cost = 0;
              return product;
            }
          )),
          catchError(() => of([])), // empty list on error
          tap(() => this.selectLoading = false)
        ))
      )
    );
  }

  public getCategories() {
    this.selectLoading = true;
    this.dataProvider.getCategoryList().subscribe(
      result => {
        this.categoryList = result;
        this.selectLoading = false;
      },
      error => console.log(error)
    );
  }

  public async getManufacturers() {
    this.selectLoading = true;
    this.manufacturerList = await this.dataProvider.getManufacturerListAll();
    this.selectLoading = false;
  }

  public getUnitTypes() {
    return this.dataProvider.getUnitTypes();
  }

}
