import {STORAGE_NAME} from '../../lib/constants';
import {map, switchMap} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs';

import {DataProviderService} from '../data/data-provider.service';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {CurrentUser, Group} from '../../models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private currentUserToken: BehaviorSubject<string>;
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _currentUser: CurrentUser;

  constructor(
    private router: Router,
    private dataProvider: DataProviderService,
  ) {
    this.currentUserToken = new BehaviorSubject<string>(localStorage.getItem(STORAGE_NAME.CURRENT_USER_TOKEN));
  }

  public get currentUser(): CurrentUser {
    if (!this._currentUser) {
      this._currentUser = JSON.parse(localStorage.getItem(STORAGE_NAME.CURRENT_USER));
    }
    return this._currentUser;
  }

  public set currentUser(value: CurrentUser) {
    this._currentUser = value;
  }

  public getFirstGroup(): string {
    if (this.currentUser.groups.length) {
      return this.currentUser.groups[0].name;
    }
    return '';
  }

  public logout() {
    this.loggedIn.next(false);
    localStorage.removeItem(STORAGE_NAME.CURRENT_USER_TOKEN);
    localStorage.removeItem(STORAGE_NAME.CURRENT_USER);
    this.router.navigate(['/login']);
  }

  login(username: string, password: string) {
    return this.dataProvider.obtainToken({'username': username, 'password': password})
      .pipe(
        map(response => {
          if (response && response.token) {
            localStorage.setItem(STORAGE_NAME.CURRENT_USER_TOKEN, response.token);
            this.currentUserToken.next(response.token);
          }
          return JSON.stringify(response.token);
        }),
        switchMap(() => this.dataProvider.getCurrentUser().pipe(map(resp => {
          localStorage.setItem(STORAGE_NAME.CURRENT_USER, JSON.stringify(resp));
          this.currentUser = resp;
          this.loggedIn.next(true);
        }))));
  }

  public get isLoggedIn() {
    if (localStorage.getItem(STORAGE_NAME.CURRENT_USER_TOKEN) && localStorage.getItem(STORAGE_NAME.CURRENT_USER)) {
      this.loggedIn.next(true);
    } else {
      this.loggedIn.next(false);
    }
    return this.loggedIn;
  }

  groupMatch(allowedGroups): boolean {
    let isMatch = false;
    const currentUser = JSON.parse(localStorage.getItem(STORAGE_NAME.CURRENT_USER));
    const userGroups = currentUser.groups;
    allowedGroups.forEach(item => {
      if (userGroups.some(group => group.code === item)) {
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }

  // public isLogged(): boolean {
  //   const token = localStorage.get(STORAGE_NAME.CURRENT_USER_TOKEN);
  //   return Boolean(token);
  // }
  //
  // public getAuthorizationToken() {
  //   return localStorage.get(STORAGE_NAME.CURRENT_USER_TOKEN);
  // }
}
