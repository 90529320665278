<div class="modal-header">
  <h4 class="modal-title">Добавление платежа</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">x
  </button>
</div>
<div class="modal-body">

  <div class="create-form">
    <form [formGroup]="createPaymentForm">

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="order_number">Номер счета</label>
        <div class="col-8">
          <ng-select [items]="orderList$ | async"
                     class="form-control custom-select"
                     placeholder="Номер счета"
                     bindLabel="order_number"
                     bindValue="order_number"
                     formControlName="order_number"
                     autofocus="true"
                     (change)="setCurrency($event.currency)"
                     [clearable]="false"
                     [hideSelected]="true"
                     [loading]="selectLoading"
                     [typeahead]="orderInput$">
          </ng-select>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="number">Номер п/п</label>
        <div class="col-8">
          <input formControlName="number"
                 class="form-control"
                 placeholder="Введите номер п/п"
                 type="text"
                 id="number"/>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="amount">Сумма</label>
        <div class="col-8">
          <input formControlName="amount"
                 class="form-control"
                 placeholder="Введите сумму"
                 type="text"
                 id="amount"
                 appOnlyNumber/>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="payment_date">Дата платежа</label>
        <div class="col-8 input-group">
          <input formControlName="payment_date"
                 class="form-control"
                 placeholder="Введите дату платежа"
                 type="text"
                 id="payment_date"
                 ngbDatepicker
                 (dateSelect)="setExchangeRate()"
                 [footerTemplate]="footerTemplate"
                 #d="ngbDatepicker"/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar"
                    type="button"
                    (click)="d.toggle()">
              <i class="fe fe-calendar"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="order_currency">Валюта счета</label>
        <div class="col-8 input-group align-content-center">
          {{ createPaymentForm.controls.order_currency.value }}
        </div>
      </div>

      <div *ngIf="createPaymentForm.controls.order_currency.value !== 'RUB'" class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="exchange_rate">Курс валюты</label>
        <div class="col-8 input-group">
          <input formControlName="exchange_rate"
                 class="form-control"
                 placeholder="Введите текущий курс"
                 type="text"
                 id="exchange_rate"
                 appOnlyNumber>
        </div>
      </div>

      <div class="row justify-content-end mr-0">
        <button type="button"
                class="btn btn-outline-danger mr-2"
                [disabled]="isLoading"
                (click)="onCancel()">Отменить
        </button>
        <button type="submit"
                class="btn btn-outline-success"
                [disabled]="isLoading || !createPaymentForm.valid"
                (click)="createPayment()">Добавить
                <img *ngIf="isLoading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpK
            SkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2M
            IOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlF
            UYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmK
            twHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0
            R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6Twf
            wyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSl
            KAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKA
            AAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
        </button>
      </div>
    </form>

    <ng-template #footerTemplate>
      <hr class="my-0">
      <button class="btn btn-primary btn-sm m-2 float-left" (click)="createPaymentForm.controls.payment_date.setValue(today); d.close()">Сегодня</button>
      <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d.close()">Закрыть</button>
    </ng-template>

  </div>
</div>

