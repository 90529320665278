<div class="create-form">
  <form [formGroup]="createClientForm"
        [class.form-validated]="isCreateClientFormValidate">

    <div class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="legal_name">Юридическое наименование</label>
      <div class="col-8">
        <input formControlName="legal_name"
               class="form-control"
               placeholder="Введите юридическое наименование"
               type="text"
               id="legal_name"/>
      </div>
    </div>

    <div class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="contact_name">Контактное лицо</label>
      <div class="col-8">
        <input formControlName="contact_name"
               class="form-control"
               placeholder="Введите контактное лицо"
               type="text"
               id="contact_name"/>
      </div>
    </div>

    <div class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="legal_address">Юридический адрес плательщика</label>
      <div class="col-8">
        <input formControlName="legal_address"
               class="form-control"
               placeholder="Введите юридический адрес плательщика"
               type="text"
               id="legal_address"/>
      </div>
    </div>

    <div class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="consignee_legal_address">Юридический адрес грузополучателя</label>
      <div class="col-8">
        <input formControlName="consignee_legal_address"
               class="form-control"
               placeholder="Введите юридический адрес грузополучателя"
               type="text"
               id="consignee_legal_address"/>
      </div>
    </div>

    <div class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="fact_address">Фактический адрес</label>
      <div class="col-8">
        <input formControlName="fact_address"
               class="form-control"
               placeholder="Введите фактический адрес"
               type="text"
               id="fact_address"/>
      </div>
    </div>

    <div class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="phone_number">Телефонный номер</label>
      <div class="col-8">
        <input formControlName="phone_number"
               class="form-control"
               placeholder="Введите телефонный номер"
               type="text"
               id="phone_number"/>
      </div>
    </div>

<!--    <div class="form-group row mb-2">-->
<!--      <label class="col-4 col-form-label"-->
<!--             for="city">Город</label>-->
<!--      <div class="col-8">-->
<!--        <input formControlName="city"-->
<!--               class="form-control"-->
<!--               placeholder="Введите город"-->
<!--               type="text"-->
<!--               id="city"/>-->
<!--      </div>-->
<!--    </div>-->

    <div class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="city">Город</label>
      <div class="col-8">
        <ng-select [items]="cities$ | async"
                   class="form-control custom-select"
                   placeholder="Город"
                   [required]="true"
                   [addTag]="false"
                   [hideSelected]="true"
                   [typeahead]="addressQuery$"
                   [loading]="isCreateLoading"
                   formControlName="city"
                   bindLabel="data.city"
                   bindValue="data.city"
                   id="city"
                   required>
        </ng-select>
      </div>
    </div>

    <div class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="INN">ИНН</label>
      <div class="col-8">
        <input formControlName="INN"
               class="form-control"
               placeholder="Введите ИНН"
               type="text"
               id="INN"/>
      </div>
    </div>

    <div class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="KPP">КПП</label>
      <div class="col-8">
        <input formControlName="KPP"
               class="form-control"
               placeholder="Введите КПП"
               type="text"
               id="KPP"/>
      </div>
    </div>

    <div class="row justify-content-end mr-0">
      <button type="button"
              class="btn btn-outline-danger mr-2"
              [disabled]="isCreateLoading"
              (click)="cancelCreateClient()">Отменить
      </button>
      <button type="submit"
              class="btn btn-outline-success"
              [disabled]="isCreateLoading"
              (click)="createClient()">Создать
      </button>
    </div>
  </form>
</div>
