export enum UnitType {
  THING = 'THING',
  METER = 'METER',
}

export enum ProductOrderStatusEnum {
  OPEN = 'OPEN',
  IN_PROGRESS = 'IN_PROGRESS',
  CLOSED = 'CLOSED',
}
