<td>
  {{ product.name }}
</td>
<td><span class="text-muted">{{ product.category.name }}</span></td>
<td><span class="text-muted">{{ product.manufacturer.name }}</span></td>
<td>
  {{ product.quantity }} / {{ product.external_quantity }}
</td>
<td>
  {{ product.transit_quantity }}
</td>
<td>
  {{ product.reserved_quantity }}
</td>
<td>
  {{ product.ordered_quantity }}
</td>
<td>
  {{ getUnitTitle(product.unit) }}
</td>
<td>
  {{ product.created_at | date: 'dd.MM.yyyy'  }}
</td>
<td>
  <div ngbDropdown class="dropdown">
    <button ngbDropdownToggle class="btn btn-secondary btn-sm">...</button>

    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <button ngbDropdownItem (click)="addStockRecordModalOpen()" class="dropdown-item">Добавить количество</button>
      <button ngbDropdownItem (click)="toggleToEdit()" class="dropdown-item">Изменить</button>
      <button ngbDropdownItem [routerLink]="['/stock', 'product', product.id]" class="dropdown-item">Перейти в карточку</button>
    </div>
  </div>
</td>
