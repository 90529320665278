import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {AuthService} from '../../services/auth/auth.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DataProviderService} from '../../services/data/data-provider.service';
import {ConfigurationsService} from '../../services/configuration/configurations.service';
import {LogoConfiguration} from '../../services/configuration/configurations';
import {eventToPromise} from '../../shared/utils';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';
  public logoUrl: string;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private configurationsService: ConfigurationsService,
    private dataProvider: DataProviderService,
  ) {
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this.initLayoutValues();
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  private async initLayoutValues(): Promise<void> {

    if (!this.configurationsService.get(LogoConfiguration)) {
      console.log(1);
      await eventToPromise(this.configurationsService.onPreferencesUpdate);
    }
    this.logoUrl = this.configurationsService.getValue(LogoConfiguration);
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authService.login(this.f.username.value, this.f.password.value)
      .subscribe(
        data => {
          this.router.navigate([this.returnUrl]);
        },
        error => {
          this.error =  'ОШИБКА: неверный логин или пароль';
          this.loading = false;
        });
  }

  getCurrentUser(params?): void {
    this.dataProvider.getCurrentUser(params)
      .subscribe(
        user => {
          this.authService.currentUser = user;
        }
      );
  }

}
