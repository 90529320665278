<div class="col-12">
  <div class="card">
    <div class="card-header justify-content-between">
      <h3 class="card-title">Список производителей</h3>
      <div class="card-options">
        <button (click)="openCreateModal()" type="button" class="btn btn-outline-info mr-4">
          <i class="fe fe-plus-square"></i> Добавить производителя
        </button>
      </div>

    </div>
    <div class="table-responsive">
      <table class="table card-table table-vcenter text-nowrap">
        <thead>
        <tr>
          <th>Название</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="isLoading">
          <td colspan="5">
            <app-loading-message>
              Получение списка производителей
            </app-loading-message>
          </td>
        </tr>
        <ng-container *ngIf="!isLoading">
          <ng-container *ngFor="let item of manufacturersList; let i = index">
            <tr>
              <td>
                {{item.name}}
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="!manufacturersList.length">
            <td class="text-center">
              Список пуст
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

<ngb-pagination class="d-flex justify-content-center"
                [(page)]="paginationPage"
                [pageSize]="pageSize"
                [collectionSize]="collectionSize"
                (pageChange)="updatePage($event)"
                [maxSize]="5">
</ngb-pagination>
