import {
  AfterViewInit,
  Component, OnDestroy,
} from '@angular/core';
import { DataProviderService } from '../../../services/data/data-provider.service';
import {
  ActivatedRoute,
  NavigationEnd,
  Router
} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ManufacturerCreateModalComponent } from '../manufacturer-create-modal/manufacturer-create-modal.component';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-manufacturers-list',
  templateUrl: './manufacturers-list.component.html',
  styleUrls: ['./manufacturers-list.component.scss']
})
export class ManufacturersListComponent implements AfterViewInit, OnDestroy {
  public isLoading = false;
  public manufacturersList: any [] = [];
  public pageSize = 50;
  public collectionSize = undefined;
  private _paginationPage = undefined;
  private _subscription: Subscription;

  public get paginationPage(): number {
    return this._paginationPage;
  }

  public set paginationPage(page: number) {
    this._paginationPage = page;
  }

  constructor(
    private dataProvider: DataProviderService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
  ) {
    this._subscription = new Subscription();
  }

  public updatePage(page: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page: page
      },
      replaceUrl: true,
    });
  }

  public async ngAfterViewInit(): Promise<void> {
    this.paginationPage = Number(this.route.queryParams['value'].page) || 1;
    await this.getList();
    this.subscribe();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  public openCreateModal(): void {
    const modalRef = this.modalService.open(ManufacturerCreateModalComponent);
    modalRef.result.finally(async () => {
      await this.getList();
    });
  }

  private subscribe() {
    this._subscription.add(
      this.router.events.subscribe(e => {
        if (e instanceof NavigationEnd) {
          this.getList();
        }
      }));
  }

  private async getList() {
    this.isLoading = true;
    const resp = await this.dataProvider.getManufacturerList({
      page: this.paginationPage
    });
    this.manufacturersList = resp.results;
    this.collectionSize = resp.count;
    this.isLoading = false;
  }

}
