import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgbDropdownConfig, NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {ModalAddStockRecordComponent} from './modal-add-stock-record/modal-add-stock-record.component';

import {Product, ProductList} from '../../../../models/stock';
import {Unit} from '../../../../models/types';
import {ProductService} from '../product.service';

@Component({
  selector: '[app-table-display]',
  templateUrl: './table-display.component.html',
  styleUrls: ['./table-display.component.scss']
})
export class TableDisplayComponent implements OnInit {
  @Input() product: ProductList;
  @Output() toggleEdit = new EventEmitter<any>();
  public unitTypes: Unit[];

  constructor(
    config: NgbDropdownConfig,
    private modalService: NgbModal,
    private productService: ProductService,
    ) {
    config.placement = 'left-bottom';
  }

  ngOnInit() {
    this.getUnitTypes();
  }

  toggleToEdit() {
    this.toggleEdit.emit();
  }

  addStockRecordModalOpen() {
    const modalRef = this.modalService.open(ModalAddStockRecordComponent);
    modalRef.componentInstance.product = this.product;
    modalRef.result.then(result => {
    }, () => {
      console.log('Backdrop click');
    });
  }

  public getUnitTitle(unitCode) {
    const foundStatus = this.unitTypes.find(
      unit => unit.code && unit.code.toUpperCase() === unitCode.toUpperCase(),
    );
    return foundStatus ? foundStatus.name : '';
  }

  private getUnitTypes() {
    this.productService.getUnitTypes().subscribe(
      (result) => {
        this.unitTypes = result;
      }
    );
  }


}

