import {
  EventEmitter,
  Injectable,
  Type,
} from '@angular/core';
import {
  Configuration,
  ConfigurationBase,
  configurationsConstructors,
} from './configurations';
import {DataProviderService} from '../data/data-provider.service';

type ConfigurationsMapType<A, T extends ConfigurationBase<A> = any> = Map<Type<T>, T>;
type GetConfigurationValueType<T> = T extends ConfigurationBase<infer A> ? A : never;

@Injectable({
  providedIn: 'root',
})
export class ConfigurationsService {

  private static WAIT_FOR_CONFIG_TIME_MILLISECONDS = 10000;

  public onPreferencesUpdate = new EventEmitter<void>();

  private preferences: ConfigurationsMapType<any> = new Map();

  constructor(
    private dataProvider: DataProviderService,
  ) {
  }

  public get<A, T extends ConfigurationBase<A>>(c: Type<T>): T | undefined {
    return this.preferences.get(c);
  }

  public getValue<T extends ConfigurationBase<unknown>>(
    c: Type<T>, defaultValue?: GetConfigurationValueType<T>,
  ): GetConfigurationValueType<T> | undefined {
    return this.preferences.has(c) ?
      this.preferences.get(c).value :
      defaultValue;
  }

  /**
   * @method getAsync
   * Возвращает конфиг асинхронно если он уже получен
   * Если конфиг не получен, то ждёт когда конфиг будет получен и затем возвращает его
   * Если конфиг не получен в течение 10сек, то возвращает undefined
   */
  public getAsync<A, T extends ConfigurationBase<A>>(c: Type<T>): Promise<T | undefined> {
    return new Promise(resolve => {
      if (this.preferences.has(c)) {
        resolve(this.preferences.get(c));
        return;
      }
      let timeout: number;
      const subscription = this.onPreferencesUpdate.subscribe(() => {
        if (!this.preferences.has(c)) {
          return;
        }
        resolve(this.preferences.get(c));
        subscription.unsubscribe();
        clearTimeout(timeout);
      });
      timeout = setTimeout(() => {
        subscription.unsubscribe();
        resolve(undefined);
      }, ConfigurationsService.WAIT_FOR_CONFIG_TIME_MILLISECONDS) as any;
    });
  }

  /**
   * @method getAsync
   * Возвращает значение конфига асинхронно если он уже получен
   * Если конфиг не получен, то ждёт когда конфиг будет получен и затем возвращает его значение
   * Если конфиг не получен в течение 10сек, то возвращает undefined или значение по умолчанию если оно было передано
   */
  public getValueAsync<T extends ConfigurationBase<unknown>>(
    c: Type<T>,
    defaultValue?: GetConfigurationValueType<T>,
  ): Promise<GetConfigurationValueType<T> | undefined> {
    return new Promise(resolve => {
      if (this.preferences.has(c)) {
        resolve(this.preferences.get(c).value);
        return;
      }
      let timeout: number;
      const subscription = this.onPreferencesUpdate.subscribe(() => {
        if (!this.preferences.has(c)) {
          return;
        }
        resolve(this.preferences.get(c).value || defaultValue);
        resolve(this.preferences.get(c).value || defaultValue);
        subscription.unsubscribe();
        clearTimeout(timeout);
      });
      timeout = setTimeout(() => {
        subscription.unsubscribe();
        resolve(defaultValue);
      }, ConfigurationsService.WAIT_FOR_CONFIG_TIME_MILLISECONDS) as any;
    });
  }

  public async updateConfigurations(): Promise<void> {
    this.resolveConfigurations(
      await this.dataProvider.getGeneralPreferences().toPromise(),
    );
  }

  private resolveConfigurations(configurations: Configuration[]): void {
    configurations.forEach(preference => {
      const constructor = configurationsConstructors.get(preference.title);
      if (!constructor) {
        return;
      }
      try {
        const configValue = constructor.create(preference);
        if (configValue) {
          this.preferences.set(constructor, configValue);
        }
      } catch (e) {
        console.error(e);
      }
    });
    this.onPreferencesUpdate.emit();
  }
}
