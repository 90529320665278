<div class="page-header">

  <div class="col-12">
    <div class="card">
      <div class="card-header justify-content-between">
        <h3 class="card-title">Адреса на складе</h3>
        <div class="card-options">
<!--          <button (click)="createModalOpen()" type="button" class="btn btn-outline-info mr-4">-->
<!--            <i class="fe fe-plus-square"></i> Добавить адрес-->
<!--          </button>-->
        </div>

      </div>
      <div class="table-responsive">
        <table class="table card-table table-vcenter text-nowrap table-hover">
          <thead>
          <tr>
            <th>Название</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="loading">
            <td colspan="5">
              <app-loading-message>
                Получение адресов
              </app-loading-message>
            </td>
          </tr>
          <ng-container *ngIf="!loading">
            <ng-container *ngFor="let address of stockRecordAddresses; let i = index">
              <tr (click)="navigateStockAddressDetail(address.id)">
                <td>
                  {{address.name}}
                </td>
              </tr>
            </ng-container>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>

  </div>

</div>
