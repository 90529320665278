import { Injectable } from '@angular/core';
import {DataProviderService} from '../../services/data/data-provider.service';


@Injectable({
  providedIn: 'root'
})
export class IssuesService {

  constructor(
    private dataProvider: DataProviderService,
  ) { }

  public getIssueStates() {
    return this.dataProvider.getIssueStateList();
  }

}
