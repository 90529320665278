<div class="page">
  <div class="page-main">
    <app-nav *ngIf="isLoggedIn | async"></app-nav>

    <div class="my-3 my-md-5">
      <div class="container">
        <router-outlet></router-outlet>
      </div>
    </div>

  </div>
</div>

<app-notify-message type="success"></app-notify-message>
