<div class="create-form">
  <form [formGroup]="createProductForm"
        [class.form-validated]="isCreateProductFormValidate">

    <div *ngIf="isFieldVisible(productFormFields.NAME)"
         class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="name">Наименование</label>
      <div class="col-8">
        <input formControlName="name"
               class="form-control"
               placeholder="Введите наименование"
               type="text"
               id="name"/>
      </div>
    </div>

    <div *ngIf="isFieldVisible(productFormFields.CATEGORY)"
         class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="category">Категория</label>
      <div class="col-8">
        <ng-select [items]="categoryList"
                   class="form-control custom-select"
                   placeholder="Категория"
                   [required]="true"
                   [addTag]="false"
                   [hideSelected]="true"
                   formControlName="category"
                   bindLabel="name"
                   bindValue="id"
                   name="category"
                   id="category"
                   (change)="onCategoryChoice($event)"
                   required>
        </ng-select>
      </div>
    </div>

    <div *ngIf="isFieldVisible(productFormFields.MANUFACTURER)"
         class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="manufacturer">Производитель</label>
      <div class="col-8">
        <ng-select [items]="manufacturersList"
                   class="form-control custom-select"
                   placeholder="Производитель"
                   [required]="true"
                   [addTag]="false"
                   [hideSelected]="true"
                   formControlName="manufacturer"
                   bindLabel="name"
                   bindValue="id"
                   name="manufacturer"
                   id="manufacturer"
                   required>
        </ng-select>
      </div>
    </div>

    <div *ngIf="isFieldVisible(productFormFields.TNVED)"
         class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="tnved">ТН ВЭД</label>
      <div class="col-8">
        <input formControlName="tnved"
               class="form-control"
               placeholder="Введите ТН ВЭД"
               type="number"
               id="tnved"/>
      </div>
    </div>

    <div *ngIf="isFieldVisible(productFormFields.BARCODE)"
         class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="barcode">Штрихкод</label>
      <div class="col-8">
        <input formControlName="barcode"
               class="form-control"
               placeholder="Введите штрихкод"
               type="text"
               id="barcode"/>
      </div>
    </div>

    <div *ngIf="isFieldVisible(productFormFields.UNIT)"
         class="form-group row mb-2">
      <label class="col-4 col-form-label"
             for="unit">Единица измерения</label>
      <div class="col-8">
        <ng-select [items]="unitTypes$ | async"
                   class="form-control custom-select"
                   [required]="true"
                   [searchable]="true"
                   [clearable]="false"
                   formControlName="unit"
                   bindLabel="name"
                   bindValue="code"
                   name="unit"
                   id="unit"
                   required>
        </ng-select>
      </div>
    </div>

    <div *ngIf="createProductForm.controls.name.errors?.errorMessage" class="cross-validation-error-message alert alert-danger">
      {{ createProductForm.controls.name.errors.errorMessage }}
    </div>
    <div class="row justify-content-end mr-0">
      <button type="button"
              class="btn btn-outline-danger mr-2"
              [disabled]="isCreateLoading"
              (click)="cancelCreateProduct()">Отменить
      </button>
      <button type="submit"
              class="btn btn-outline-success"
              [disabled]="isCreateLoading || !createProductForm.valid"
              (click)="createProduct()">Создать
      </button>
    </div>
  </form>
</div>
