import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {DataProviderService} from '../../../services/data/data-provider.service';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {debounceTime, distinctUntilChanged, first, map, switchMap} from 'rxjs/operators';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {FiltersService} from '../../../shared/filters/services/filters.service';
import {OrderLine} from '../../../models/sale';
import {Filters} from '../../../lib/constants';
import {ProductOrderState} from '../../../models/types';

@Component({
  selector: 'app-shipment-list',
  templateUrl: './shipment-list.component.html',
  styleUrls: ['./shipment-list.component.scss']
})
export class ShipmentListComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public orderLines: OrderLine[] = [];
  public pageSize = 50;
  public collectionSize = undefined;
  private _subscription: Subscription;

  public pagination: any = {
    count: 10,
    per_page: 50,
    current_page: 1
  };

  constructor(
    private dataProvider: DataProviderService,
    private filtersService: FiltersService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private modalConfig: NgbModalConfig,
  ) {
    this._subscription = new Subscription();
    modalConfig.backdrop = 'static';
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private subscribe() {
    this._subscription.add(
      this.filtersService.filterParams.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(
          params => this.getList(params)
        ),
      ).subscribe(),
    );
  }

  get paginationPage() {
    return this.pagination.current_page;
  }

  set paginationPage(page: number | string) {
    if (Number(page) === Number(this.paginationPage) || !page) {
      return;
    }
    this.pagination.current_page = Number(page);
    this.filtersService.updateQueryParameter(Filters.PAGE, String(page));
  }

  private getList(params?): Observable<any> {
    this.isLoading = true;
    return this.dataProvider.getRequiredToShipmentOrderLines(params)
      .pipe(
        first(),
        map(resp => {
          this.orderLines = resp.results;
          this.pagination.count = resp.count;
          this.isLoading = false;
        }));
  }

  public navigateOrderDetail(id): void {
    this.router.navigate(['/sale', 'order', id]);
  }

}
