import {Component, Input, OnInit} from '@angular/core';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, finalize, first, map, switchMap, tap} from 'rxjs/operators';
import {DataProviderService} from '../../../../services/data/data-provider.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Order, OrderLine} from '../../../../models/sale';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../shared/notify-message/notify-message.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {StockRecord} from '../../../../models/stock';

@Component({
  selector: 'app-modal-shipping',
  templateUrl: './modal-shipment.component.html',
  styleUrls: ['./modal-shipment.component.scss']
})
export class ModalShipmentComponent implements OnInit {
  @Input() orderLine: OrderLine;
  @Input() order: Order;

  stockRecord: StockRecord[];
  stockInput$ = new Subject<string>();
  shipmentForm: FormGroup;
  submitted = false;
  stockLoading = false;
  loading = false;
  error = '';

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,

    private authService: AuthService,
    private notifierService: NotifyMessageService,
    private dataProvider: DataProviderService,
    ) { }

  ngOnInit() {
    this.getStockRecordList();
    this.shipmentForm = this.formBuilder.group({
      line: [this.orderLine.id, Validators.required],
      quantity: [null, Validators.required],
      stock_record_id: [null, Validators.required],
    });
  }

  get f() {
    return this.shipmentForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.shipmentForm.invalid) {
      console.log(this.shipmentForm.value);
      return;
    }
    this.setShipped(this.shipmentForm.value);
  }

  // private getProductStockList() {
  //   this.stockRecords$ = concat(
  //     of([]), // default items
  //     this.stockInput$.pipe(
  //       debounceTime(200),
  //       distinctUntilChanged(),
  //       tap(() => this.stockLoading = true),
  //       switchMap(term => this.dataProvider.getProductStockList({'product': this.orderLine.product.id}).pipe(
  //         map(res => res.results.map(
  //           (stockRecords: any) => {
  //             stockRecords.bind_label = `${stockRecords.address.name} (${stockRecords.quantity || 0 })`;
  //             return stockRecords;
  //           }
  //         )),
  //         catchError(() => of([])), // empty list on error
  //         tap(() => this.stockLoading = false)
  //       ))
  //     )
  //   );
  // }

  private getStockRecordList() {
    this.stockLoading = true;
    const params = {
      'order_line_id': this.orderLine.id,
    };
    this.dataProvider.getAvailableToShipmentStockRecordList(params)
      .pipe(
        first(),
        map(resp => resp.map(
          (item: any) => {
            item.bind_label = `${item.address.name} (${item.quantity || 0}) (${item.country.code})`;
            return item;
          }
        )))
      .subscribe(
        response => {
          this.stockRecord = response;
          this.stockLoading = false;
        }
      );
  }

  setShipped(data): void {
    this.loading = true;

    this.dataProvider.setShipped(this.order.id, data).pipe(
      finalize(() => this.loading = false),
    )
      .subscribe(
        res => {
          this.orderLine.shipped_quantity = res.shipped_quantity;
          this.orderLine.product.quantity = res.product.quantity;
          this.activeModal.close();
        },
        err => {
          this.error = 'Ошибка отгрузки'; console.log(err);
          this.notifierService.notify('Ошибка отгрузки', 'danger');
        },
      );
  }


}
