import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {finalize} from 'rxjs/operators';

import {DataProviderService} from '../../../../../services/data/data-provider.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {Product} from '../../../../../models/stock';

@Component({
  selector: 'app-modal-add-stock-record',
  templateUrl: './modal-add-stock-record.component.html',
  styleUrls: ['./modal-add-stock-record.component.scss']
})
export class ModalAddStockRecordComponent implements OnInit {
  @Input() product: Product;
  addStockRecordForm: FormGroup;
  submitted = false;
  stockRecordAddresses: any[];
  countryList: any[];
  loading = false;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    private dataProvider: DataProviderService,
  ) {
  }

  get f() {
    return this.addStockRecordForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.addStockRecordForm.invalid) {
      console.log(this.f);
      return;
    }
    this.createStockRecord(this.addStockRecordForm.value);
  }

  private async getStockAddress() {
    await this.dataProvider.getStockAddress()
        .subscribe(
            response => {
              this.stockRecordAddresses = response;
            }
        );
  }

  private async getCountryList() {
    await this.dataProvider.getCountryList()
        .subscribe(
            response => {
              this.countryList = response;
            }
        );
  }

  createStockRecord(data): void {
    this.loading = true;

    this.dataProvider.createStockRecord(this.product.id, data).pipe(
      finalize(() => this.loading = false),
    )
      .subscribe(
        res => {
          this.product.quantity += Number(data.quantity);
          this.activeModal.close();
        },
        err => {
          this.error = 'Ошибка создания';
          console.log(err);
        },
      );
  }

  async ngOnInit() {
    this.getStockAddress();
    this.getCountryList();
    this.addStockRecordForm = this.formBuilder.group({
      address: [null, Validators.required],
      quantity: [null, Validators.required],
      country: [null, Validators.required],
      product: [this.product.id, Validators.required],
    });
  }

}
