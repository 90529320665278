import {
  Injectable,
  Injector
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../../lib/api.service';
import {
  Currency, PaymentTypes, ProductOrderState,
  Unit
} from '../../models/types';
import {
  CurrencyType, IssueState, PaymentType, ProductOrderStatusEnum,
  UnitType
} from '../../models/enums';
import {
  from,
  Observable,
  of
} from 'rxjs';
import { PaginatorPage } from './paginator-page';
import {IssueStates} from '../../models/types/issue.interface';
import {Configuration} from '../configuration/configurations';

@Injectable({
  providedIn: 'root'
})
export class DataProviderService extends ApiService {

  constructor(
    injector: Injector,
  ) {
    super(injector.get(HttpClient));
  }

  obtainToken(params: any) {
    return this.post([
      'v1',
      'users',
      'obtain-token'
    ], params);
  }

  getCurrentUser(params?) {
    return this.get([
      'v1',
      'users',
      'current-user'
    ], params);
  }

  getUsers(params?) {
    return this.get([
      'v1',
      'users',
    ], params);
  }

  getCategoryList(params?) {
    return this.get([
      'v1',
      'stock',
      'category'
    ], params);
  }

  getManufacturerList(params?, page?): Promise<PaginatorPage<any>> {
    return this.get([
      'v1',
      'stock',
      'manufacturer'
    ], {page, ...params}).toPromise();
  }

  getManufacturerListAll(params?): Promise<any[]> {
    return PaginatorPage.resolveAllPages(page => this.getManufacturerList(params, page));
  }

  createManufacturer(params): Observable<any> {
    return this.post([
      'v1',
      'stock',
      'manufacturer'
    ], params);
  }

  getIssuesList(params?): Observable<any> {
    return this.get([
      'v1',
      'issues',
    ], params);
  }

  createIssue(params?): Observable<any> {
    return this.post([
      'v1',
      'issues',
      'create',
    ], params);
  }

  updateIssue(id, params?): Observable<any> {
    return this.patch([
      'v1',
      'issues',
      id,
      'update',
    ], params);
  }

  deleteIssue(id, params?): Observable<any> {
    return this.post([
      'v1',
      'issues',
      id,
      'delete',
    ], params);
  }

  getProductList(params?) {
    return this.get([
      'v1',
      'stock',
      'products'
    ], params);
  }

  getProductDetail(id, params?) {
    return this.get([
      'v1',
      'stock',
      'products',
      id
    ], params);
  }

  getStockRecordList(id, params?) {
    return this.get([
      'v1',
      'stock',
      'products',
      id,
      'stock-records',
    ], params);
  }

  getAvailableToShipmentStockRecordList(params) {
    return this.get([
      'v1',
      'stock',
      'available-to-shipment',
    ], params);
  }

  changeStockRecord(id, params) {
    return this.patch([
      'v1',
      'stock',
      'products',
      id,
      'stock-records',
      'update',
    ], params);
  }

  moveStockRecord(id, params) {
    return this.post([
      'v1',
      'stock',
      'products',
      id,
      'stock-records',
      'move',
    ], params);
  }

  createStockRecord(id, params) {
    return this.post([
      'v1',
      'stock',
      'products',
      id,
      'stock-records'
    ], params);
  }

  getProductOrders(id, params?) {
    return this.get([
      'v1',
      'stock',
      'products',
      id,
      'product-orders'
    ], params);
  }

  createProduct(params) {
    return this.post([
      'v1',
      'stock',
      'products'
    ], params);
  }

  changeProduct(id, params) {
    return this.patch([
      'v1',
      'stock',
      'products',
      id
    ], params);
  }

  createTnved(params) {
    return this.post([
      'v1',
      'stock',
      'tnved'
    ], params);
  }

  getStockAddress(params?) {
    return this.get([
      'v1',
      'stock',
      'address'
    ], params);
  }

  getStockAddressDetail(id, params?) {
    return this.get([
      'v1',
      'stock',
      'address',
      id
    ], params);
  }

  getStockShipments(params?) {
    return this.get([
      'v1',
      'stock',
      'shipments'
    ], params);
  }

  getStockShipmentsDetail(id, params?) {
    return this.get([
      'v1',
      'stock',
      'shipments',
      id
    ], params);
  }

  getCountryList(params?) {
    return this.get([
      'v1',
      'dicts',
      'countries'
    ], params);
  }

  getDeliveryTimeList(params?) {
    return this.get([
      'v1',
      'dicts',
      'delivery-time'
    ], params);
  }

  getClients(params?) {
    return this.get([
      'v1',
      'sale',
      'clients'
    ], params);
  }

  getCompanies(params?) {
    return this.get([
      'v1',
      'companies/',
    ], params);
  }

  createClient(params) {
    return this.post([
      'v1',
      'sale',
      'clients'
    ], params);
  }

  getClientDetail(id, params?) {
    return this.get([
      'v1',
      'sale',
      'clients',
      id,
    ], params);
  }

  getOrderPayments(id, params?) {
    return this.get([
      'v1',
      'sale',
      'order',
      id,
      'payments'
    ], params);
  }

  recalculate(id, params): Observable<any> {
    return this.post([
      'v1',
      'sale',
      'order',
      id,
      'recalculate',
    ], params);
  }

  getPayments(params?) {
    return this.get([
      'v1',
      'sale',
      'payments'
    ], params);
  }

  getRequiredToOrderProducts(params?) {
    return this.get([
      'v1',
      'stock',
      'order-required-products'
    ], params);
  }

  getOverdueOrderProducts(params?) {
    return this.get([
      'v1',
      'stock',
      'order-overdue-products'
    ], params);
  }

  createProductOrder(params) {
    return this.post([
      'v1',
      'stock',
      'product-orders'
    ], params);
  }

  createReservedStockRecord(id, params) {
    return this.post([
      'v1',
      'stock',
      'stock-records',
      id,
      'reserve',
    ], params);
  }

  updateProductOrder(id, params) {
    return this.patch([
      'v1',
      'stock',
      'product-orders',
      id,
    ], params);
  }

  deleteProductOrder(id) {
    return this.delete([
      'v1',
      'stock',
      'product-orders',
      id,
    ]);
  }

  listProductOrders(params) {
    return this.get([
      'v1',
      'stock',
      'product-orders'
    ], params);
  }

  createPayment(id, params) {
    return this.post([
      'v1',
      'sale',
      'order',
      id,
      'payments'
    ], params);
  }

  deletePayment(id, params) {
    return this.post([
      'v1',
      'sale',
      'order',
      id,
      'payments',
      'delete'
    ], params);
  }

  createPaymentByOrderNumber(params) {
    return this.post([
      'v1',
      'sale',
      'payments'
    ], params);
  }

  createPaymentsByOrderNumber(params) {
    return this.post([
      'v1',
      'sale',
      'payments',
      'bulk_create',
    ], params);
  }

  checkUploadPayments(params) {
    return this.post([
      'v1',
      'sale',
      'payments',
      'upload'
    ], params, 'FORM_DATA');
  }

  checkPayments(params) {
    return this.post([
      'v1',
      'sale',
      'payments',
      'check'
    ], params);
  }

  getGeneralPreferences(params?): Observable<Configuration[]> {
    return this.get([
      'v1',
      'general_preferences/',
    ], params);
  }

  getOrderList(params?) {
    return this.get([
      'v1',
      'sale',
      'order'
    ], params);
  }

  getOrder(id, params?) {
    return this.get([
      'v1',
      'sale',
      'order',
      id
    ], params);
  }

  changeOrder(id, params) {
    return this.patch([
      'v1',
      'sale',
      'order',
      id
    ], params);
  }

  copyOrder(id, params?) {
    return this.post([
      'v1',
      'sale',
      'order',
      id,
      'copy',
    ], params);
  }

  createOrder(params) {
    return this.post([
      'v1',
      'sale',
      'order'
    ], params);
  }

  changeOrderStatus(id, params?) {
    return this.post([
      'v1',
      'sale',
      'order',
      id,
      'change-status'
    ], params);
  }

  getOrderStatuses(params?) {
    return this.get([
      'v1',
      'sale',
      'order',
      'status-list',
    ], params);
  }

  changeOrderLine(id, params) {
    return this.patch([
      'v1',
      'sale',
      'order-line',
      id
    ], params);
  }

  orderLineRemoveReservation(id, params?) {
    return this.post([
      'v1',
      'sale',
      'order-line',
      id,
      'remove-reservation'
    ], params);
  }

  getRequiredToShipmentOrderLines(params?) {
    return this.get([
      'v1',
      'stock',
      'to-shipment',
    ], params);
  }

  selectShipment(id, params) {
    return this.post([
      'v1',
      'sale',
      'order',
      id,
      'select-shipment'
    ], params);
  }

  setShipped(id, params) {
    return this.post([
      'v1',
      'sale',
      'order',
      id,
      'set-shipped'
    ], params);
  }

  getShipperList(params?) {
    return this.get([
      'v1',
      'stock',
      'shippers'
    ], params);
  }

  /* Special 3rd side */

  getDadataSuggestions(params) {
    return this.post([
      'v1',
      'common',
      'dadata',
      'address/',
    ], params);
  }

  /* // Special 3rd side */

  exportOrder(id, params) {
    return this.get([
        'v1',
        'sale',
        'order',
        id,
        'export'
      ], params,
      Object.assign({
        observe: 'response',
        responseType: 'blob',
      }, this.buildOptions()));
  }

  exportOrdersTo1C(params) {
    return this.get([
        'v1',
        'sale',
        'orders',
        'export-to-1c'
      ], params,
      Object.assign({
        observe: 'response',
        responseType: 'blob',
      }, this.buildOptions()));
  }

  exportShipmentLetter(params) {
    return this.post([
      'v1',
      'stock',
      'shipments',
      'generate-letter'
    ], params, undefined,
      Object.assign({
        observe: 'response',
        responseType: 'blob',
      }, this.buildOptions()));
  }

  getUnitTypes(): Observable<Unit[]> {
    return of([
      {
        name: 'шт.',
        code: UnitType.THING
      },
      {
        name: 'м',
        code: UnitType.METER
      }
    ]);
  }

  getProductOrderStates(): Observable<ProductOrderState[]> {
    return of([
      {
        title: 'Открыт',
        code: ProductOrderStatusEnum.OPEN
      },
      {
        title: 'В работе',
        code: ProductOrderStatusEnum.IN_PROGRESS
      },
      {
        title: 'Закрыт',
        code: ProductOrderStatusEnum.CLOSED
      }
    ]);
  }

  getCurrencyTypes(): Observable<Currency[]> {
    return of([
      {
        title: 'Рубли',
        code: CurrencyType.RUB
      },
      {
        title: 'Доллары',
        code: CurrencyType.USD
      },
      {
        title: 'Евро',
        code: CurrencyType.EUR
      }
    ]);
  }

  getPaymentTypes(): Observable<PaymentTypes[]> {
    return of([
      {
        title: 'Обычный',
        code: PaymentType.DEFAULT
      },
      {
        title: 'Сторнирующий',
        code: PaymentType.REVERSING
      },
    ]);
  }

  getExchangeRates(params?) {
    return this.get([
      'v1',
      'sale',
      'payments',
      'exchange_rates'
    ], params);
  }

  getIssueStateList(): Observable<IssueStates[]> {
    return of([
      {
        title: 'Открыта',
        code: IssueState.OPEN
      },
      {
        title: 'Решена',
        code: IssueState.RESOLVED
      },
    ]);
  }

}
