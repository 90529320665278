import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DataProviderService} from '../../../../services/data/data-provider.service';
import {NotifyMessageService} from '../../../../shared/notify-message/notify-message.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';
import {ProductFormField} from '../../../stock/product/product-create/product-create.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

export enum ClientFormField {
  LEGAL_NAME = 'legal_name',
  CONTACT_NAME = 'contact_name',
  LEGAL_ADDRESS = 'legal_address',
  CONSIGNEE_LEGAL_ADDRESS = 'consignee_legal_address',
  FACT_ADDRESS = 'fact_address',
  PHONE = 'phone_number',
  CITY = 'city',
  INN = 'INN',
  KPP = 'KPP',
}

export type ClientFormConfig = { [key in ClientFormField]?: boolean };

@Component({
  selector: 'app-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.scss']
})
export class ClientCreateComponent implements OnInit {

  @Output() public OnClientCreate = new EventEmitter();
  @Output() public OnCancel = new EventEmitter();

  public cities$: Observable<any[]>;
  public addressQuery$ = new Subject<string>();

  public clientFormFields = ClientFormField;

  public isCreateLoading = false;
  public isCreateClientFormValidate = false;
  public createClientForm: FormGroup;
  public formConfig: ClientFormConfig = {
    [ClientFormField.LEGAL_NAME]: true,
    [ClientFormField.CONTACT_NAME]: true,
    [ClientFormField.LEGAL_ADDRESS]: true,
    [ClientFormField.CONSIGNEE_LEGAL_ADDRESS]: true,
    [ClientFormField.FACT_ADDRESS]: true,
    [ClientFormField.PHONE]: true,
    [ClientFormField.CITY]: true,
    [ClientFormField.INN]: true,
    [ClientFormField.KPP]: false,
  };

  constructor(
    private dataProvider: DataProviderService,
    private notifierService: NotifyMessageService,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.initCreateClientForm();
    this.getCities();
  }

  get clientFromCtrls() {
    return this.createClientForm.controls;
  }

  public cancelCreateClient() {
    this.OnCancel.emit();
  }

  public createClient() {
    if (!this.createClientForm.valid) {
      this.isCreateClientFormValidate = true;
      return;
    }
    this.isCreateLoading = true;

    // const clientData = Object.assign({},
    //   this.createClientForm.value,
    //   {
    //     name: this.productNameValue,
    //   });
    const clientData = this.createClientForm.value;
    this.dataProvider.createClient(clientData).pipe()
      .subscribe(
        created_client => {
          this.dataProvider.getClientDetail(created_client.id).subscribe(
            client => {
              client.clientInfo = `${client.legal_name} (${client.city} ИНН: ${client.INN})`;
              this.notifierService.notify('Клиент успешно создан', 'success');
              this.isCreateLoading = false;
              this.OnClientCreate.emit(client);
            }
          );
        },
        err => {
          this.isCreateLoading = false;
          console.log(err);
          this.notifierService.notify('Ошибка создания клиента', 'danger');
        },
      );
  }

  public getCities() {
    this.cities$ = concat(
      of([]), // default items
      this.addressQuery$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.isCreateLoading = true),
        switchMap(term => this.dataProvider.getDadataSuggestions(
          {
            'search_query': term,
            'count': 10,
            from_bound: 'city',
            to_bound: 'city',
          }
        ).pipe(
          map(res => res.map(
            (address: any) => {
              return address;
            }
          )),
          catchError(() => of([])), // empty list on error
          tap(() => this.isCreateLoading = false)
        ))
      )
    );
  }

  private initCreateClientForm() {
    this.isCreateClientFormValidate = false;
    const controls = {};
    for (const productField of Object.keys(this.clientFormFields)) {
      const key = this.clientFormFields[productField];
      if (key in this.formConfig) {
        const control = [];
        const isRequired = this.formConfig[key];
        control.push(this.getFieldDefaultValue(key));
        if (isRequired) {
          control.push(Validators.required);
        }
        controls[key] = control;
      }
    }

    this.createClientForm = this.formBuilder.group(controls);
  }

  private getFieldDefaultValue(fieldId: keyof ClientFormField) {
    const defaults: { [key in ClientFormField]: any } = {
      [ClientFormField.LEGAL_NAME]: null,
      [ClientFormField.CONTACT_NAME]: null,
      [ClientFormField.LEGAL_ADDRESS]: null,
      [ClientFormField.CONSIGNEE_LEGAL_ADDRESS]: null,
      [ClientFormField.FACT_ADDRESS]: null,
      [ClientFormField.PHONE]: null,
      [ClientFormField.CITY]: null,
      [ClientFormField.INN]: null,
      [ClientFormField.KPP]: null,
    };
    return defaults[fieldId];
  }

}
