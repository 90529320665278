import { Injectable } from '@angular/core';
import {Constants} from '../../lib/constants';
import {DataProviderService} from '../data/data-provider.service';

export class CalculatedOrder {

  public orderSubTotal: number;
  public orderTotal: number;
  public vatTotal: number;

  constructor(orderSubTotal, orderTotal, vatTotal) {
    this.orderSubTotal = orderSubTotal;
    this.orderTotal = orderTotal;
    this.vatTotal = vatTotal;
  }
}

@Injectable({
  providedIn: 'root'
})
export class SaleService {

  vatValue = Constants.VAT_CONSTANT;

  constructor(
      private dataProvider: DataProviderService,
    ) { }

  calculate(orderProducts) {
    let orderSubTotal = 0;
    let orderTotal = 0;
    let vatTotal = 0;
    orderProducts.forEach(item => {
      orderSubTotal += item.cost;
      vatTotal += Number((item.price * item.quantity / 100 * this.vatValue).toFixed(2));
    });
    orderTotal = Number((orderSubTotal + vatTotal).toFixed(2));
    return new CalculatedOrder(orderSubTotal, orderTotal, vatTotal);
  }

  public getCurrencyTypes() {
    return this.dataProvider.getCurrencyTypes();
  }

  public getPaymentTypes() {
    return this.dataProvider.getPaymentTypes();
  }

}
