<div class="modal-header">
  <h4 class="modal-title">Добавление платежей</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">x
  </button>
</div>
<div class="modal-body">

  <div *ngIf="isLoading">
    <app-loading-message>
      Загрузка
    </app-loading-message>
  </div>

  <div *ngIf="!isChecked && !checkedPayments" class="create-form">
    <form [formGroup]="uploadPaymentsForm">

      <div class="form-group row mb-2">

        <label class="col-2 col-form-label"
               for="file">Загрузить файл</label>
        <div class="col-4">
          <input class="form-control-file"
                 type="file"
                 id="file"
                 (change)="handleFileInput($event)">
        </div>


        <div class="col-4">
          <ng-select [items]="paymentParserCodes"
                     class="form-control custom-select"
                     [searchable]="true"
                     placeholder="Код банка"
                     [clearable]="false"
                     bindLabel="title"
                     bindValue="code"
                     id="code"
                     formControlName="code"
                     required>
          </ng-select>
        </div>

      </div>


      <div class="row justify-content-end mr-0">
        <button type="button"
                class="btn btn-outline-danger mr-2"
                [disabled]="isLoading"
                (click)="onCancel()">Отменить
        </button>
        <button type="submit"
                class="btn btn-outline-success"
                [disabled]="isLoading || !uploadPaymentsForm.valid"
                (click)="uploadCheck()">Проверить
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="errorMessage" class="alert-danger">
    {{ errorMessage }}
  </div>

  <ng-container *ngIf="isChecked && checkedPayments && !errorMessage">
    <table class="table table-hover table-vcenter">
      <thead>
        <tr>
          <th>№</th>
          <th>Номер счета</th>
          <th>Номер п/п</th>
          <th>Сумма</th>
          <th>Дата</th>
          <th>Валюта счета</th>
          <th>Курс</th>
          <th>Статус</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payment of checkedPayments; let i = index"
            [ngClass]="{
                    'alert-success': payment.status == 'success',
                    'alert-danger': payment.status == 'fail'
                    }">
          <td>{{ i + 1 }}</td>
          <td style="width: 8rem">
            <ng-select [items]="orderList$ | async"
                       placeholder="Номер заказа"
                       bindLabel="order_number"
                       bindValue="order_number"
                       [clearable]="false"
                       (change)="setCurrency($event.currency, payment)"
                       [hideSelected]="true"
                       [loading]="selectLoading"
                       [typeahead]="orderInput$"
                       [(ngModel)]="payment.order_number">
            </ng-select>
          </td>
<!--          <td><input class="form-control" type="text" [(ngModel)]="payment.order_number"></td>-->
          <td><input class="form-control" type="text" [(ngModel)]="payment.number"></td>
          <td><input class="form-control" type="text" [(ngModel)]="payment.amount" appOnlyNumber></td>
          <td class="w-25">
            <div class="input-group">
              <input class="form-control"
                     type="text"
                     [(ngModel)]="payment.payment_date"
                     ngbDatepicker
                     (dateSelect)="setExchangeRate(payment)"
                     #d="ngbDatepicker">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar"
                        type="button"
                        (click)="d.toggle()">
                  <i class="fe fe-calendar"></i>
                </button>
              </div>
            </div>
          </td>
          <td>{{ payment.order_currency }}</td>
          <td><input class="form-control" type="text" [(ngModel)]="payment.exchange_rate" appOnlyNumber></td>
          <td>{{ payment.messages.join(', ') }}</td>
          <td>
            <i class="fa fa-minus-circle text-red" (click)="removePaymentFromList(i)"></i>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="row justify-content-end mr-0">
      <button type="submit"
              class="btn btn-primary mr-5"
              (click)="checkPayments()">Проверить
      </button>
      <button type="button"
              class="btn btn-outline-danger mr-2"
              [disabled]="isLoading"
              (click)="onCancel()">Отменить
      </button>
      <button type="submit"
              class="btn btn-success"
              [disabled]="!isReadyToCreatePayments()"
              (click)="createPayments()">Загрузить
      </button>
    </div>

  </ng-container>

  <ng-template #footerTemplate>
    <hr class="my-0">
    <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d.close()">Закрыть</button>
  </ng-template>


<!--    Оригинальная таблица без редактирования-->

<!--  </ng-container>-->
<!--  <ng-container *ngIf="isChecked && checkedPayments && !errorMessage">-->
<!--    <table class="table table-hover">-->
<!--      <thead>-->
<!--        <tr>-->
<!--          <th>№</th>-->
<!--          <th>Номер счета</th>-->
<!--          <th>Сумма</th>-->
<!--          <th>Дата</th>-->
<!--          <th>Валюта</th>-->
<!--          <th>Курс</th>-->
<!--          <th>Статус</th>-->
<!--        </tr>-->
<!--      </thead>-->
<!--      <tbody>-->
<!--        <tr *ngFor="let payment of checkedPayments; let i = index"-->
<!--            [ngClass]="{-->
<!--                    'alert-success': payment.status == 'success',-->
<!--                    'alert-danger': payment.status == 'fail'-->
<!--                    }">-->
<!--          <td>{{ i + 1 }}</td>-->
<!--          <td>{{ payment.order_number }}</td>-->
<!--          <td>{{ payment.amount }}</td>-->
<!--          <td>{{ payment.payment_date }}</td>-->
<!--          <td>{{ payment.currency }}</td>-->
<!--          <td>{{ payment.exchange_rate }}</td>-->
<!--          <td>{{ payment.messages.join(', ') }}</td>-->
<!--        </tr>-->
<!--      </tbody>-->
<!--    </table>-->

<!--  </ng-container>-->

</div>

