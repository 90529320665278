import {
  Component, Input,
  OnInit
} from '@angular/core';
import {NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { DataProviderService } from '../../../services/data/data-provider.service';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, map, share, switchMap, tap} from 'rxjs/operators';
import {concat, Observable, of, Subject} from 'rxjs';
import {Order, OrderLine} from '../../../models/sale';
import {DatePipe, formatDate} from '@angular/common';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, fromApiDate, I18n, toApiDate} from '../../../shared/datepicker';
import {IssueList, IssueStates} from '../../../models/types/issue.interface';
import {PaymentTypes} from '../../../models/types';
import {SaleService} from '../../../services/sale/sale.service';
import {IssuesService} from '../issues.service';

@Component({
  selector: 'app-issues-update-modal',
  templateUrl: './issues-update-modal.component.html',
  styleUrls: ['./issues-update-modal.component.scss'],
  providers: [
    DatePipe,
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class IssuesUpdateModalComponent implements OnInit {
  @Input() issue: IssueList;

  public isLoading = false;
  public updateIssueForm: FormGroup;
  public orderList$: Observable<Order[]>;
  public orderInput$ = new Subject<string>();
  public selectLoading = false;
  public issueStates$: Observable<IssueStates[]>;
  public today = formatDate(new Date(), 'dd.MM.yyyy', 'en');

  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private dataProvider: DataProviderService,
              private issueService: IssuesService,
  ) {
  }

  public ngOnInit() {
    this.init();
    this.issueStates$ = this.issueService.getIssueStates();
  }

  public updateIssue() {
    this.isLoading = true;
    const data = Object.assign({}, this.updateIssueForm.value);
    data.control_date = toApiDate(data.control_date);
    this.dataProvider.updateIssue(this.issue.id, data)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        () => {
          this.activeModal.close();
        }
      );
  }

  public onCancel() {
    this.activeModal.close();
  }

  private init() {
    this.getOrders();
    this.updateIssueForm = this.formBuilder.group({
      description: [this.issue.description, Validators.required],
      control_date: [fromApiDate(this.issue.control_date), Validators.required],
      state: [this.issue.state, Validators.required],
    });
  }

  public getOrders() {
    this.orderList$ = concat(
      of([]), // default items
      this.orderInput$.pipe(
        filter(term => {
          return term !== null && term.length >= 3;
        }),
        distinctUntilChanged(),
        debounceTime(400),
        share(),
        tap(() => this.selectLoading = true),
        switchMap(term => this.dataProvider.getOrderList({'order_number': term}).pipe(
          map(resp => {
            return resp.results;
          }),
          catchError(() => of([])), // empty list on error
          tap(() => this.selectLoading = false),
        ))
      ),
    );
  }

}
