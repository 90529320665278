import { NgModule } from '@angular/core';
import {
  Routes,
  RouterModule
} from '@angular/router';

import { AuthGuard } from './guards/auth.guard';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { StockRecordComponent } from './components/stock/stock-record.component';
import { SaleComponent } from './components/sale/sale.component';
import { OrderCreateComponent } from './components/sale/order-create/order-create.component';
import { StockRecordAddressComponent } from './components/stock/stock-address/stock-record-address.component';
import { ProductDetailComponent } from './components/stock/product/product-detail/product-detail.component';
import { OrderDetailComponent } from './components/sale/order-detail/order-detail.component';
import { HasUnsavedDataGuard } from './guards/has-unsaved-data.guard';
import {StockRecordAddressDetailComponent} from './components/stock/stock-address/stock-address-detail/stock-record-address-detail.component';
import {StockShipmentComponent} from './components/stock/stock-shipment/stock-shipment.component';
import {StockShipmentDetailComponent} from './components/stock/stock-shipment/stock-shipment-detail/stock-shipment-detail.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: {
      title: 'Главная'
    },
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Вход'
    }
  },

  {
    path: 'sale',
    canActivate: [AuthGuard],
    children: [
      {
        path: '', redirectTo: 'order',
        pathMatch: 'full'
      },
      {
        path: 'order',
        component: SaleComponent
      },
      {
        path: 'order/create',
        component: OrderCreateComponent,
        canDeactivate: [HasUnsavedDataGuard]
      },
      {
        path: 'order/:id',
        component: OrderDetailComponent,
        canDeactivate: [HasUnsavedDataGuard]
      },
    ]
  },
  {
    path: 'stock',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: 'product',
        pathMatch: 'full'
      },
      {
        path: 'product',
        component: StockRecordComponent,
        data: {
          title: 'Склад'
        },
      },
      {
        path: 'product/:id',
        component: ProductDetailComponent
      },
      {
        path: 'address',
        component: StockRecordAddressComponent,
        data: {
          title: 'Адреса'
        }
      },
      {
        path: 'address/:id',
        component: StockRecordAddressDetailComponent,
        data: {
          title: 'Товары на адресе'
        }
      },
      {
        path: 'manufacturers',
        loadChildren:  () => import('./modules/manufacturers-list/manufacturers-list.module').then(m => m.ManufacturersListModule),
        data: {
          title: 'Производители'
        }
      },
      {
        path: 'to-shipment',
        loadChildren:  () => import('./modules/shipment-list/shipment-list.module').then(m => m.ShipmentListModule),
        data: {
          title: 'Товары к отгрузке'
        }
      },
      {
        path: 'shipments',
        component: StockShipmentComponent,
        data: {
          title: 'Посылки'
        }
      },
      {
        path: 'shipments/:id',
        component: StockShipmentDetailComponent,
        data: {
          title: 'Детали посылки'
        }
      },
    ]
  },
  {
    path: 'payments',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren:  () => import('./modules/payments-list/payments-list.module').then(m => m.PaymentsListModule),
        data: {
          title: 'Платежи'
        }
      },
    ]
  },
  {
    path: 'issues',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren:  () => import('./modules/issues/issues.module').then(m => m.IssuesModule),
        data: {
          title: 'Проблемы'
        }
      },
    ]
  },
  {
    path: 'product-orders',
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren:  () => import('./modules/ordered-products-list/ordered-products-list.module').then(m => m.OrderedProductsListModule),
        data: {
          title: 'Заказы'
        }
      },
    ]
  },

  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
