import {Component, Injectable, Input, OnInit} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {INotification} from './notify-message.helper';
import {NotifyMessageService} from './notify-message.service';
import {animate, state, style, transition, trigger} from '@angular/animations';

@Component({
  selector: 'app-notify-message',
  templateUrl: './notify-message.component.html',
  styleUrls: ['./notify-message.component.scss', '../.././app.component.scss'],
  animations: [
    trigger('animateToasts', [
      state('void', style({ opacity: 0 })),
      transition(':enter, :leave', [ // void <=> *
        animate('0.3s ease')
      ])
    ])
  ]
})
@Injectable({
  providedIn: 'root'
})
export class NotifyMessageComponent {
  private componentDestroyed$: Subject<boolean> = new Subject();
  private lastInsertedNotificationId: string;

  @Input() type: string;
  @Input() max = 5;
  @Input() insertOnTop = true;
  @Input() duration = 60000;
  @Input() dismissOnClick = true;
  @Input() className: string;
  @Input() disableAnimations = false;

  notifications: INotification[] = [];

  constructor(
    private notifierService: NotifyMessageService,
  ) {
    this.notifierService.notification$.pipe(
      takeUntil(this.componentDestroyed$)
    ).subscribe((notification: INotification) => { this.updateNotifications(notification); });
  }

  private updateNotifications(notification: INotification): void {
    // checks whether the message is alrady present in notifications
    const index = this.notifications.map(function (e) { return e.message; }).indexOf(notification.message);

    // if (!this.allowDuplicates) {
    //   if (index !== -1) {
    //     return;
    //   }
    // }

    // save the last inserted Id
    this.lastInsertedNotificationId = notification.id;

    notification['message'] = notification.message;

    if (this.insertOnTop) {
      this.notifications.unshift(notification);
    } else {
      this.notifications.push(notification);
    }

    /**
     * remove the last inserted element if max has
     * pop or shift based on `insertOnTop`
     */
    if (this.notifications.length > this.max) {
      if (this.insertOnTop) {
        this.notifications.pop();
      } else {
        this.notifications.shift();
      }
    }

    // clear notification in given time
    setTimeout(() => {
      this.notifications.splice(index, 1);
    }, notification.duration || this.duration);

    return;

  }

  onNotificationClick(index: number): void {
    if (this.dismissOnClick) {
      this.removeNotification(index);
    }
    return;
  }

  removeNotification(index: number): void {
    if (index !== undefined || index !== null) {
      this.notifications.splice(index, 1);
    }
    return;
  }

}
