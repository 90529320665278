import { Injectable } from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';
import {DataProviderService} from '../data/data-provider.service';
import {Client, Shipper} from '../../models/sale';
import {first} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  public loading: BehaviorSubject<boolean> = new BehaviorSubject(true);

  constructor(
    private dataProvider: DataProviderService,
  ) {
  }

  public getShippers(params?) {
    return this.dataProvider.getShipperList(params)
      .pipe(
        first()
      );
  }

}
