import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';

import {Product, StockRecord, StockRecordAddress} from '../../../../models/stock';
import {DataProviderService} from '../../../../services/data/data-provider.service';
import {DEFAULT_VALUE, StockRecordAction} from '../../../../lib/constants';
import {NgbModal, NgbModalConfig, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../shared/notify-message/notify-message.service';
import {getAttributeValue} from 'codelyzer/util/getAttributeValue';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})
export class ProductDetailComponent implements OnInit {
  product: Product;
  stockRecords: StockRecord[] = [];
  stockRecordItem: StockRecord;
  action: string;
  loading = true;
  changeStockRecordForm: FormGroup;
  submitted = false;
  openedModal: NgbModalRef;
  stockRecordAddresses: StockRecordAddress[];

  public defaultVal = DEFAULT_VALUE;
  public stockRecordAction = StockRecordAction;

  constructor(
    private modalConfig: NgbModalConfig,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private location: Location,

    private dataProvider: DataProviderService,
    private notifierService: NotifyMessageService,
  ) {
    modalConfig.backdrop = 'static';
  }

  ngOnInit() {
    this.getProductDetail(this.route.snapshot.paramMap.get('id'));
    this.getStockAddress();
  }

  getProductDetail(id, params?): void {
    this.loading = true;

    this.dataProvider.getProductDetail(id, params)
      .subscribe(
        product => {
          this.product = product;
          this.stockRecords = product.stock_records;
          this.loading = false;
        }
      );
  }

  goBack(): void {
    this.location.back();
  }

  public changeStockRecord() {
    this.submitted = true;
    if (this.changeStockRecordForm.invalid) {
      this.submitted = false;
      return;
    }
    this.patchStockRecord(this.changeStockRecordForm.value);
  }

  private patchStockRecord(data) {
    let func;
    if (this.action === this.stockRecordAction.CHANGE) {
      func = this.dataProvider.changeStockRecord(this.product.id, data);
    } else if (this.action === this.stockRecordAction.MOVE) {
      func = this.dataProvider.moveStockRecord(this.product.id, data);
    } else { return; }
    func
      .subscribe(
        resp => {
          this.changeStockRecordForm.reset();
          this.stockRecords = resp;
          this.openedModal.close();
          this.submitted = false;
          this.stockRecordItem = undefined;
          this.recalcProductQuantity();
          this.notifierService.notify('Количество изменено', 'success', 4000);
        },
        err => {
          console.log(err);
          this.notifierService.notify('Произошла ошибка', 'danger', 4000);
          this.submitted = false;
        },
      );
  }

  private recalcProductQuantity() {
    let qty = 0;
    this.stockRecords.forEach(item => {
      qty += item.quantity;
    });
    this.product.quantity = qty;
  }

  private modalChangeStockRecordOpen(content, stockRecordItem, action) {
    this.stockRecordItem = stockRecordItem;
    this.action = action;
    this.changeStockRecordForm = this.formBuilder.group({
      address: [null, Validators.required],
      quantity: [null, Validators.required],
      id: [this.stockRecordItem.id],
    });
    this.changeStockRecordForm.get('quantity').setValue(stockRecordItem.quantity);
    this.changeStockRecordForm.get('address').setValue(stockRecordItem.address.id);
    this.openedModal = this.modalService.open(content);
    this.openedModal.result.then((result) => {
    }, (reason) => {
      console.log(reason);
    });
  }

  private async getStockAddress() {
    await this.dataProvider.getStockAddress()
      .subscribe(
        response => {
          this.stockRecordAddresses = response;
        }
      );
  }

}
