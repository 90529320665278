import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import {AuthService} from '../services/auth/auth.service';
import {STORAGE_NAME} from '../lib/constants';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
    const token = localStorage.getItem(STORAGE_NAME.CURRENT_USER_TOKEN);
    if (token != null) {
      const roles = next.data['permittedGroups'] as Array<string>;
      if (roles) {
        if (this.authService.groupMatch(roles)) { return true; } else {
          this.router.navigate(['/forbidden']);
          return false;
        }
      }
      return true;
    } else {
      this.router.navigate(['/login']);
      return false;
    }

  }
}
