import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {concat, Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, switchMap, tap} from 'rxjs/operators';

import {DataProviderService} from '../../services/data/data-provider.service';
import {DictItem, Order} from '../../models/sale';
import {FiltersService} from '../../shared/filters/services/filters.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Filters, OrderStatus} from '../../lib/constants';
import {toApiDate} from '../../shared/datepicker';

@Component({
  selector: 'app-sale',
  templateUrl: './sale.component.html',
  styleUrls: ['./sale.component.scss']
})
export class SaleComponent implements OnInit, OnDestroy {
  private _subscription: Subscription;
  public loading = true;
  public orders: Order[];
  public orderStatus = OrderStatus;
  public orderStatuses: DictItem[] = [];

  public pagination: any = {
    count: 10,
    per_page: 50,
    current_page: 1
  };

  constructor(
    private router: Router,
    private dataProvider: DataProviderService,
    private stockFiltersService: FiltersService,
  ) {
    this._subscription = new Subscription();
  }

  static downloadFile(body, options, filename) {
    const blob = new Blob([body], options);
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  static getFileNameFromHttpResponse(response) {
    const contentDispositionHeader = response.headers.get('Content-Disposition');
    const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }

  ngOnInit() {
    this._subscription.add(
      this.stockFiltersService.filterParams.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(params => this.getOrders(params))
      ).subscribe()
    );
    this._subscription.add(
      this.dataProvider.getOrderStatuses().subscribe(resp => this.orderStatuses = resp)
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  get paginationPage() {
    return this.pagination.current_page;
  }

  set paginationPage(page: number | string) {
    if (Number(page) === Number(this.paginationPage) || !page) {
      return;
    }
    this.pagination.current_page = Number(page);
    this.stockFiltersService.updateQueryParameter(Filters.PAGE, String(page));
  }

  public getTotalPayments(currency) {
    if (!this.orders) {
      return 0;
    }
    return this.orders
      .filter(order => order.currency === currency)
      .map(p => p['total']).reduce((acc, value) => acc + Number(value), 0);
  }

  setOrderStatuses() {
    this.orders.forEach((order: any) => {
      order.statusTitle = this.getStatusTitle(order.status);
    });
  }

  getOrders(params?): Observable<any> {
    this.loading = true;

    return this.dataProvider.getOrderList(params).pipe(
      map(resp => {
        this.orders = resp.results;
        this.pagination.count = resp.count;
        this.setOrderStatuses();
        this.loading = false;
      }),
    );
  }

  exportOrdersTo1C(): void {
    const data = Object.assign({}, this.stockFiltersService.filterParams.value);
    data.created_at_gte = toApiDate(data.created_at_gte);
    data.created_at_lte = toApiDate(data.created_at_lte);
    data.updated_at_gte = toApiDate(data.updated_at_gte);
    data.updated_at_lte = toApiDate(data.updated_at_lte);
    Object.keys(data)
      .filter(key => data[key] == null)
      .forEach(key => delete data[key]);
    this.dataProvider.exportOrdersTo1C(data)
      .subscribe(
        response => {
          const headers = response.headers;
          const contentType = headers.get('content-type');
          const options = {type: contentType};
          const filename = SaleComponent.getFileNameFromHttpResponse(response);
          SaleComponent.downloadFile(response.body, options, filename);
        },
        error => {
          console.log('Error downloading the file.' + JSON.stringify(error));
        }
      );
  }

  private getStatusTitle(statusCode) {
    const foundStatus = this.orderStatuses.find(
      status => status.code && status.code.toUpperCase() === statusCode.toUpperCase(),
    );
    return foundStatus ? foundStatus.title : '';
  }

  exportOrder(id, params?): void {
    this.dataProvider.exportOrder(id, params)
      .subscribe(
        response => {
          const headers = response.headers;
          const contentType = headers.get('content-type');
          const options = {type: contentType};
          const filename = SaleComponent.getFileNameFromHttpResponse(response);
          SaleComponent.downloadFile(response.body, options, filename);
        },
        error => console.log('Error downloading the file.' + error),
      );
  }

  navigateOrderDetail(id): void {
    this.router.navigate(['/sale', 'order', id]);
  }

}
