import { EventEmitter } from '@angular/core';


export function toFormData<T>( formValue: T ) {
  const formData = new FormData();

  for ( const key of Object.keys(formValue) ) {
    const value = formValue[key];
    formData.append(key, value);
  }

  return formData;
}

/**
 * @description eventToPromise Позволяет сделать ожидание события без подписки на него
 * @param condition Условие при котором должен сработать промис
 */
export function eventToPromise<T>(event: EventEmitter<T>, condition?: (value: T) => boolean): Promise<T> {
  return new Promise<T>(resolve => {
    const subscription = event.subscribe(v => {
      if (condition && !condition(v)) {
        return;
      }
      subscription.unsubscribe();
      resolve(v);
    });
  });
}
