import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingMessageModule} from '../../shared/loading-message/loading-message.module';
import {NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { ShipmentListComponent } from './shipment-list/shipment-list.component';
import {ShipmentListRoutingModule} from './shipment-list-routing.module';
import {DirectivesModule} from '../../shared/directives/directives.module';
import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
  imports: [
    CommonModule,
    LoadingMessageModule,
    DirectivesModule,
    FormsModule,
    ShipmentListRoutingModule,
    NgbPaginationModule,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ShipmentListComponent,
  ],
})
export class ShipmentListModule {
}
