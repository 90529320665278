import {
  Component, OnDestroy, OnInit,
} from '@angular/core';
import {DataProviderService} from '../../../services/data/data-provider.service';
import {
  ActivatedRoute,
  Router
} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {IssuesCreateModalComponent} from '../issues-create-modal/issues-create-modal.component';
import {concat, Observable, of, Subject, Subscription} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, filter, first, map, share, switchMap, tap} from 'rxjs/operators';
import {FiltersService} from '../../../shared/filters/services/filters.service';
import {IssueList, IssueStates} from '../../../models/types/issue.interface';
import {IssuesUpdateModalComponent} from '../issues-update-modal/issues-update-modal.component';
import {IssuesService} from '../issues.service';

@Component({
  selector: 'app-issues-list',
  templateUrl: './issues-list.component.html',
  styleUrls: ['./issues-list.component.scss']
})
export class IssuesListComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public issuesList: IssueList[] = [];
  public pageSize = 50;
  public collectionSize = undefined;
  public issueStates: IssueStates[] = [];
  private _paginationPage = undefined;
  private _subscription: Subscription;

  public get paginationPage(): number {
    return this._paginationPage;
  }

  public set paginationPage(page: number) {
    this._paginationPage = page;
  }

  constructor(
    private dataProvider: DataProviderService,
    private filtersService: FiltersService,
    private issueService: IssuesService,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
  ) {
    this._subscription = new Subscription();
  }

  public updatePage(page: number) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        page: page
      },
      replaceUrl: true,
    });
  }

  public async ngOnInit(): Promise<void> {
    this.paginationPage = Number(this.route.queryParams['value'].page) || 1;
    this.subscribe();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  public openCreateModal(): void {
    const modalRef = this.modalService.open(IssuesCreateModalComponent);
    modalRef.result.finally(async () => {
      this.getList().pipe(first()).subscribe();
    });
  }

  public openUpdateModal(item: IssueList): void {
    const modalRef = this.modalService.open(IssuesUpdateModalComponent);
    modalRef.componentInstance.issue = item;
    modalRef.result.finally(async () => {
      this.getList().pipe(first()).subscribe();
    });
  }

  public deleteIssueModalOpen(content, item: IssueList) {
    const modal = this.modalService.open(content);
    modal.result.then((result) => {
      console.log('!!');
      this.dataProvider.deleteIssue(item.id).pipe(first()).subscribe();
      this.getList().pipe(first()).subscribe();
    }, (reason) => {
      console.log(reason);
    });
  }

  public is_issue_overdue(item: IssueList): Boolean {
    return Date.parse(item.control_date) < new Date().setHours(0, 0, 0, 0);
  }

  private subscribe() {
    this._subscription.add(
      this.filtersService.filterParams.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(
          params => this.getList(params)
        ),
      ).subscribe()
    );
    this._subscription.add(
      this.issueService.getIssueStates().subscribe(resp => this.issueStates = resp)
    );
  }

  private setIssueStates() {
    this.issuesList.forEach((item: IssueList) => {
      item.stateTitle = this.getStateTitle(item.state);
    });
  }

  private getStateTitle(code) {
    const foundStatus = this.issueStates.find(
      status => status.code && status.code.toUpperCase() === code.toUpperCase(),
    );
    return foundStatus ? foundStatus.title : '';
  }

  private getList(params?): Observable<any> {
    this.isLoading = true;
    return this.dataProvider.getIssuesList(params)
      .pipe(
        first(),
        map(resp => {
          this.issuesList = resp.results;
          this.collectionSize = resp.count;
          this.setIssueStates();
          this.isLoading = false;
        }));
  }


}
