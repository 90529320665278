import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {UnitType} from '../../../../models/enums';
import {DataProviderService} from '../../../../services/data/data-provider.service';
import {NotifyMessageService} from '../../../../shared/notify-message/notify-message.service';
import {DictItem} from '../../../../models/stock';
import {Unit} from '../../../../models/types';
import {ProductService} from '../product.service';
import {Observable} from 'rxjs';

export enum ProductFormField {
  NAME = 'name',
  CATEGORY = 'category',
  MANUFACTURER = 'manufacturer',
  TNVED = 'tnved',
  BARCODE = 'barcode',
  // QUANTITY = 'quantity',
  UNIT = 'unit',
}

export type ProductFormConfig = { [key in ProductFormField]?: boolean };

@Component({
  selector: 'app-product-create',
  templateUrl: './product-create.component.html',
  styleUrls: ['./product-create.component.scss']
})
export class ProductCreateComponent implements OnInit {

  @Input() productName: string;
  @Input() productCategoryName: string;
  @Input() formConfig: ProductFormConfig;

  @Output() public OnProductCreate = new EventEmitter();
  @Output() public OnCancel = new EventEmitter();

  public categoryList: DictItem[];
  public manufacturersList: DictItem[];
  public unitTypes$: Observable<Unit[]>;
  public productFormFields = ProductFormField;

  public isCreateLoading = false;
  public isCreateProductFormValidate = false;
  public createProductForm: FormGroup;


  constructor(
    private dataProvider: DataProviderService,
    private notifierService: NotifyMessageService,
    private productService: ProductService,
    private formBuilder: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.preloadCategories();
    this.preloadManufacturers();
    this.initCreateProductForm();
    this.unitTypes$ = this.productService.getUnitTypes();
  }

  get productFromCtrls() {
    return this.createProductForm.controls;
  }

  public onCategoryChoice(category) {
    this.productCategoryName = category.name;
  }

  get productNameValue(): string {
    const name = this.productFromCtrls['name'] && this.productFromCtrls['name'].value;
    return this.productName || name;
  }

  public isFieldVisible(fieldName: ProductFormField): boolean {
    return this.formConfig.hasOwnProperty(fieldName);
  }

  public createProduct() {
    if (!this.createProductForm.valid) {
      this.isCreateProductFormValidate = true;
      return;
    }
    this.isCreateLoading = true;

    // const productData = Object.assign({},
    //   this.createProductForm.value,
    //   {
    //     name: this.productNameValue,
    //   });
    this.dataProvider.createProduct(this.createProductForm.value).pipe()
      .subscribe(
        created_product => {
          this.dataProvider.getProductDetail(created_product.id).subscribe(
            product => {
              this.notifierService.notify('Товар успешно создан', 'success');
              this.isCreateLoading = false;
              this.OnProductCreate.emit(product);
            }
          );
        },
        err => {
          this.isCreateLoading = false;
          console.log(err);
          this.notifierService.notify('Ошибка создания товара', 'danger');
        },
      );
  }

  public cancelCreateProduct() {
    this.OnCancel.emit(this.productNameValue);
  }

  private async preloadCategories() {
    await this.dataProvider.getCategoryList()
      .subscribe(
        response => {
          this.categoryList = response;
        }
      );
  }

  private async preloadManufacturers() {
    this.manufacturersList = await this.dataProvider.getManufacturerListAll();
  }

  private initCreateProductForm() {
    this.isCreateProductFormValidate = false;
    const controls = {};
    for (const productField of Object.keys(this.productFormFields)) {
      const key = this.productFormFields[productField];
      if (key in this.formConfig) {
        const control = [];
        const isRequired = this.formConfig[key];
        control.push(this.getFieldDefaultValue(key));
        if (isRequired) {
          control.push(Validators.required);
        }
        controls[key] = control;
      }
    }
    // controls[ProductFormField.NAME] = [this.productName, [Validators.required, Validators.pattern(/^[\dA-Za-z-_.]+$/)]];
    controls[ProductFormField.NAME] = [
      this.productName, [
        Validators.required,
        this.customPatternValid({pattern: /^[\dA-Za-z~!@#$%^&*()\-+`"№;:?{}\[\]'\\\/.,<>_= ]+\S$/, msg: 'Название содержит недопустимые символы', errorPattern: /[\dA-Za-z~!@#$%^&*()\-+`"№;:?{}\[\]'\\\/.,<>_= ]/g})
      ]
    ];
    this.createProductForm = this.formBuilder.group(controls);
  }

  private getFieldDefaultValue(fieldId: keyof ProductFormField) {
    const defaults: { [key in ProductFormField]: any } = {
      [ProductFormField.NAME]: null,
      [ProductFormField.CATEGORY]: null,
      [ProductFormField.MANUFACTURER]: null,
      [ProductFormField.TNVED]: null,
      [ProductFormField.BARCODE]: null,
      // [ProductFormField.QUANTITY]: 0,
      [ProductFormField.UNIT]: UnitType.THING,
    };
    return defaults[fieldId];
  }

  public customPatternValid(config: any): ValidatorFn {
    return (control: FormControl) => {
      const urlRegEx: RegExp = config.pattern;
      if (control.value && !control.value.match(urlRegEx)) {
        console.log(control.value.replace(new RegExp(urlRegEx, 'g'), ''));
        return {
          errorMessage: `${config.msg}: "${control.value.replace(config.errorPattern, '')}"`
        };
      } else {
        return null;
      }
    };
  }

}
