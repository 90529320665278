<form [formGroup]="filtersForm" action="">
  <div class="row">
    <div class="col-6 form-group">
      <div class="input-icon mb-3">
        <input formControlName="order_number" type="text" class="form-control" placeholder="Номер счета">
        <span class="input-icon-addon"><i class="fe fe-search"></i></span>
      </div>
    </div>

    <div class="col-6 form-group">
      <ng-container [formGroup]="filtersForm">
        <ng-select [items]="issueStates"
                   class="form-control custom-select"
                   [searchable]="true"
                   [clearable]="true"
                   bindLabel="title"
                   bindValue="code"
                   formControlName="state"
                   placeholder="Статус"
                   required>
        </ng-select>
      </ng-container>
    </div>

    <div class="container">
      <div class="row">
        <div class="form-group hidden">
          <div class="input-group">
            <input name="datepicker"
                   class="form-control invisible"
                   ngbDatepicker
                   #datepicker="ngbDatepicker"
                   [autoClose]="'outside'"
                   [footerTemplate]="footerTemplate"
                   (dateSelect)="onDateSelection($event)"
                   [displayMonths]="2"
                   [dayTemplate]="t"
                   outsideDays="hidden"
                   [startDate]="start_date"/>
          </div>
        </div>
        <div class="col-6 form-group">
          <ng-template #t let-date let-focused="focused">
          <span class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
          </ng-template>
          <div class="input-group">
            <input formControlName="start_date"
                   class="form-control"
                   placeholder="Ожидаемая дата заказа от"
                   #dpFromDate
                   name="dpFromDate"
                   [value]="formatter.format(start_date)"
                   (input)="start_date = validateInput(end_date, dpFromDate.value)"
                   type="text">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar"
                      type="button"
                      (click)="datepicker.toggle()">
                <i class="fe fe-calendar"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="col-6 form-group">
          <div class="input-group">
            <input formControlName="end_date"
                   class="form-control"
                   placeholder="Ожидаемая дата заказа до"
                   #dpToDate
                   name="dpToDate"
                   [value]="formatter.format(end_date)"
                   (input)="end_date = validateInput(end_date, dpToDate.value)"
                   type="text">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar"
                      type="button"
                      (click)="datepicker.toggle()">
                <i class="fe fe-calendar"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <button class="btn btn-primary btn-sm float-right m-0" (click)="clearFilter()">Очистить</button>
    </div>
  </div>
</form>

<ng-template #footerTemplate>
  <hr class="my-0">
  <button class="btn btn-primary btn-sm m-2 float-left" (click)="filtersForm.controls.start_date.setValue(today);filtersForm.controls.end_date.setValue(today);">Сегодня</button>
  <button class="btn btn-secondary btn-sm m-2 float-right" (click)="datepicker.close()">Закрыть</button>
</ng-template>
