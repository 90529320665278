import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ProductList} from '../../../models/stock';
import {concat, Observable, of, Subject} from 'rxjs';

import {catchError, debounceTime, distinctUntilChanged, finalize, map, switchMap, tap} from 'rxjs/operators';
import {DataProviderService} from '../../../services/data/data-provider.service';
import {NotifyMessageService} from '../../../shared/notify-message/notify-message.service';
import {ProductFormConfig, ProductFormField} from '../../stock/product/product-create/product-create.component';
import {ProductService} from '../../stock/product/product.service';

@Component({
  selector: 'app-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.scss']
})
export class AddProductModalComponent implements OnInit {
  public productForm: FormGroup =
    this.formBuilder.group({
      selectedProducts: []
    });

  public productFormConfig: ProductFormConfig = {
    // [ProductFormField.NAME]: true,
    [ProductFormField.CATEGORY]: true,
    [ProductFormField.MANUFACTURER]: false,
    // ProductFormField.TNVED: true,
    // ProductFormField.BARCODE: true,
    // ProductFormField.QUANTITY: true,
    [ProductFormField.UNIT]: true,
  };

  public isCreateProductFormVisible = false;
  public newProduct: string = null;


  public error = '';

  constructor(
    private formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    public productService: ProductService,
  ) {
  }

  public get selectedProducts(): any[] {
    return this.productForm.controls['selectedProducts'].value;
  }

  public set selectedProducts(products: any[]) {
    this.productForm.controls['selectedProducts'].setValue(products);
  }

  ngOnInit(): void {
    this.productService.getProducts();
  }

  public async addNewTag(item) {
    if (item.id) {
      return;
    }

    this.newProduct = item.name.trim();
    this.productForm.controls['selectedProducts'].disable();
    this.isCreateProductFormVisible = true;
  }

  public addProduct() {
    if (!this.selectedProducts) {
      return;
    }
    this.activeModal.close(this.selectedProducts);
    this.selectedProducts = [];
  }

  public onProductCreate(product: any) {
    const newProducts = this.selectedProducts.slice();
    this.selectedProducts.map((item, idx) => {
      if (product.name === item.name.trim()) {
        newProducts.splice(idx, 1, product);
        this.productForm.setValue({
          selectedProducts: newProducts
        });
      }
    });
    this.clearCreateProductForm();
  }

  public onProductCreateCancel(product: string) {
    this.selectedProducts = this.selectedProducts.filter(p => p.name !== product);
    this.clearCreateProductForm();
  }

  public clearCreateProductForm() {
    this.newProduct = null;
    this.isCreateProductFormVisible = false;
    this.productForm.controls['selectedProducts'].enable();
  }

}
