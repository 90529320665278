  <div class="row">
    <div class="col col-login mx-auto">
      <div class="text-center mb-6">
        <img *ngIf="logoUrl" [src]="logoUrl" class="h-6" alt="">
      </div>
      <form class="card" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="card-body p-6">
          <div class="card-title">Вход в систему</div>
          <div class="form-group" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
            <label class="form-label">Логин</label>
            <input type="text" formControlName="username" class="form-control" placeholder="Введите логин">
            <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
              <div *ngIf="f.username.errors.required">Введите логин</div>
            </div>
          </div>
          <div class="form-group">
            <label class="form-label" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
              Пароль
            </label>
            <input type="password" formControlName="password" class="form-control" placeholder="Введите пароль">
            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
              <div *ngIf="f.password.errors.required">Введите пароль</div>
            </div>
          </div>
          <div class="form-footer">
            <button [disabled]="loading" type="submit" class="btn btn-primary btn-block">Войти</button>
            <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpK
            SkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2M
            IOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlF
            UYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmK
            twHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0
            R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6Twf
            wyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSl
            KAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKA
            AAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
          </div>
          <div *ngIf="error" class="alert alert-danger">{{error}}</div>
        </div>
      </form>
    </div>
  </div>
