import {
  Component,
  OnInit
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { DataProviderService } from '../../../services/data/data-provider.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-manufacturer-create-modal',
  templateUrl: './manufacturer-create-modal.component.html',
  styleUrls: ['./manufacturer-create-modal.component.scss']
})
export class ManufacturerCreateModalComponent implements OnInit {
  public isLoading = false;
  public createManufacturerForm: FormGroup;

  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private dataProvider: DataProviderService,
  ) {
  }

  public ngOnInit() {
    this.init();
  }

  public createManufacturer() {
    this.isLoading = true;
    this.dataProvider.createManufacturer(this.createManufacturerForm.value)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        () => {
          this.activeModal.close();
        }
      );
  }

  public onCancel() {
    this.activeModal.close();
  }

  private init() {
    this.createManufacturerForm = this.formBuilder.group({
      name: ['', Validators.required],
    });
  }

}
