import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {DataProviderService} from '../../../services/data/data-provider.service';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {debounceTime, distinctUntilChanged, finalize, first, map, switchMap} from 'rxjs/operators';
import {FormBuilder} from '@angular/forms';
import {OrderedProductsCreateModalComponent} from '../ordered-products-create-modal/ordered-products-create-modal.component';
import {FiltersService} from '../../../shared/filters/services/filters.service';
import {OrderLine, ProductOrder, ProductOrderDetail} from '../../../models/sale';

@Component({
  selector: 'app-payments-list',
  templateUrl: './requiered-to-order-list.component.html',
  styleUrls: ['./requiered-to-order-list.component.scss']
})
export class RequieredToOrderListComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public orderLines: OrderLine[] = [];
  public pageSize = 50;
  public collectionSize = undefined;
  private _subscription: Subscription;

  constructor(
    private dataProvider: DataProviderService,
    private filtersService: FiltersService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private modalConfig: NgbModalConfig,
  ) {
    this._subscription = new Subscription();
    modalConfig.backdrop = 'static';
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private subscribe() {
    this._subscription.add(
      this.filtersService.filterParams.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(params => this.getList(params)),
      ).subscribe(),
    );
  }

  public openCreateModal(orderLine: OrderLine): void {
    const modalRef = this.modalService.open(OrderedProductsCreateModalComponent);
    modalRef.componentInstance.orderLine = orderLine;
    modalRef.result.then((result) => {
      this.getList().pipe(first()).subscribe();
    }, (reason) => {
      console.log(reason);
    });
  }

  private getList(params?): Observable<any> {
    this.isLoading = true;
    this.orderLines = [];
    return this.dataProvider.getRequiredToOrderProducts(params)
      .pipe(
        first(),
        map(resp => {
          resp.forEach(item => {
            item.is_duplicated = resp.filter(v => v.product.name === item.product.name).length > 1;
            this.orderLines.push(item);
          });
          this.isLoading = false;
        }));
  }

  public navigateOrderDetail(id): void {
    this.router.navigate(['/sale', 'order', id]);
  }

}
