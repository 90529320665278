import {
  Component, Input,
  OnInit
} from '@angular/core';
import {NgbActiveModal, NgbCalendar, NgbDate, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { DataProviderService } from '../../../services/data/data-provider.service';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, first, map, share, switchMap, tap} from 'rxjs/operators';
import {concat, Observable, of, Subject} from 'rxjs';
import {Client, Order, OrderLine, ProductOrderDetail, Shipper} from '../../../models/sale';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n, toApiDate} from '../../../shared/datepicker';
import {DatePipe, formatDate} from '@angular/common';


@Component({
  selector: 'app-ordered-products-close-modal',
  templateUrl: './ordered-products-close-modal.component.html',
  styleUrls: ['./ordered-products-close-modal.component.scss'],
  providers: [
    DatePipe,
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class OrderedProductsCloseModalComponent implements OnInit {
  @Input() productOrder: ProductOrderDetail;

  public isLoading = false;

  constructor(public activeModal: NgbActiveModal,
              private calendar: NgbCalendar,
              private dataProvider: DataProviderService,
  ) {
  }

  public ngOnInit() {
  }

  public closeProductOrder() {
    this.isLoading = true;
    this.dataProvider.updateProductOrder(this.productOrder.id, {'state': 'CLOSED'}).pipe(
      first(),
      finalize(() => {
          this.isLoading = false;
        }
      ))
      .subscribe(() => {
          this.activeModal.close();
        }
      );
  }

}
