import {
  Component, Input,
  OnInit
} from '@angular/core';
import {NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, first, map, share, switchMap, tap} from 'rxjs/operators';
import {concat, Observable, of, Subject} from 'rxjs';
import {DatePipe, formatDate} from '@angular/common';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n, toApiDate} from '../../../../shared/datepicker';
import {DataProviderService} from '../../../../services/data/data-provider.service';
import {Order, Shipper} from '../../../../models/sale';
import {StockService} from '../../../../services/stock/stock.service';
import {Currency} from '../../../../models/types';
import {SaleService} from '../../../../services/sale/sale.service';

@Component({
  selector: 'app-recalculate-modal',
  templateUrl: './modal-recalculate.component.html',
  styleUrls: ['./modal-recalculate.component.scss'],
  providers: [
    DatePipe,
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class ModalRecalculateComponent implements OnInit {
  @Input() order: Order;

  public currencyTypes$: Observable<Currency[]>;
  public isLoading = false;
  public recalculateForm: FormGroup;

  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private stockService: StockService,
              private saleService: SaleService,
              private dataProvider: DataProviderService,
  ) {
  }

  public ngOnInit() {
    this.init();
  }

  public recalculate() {
    this.isLoading = true;
    this.dataProvider.recalculate(this.order.id, this.recalculateForm.value)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        () => {
          this.activeModal.close();
        }
      );
  }

  public onCancel() {
    this.activeModal.close();
  }

  private init() {
    this.currencyTypes$ = this.saleService.getCurrencyTypes();
    this.recalculateForm = this.formBuilder.group({
      coefficient: [null, Validators.required],
      currency: [this.order.currency, Validators.required],
    });
  }

}
