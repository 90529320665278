import {
  APP_INITIALIZER,
  NgModule,
} from '@angular/core';
import { FaviconSetterService } from '@app/modules/favicon-setter/services/favicon-setter.service';

export function startFaviconSetterFactory(faviconSetter: FaviconSetterService) {
  return () => {
    faviconSetter.setFavicon();
  };
}

@NgModule({
  providers: [
    FaviconSetterService,
    {
      provide: APP_INITIALIZER,
      deps: [
        FaviconSetterService,
      ],
      useFactory: startFaviconSetterFactory,
      multi: true,
    },
  ],
})
export class FaviconSetterModule { }
