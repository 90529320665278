import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {DataProviderService} from '../../../services/data/data-provider.service';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {debounceTime, distinctUntilChanged, finalize, first, map, switchMap} from 'rxjs/operators';
import {FormBuilder} from '@angular/forms';
import {FiltersService} from '../../../shared/filters/services/filters.service';
import {ProductOrderDetail} from '../../../models/sale';
import {
  OrderedProductsCloseModalComponent
} from '../ordered-products-close-modal/ordered-products-close-modal.component';
import {
  OrderedProductsUpdateModalComponent
} from '../ordered-products-update-modal/ordered-products-update-modal.component';
import {Filters} from '../../../lib/constants';
import {ProductOrderState} from '../../../models/types';
import {
  OrderedProductsDeleteModalComponent
} from '../ordered-products-delete-modal/ordered-products-delete-modal.component';
import {ProductOrderStatusEnum} from '@app/models/enums';

@Component({
  selector: 'app-payments-list',
  templateUrl: './ordered-products-list.component.html',
  styleUrls: ['./ordered-products-list.component.scss']
})
export class OrderedProductsListComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public productOrders: ProductOrderDetail[] = [];
  public pageSize = 50;
  public productOrderStates: ProductOrderState[] = [];
  public collectionSize = undefined;
  public filterParams: any = {};
  private _subscription: Subscription;

  public pagination: any = {
    count: 10,
    per_page: 50,
    current_page: 1
  };

  constructor(
    private dataProvider: DataProviderService,
    private filtersService: FiltersService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private modalConfig: NgbModalConfig,
  ) {
    this._subscription = new Subscription();
    modalConfig.backdrop = 'static';
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  private subscribe() {
    this._subscription.add(
      this.filtersService.filterParams.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(params => {
            this.filterParams = params;
            return this.getList(params);
          }

        ),
      ).subscribe(),
    );
    this.dataProvider.getProductOrderStates().subscribe(resp => this.productOrderStates = resp);
  }

  get paginationPage() {
    return this.pagination.current_page;
  }

  set paginationPage(page: number | string) {
    if (Number(page) === Number(this.paginationPage) || !page) {
      return;
    }
    this.pagination.current_page = Number(page);
    this.filtersService.updateQueryParameter(Filters.PAGE, String(page));
  }

  public openConfirmDeleteModal(productOrder: ProductOrderDetail): void {
    const modalRef = this.modalService.open(OrderedProductsDeleteModalComponent);
    modalRef.componentInstance.productOrder = productOrder;
    modalRef.result.then((result) => {
      this.getList(this.filterParams).pipe(first()).subscribe();
    }, (reason) => {
      console.log(reason);
    });
  }

  public openConfirmCloseModal(productOrder: ProductOrderDetail): void {
    const modalRef = this.modalService.open(OrderedProductsCloseModalComponent);
    modalRef.componentInstance.productOrder = productOrder;
    modalRef.result.then((result) => {
      this.getList(this.filterParams).pipe(first()).subscribe();
    }, (reason) => {
      console.log(reason);
    });
  }

  public setProductOrderAtWork(productOrder: ProductOrderDetail) {
    this.isLoading = true;
    this.dataProvider.updateProductOrder(productOrder.id, {'state': ProductOrderStatusEnum.IN_PROGRESS}).pipe(
      first(),
      finalize(() => {
          this.isLoading = false;
        }
      ))
      .subscribe(() => {
          this.getList(this.filterParams).pipe(first()).subscribe();
        }
      );
  }

  setProductOrderStatuses() {
    this.productOrders.forEach((item: ProductOrderDetail) => {
      item.stateTitle = this.getStatusTitle(item.state);
    });
  }

  private getStatusTitle(statusCode) {
    const foundStatus = this.productOrderStates.find(
      status => status.code && status.code.toUpperCase() === statusCode.toUpperCase(),
    );
    return foundStatus ? foundStatus.title : '';
  }

  public openUpdateModal(productOrder: ProductOrderDetail): void {
    const modalRef = this.modalService.open(OrderedProductsUpdateModalComponent);
    modalRef.componentInstance.productOrder = productOrder;
    modalRef.result.then((result) => {
      this.getList(this.filterParams).pipe(first()).subscribe();
    }, (reason) => {
      console.log(reason);
    });
  }

  private getList(params?): Observable<any> {
    this.isLoading = true;
    return this.dataProvider.listProductOrders(params)
      .pipe(
        first(),
        map(resp => {
          this.productOrders = resp.results;
          this.pagination.count = resp.count;
          this.setProductOrderStatuses();
          this.isLoading = false;
        }));
  }

  public navigateOrderDetail(id): void {
    this.router.navigate(['/sale', 'order', id]);
  }

}
