import { Injectable } from '@angular/core';
import {CanDeactivate} from '@angular/router';
import {HasUnsavedData} from '../models/guard';


@Injectable({ providedIn: 'root' })
export class HasUnsavedDataGuard implements CanDeactivate<any> {
  canDeactivate(component: HasUnsavedData): boolean {
    if (component.hasUnsavedData && component.hasUnsavedData()) {
      return confirm('На странице присутствуют несохраненные данные, покинуть страницу?');
    }
    return true;
  }
}
