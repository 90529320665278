import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DataProviderService} from '../../../../services/data/data-provider.service';
import {Product} from '../../../../models/stock';
import {FiltersService} from '../../../../shared/filters/services/filters.service';
import {ProductFormConfig, ProductFormField} from '../product-create/product-create.component';

@Component({
  selector: 'app-modal-create',
  templateUrl: './modal-create.component.html',
  styleUrls: ['./modal-create.component.scss']
})
export class ModalCreateComponent implements OnInit {
  error = '';
  createProductForm: FormGroup;
  product: Product;
  loading = false;
  units = [{name: 'шт.', code: 'THING'}, {name: 'м', code: 'METER'}];
  public productFormConfig: ProductFormConfig = {
    [ProductFormField.NAME]: true,
    [ProductFormField.CATEGORY]: true,
    [ProductFormField.MANUFACTURER]: false,
    [ProductFormField.TNVED]: false,
    [ProductFormField.BARCODE]: false,
    [ProductFormField.UNIT]: false,
  };

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private dataProvider: DataProviderService,
    private stockFiltersService: FiltersService,
  ) {
  }

  public onProductCreate(product: any) {
    this.product = product;
    this.stockFiltersService.filterParams.next({});
    this.activeModal.close();
  }

  public onProductCreateCancel(product: string) {
    this.activeModal.close('Close!');
  }

  ngOnInit() {

    this.createProductForm = this.formBuilder.group({
      name: ['', Validators.required],
      barcode: ['', ],
      unit: [this.units[0].code, Validators.required],
    });
  }
}
