import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthService} from '../services/auth/auth.service';
import {STORAGE_NAME} from '../lib/constants';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(STORAGE_NAME.CURRENT_USER_TOKEN);
      return next.handle(request.clone()).pipe(
        tap(
          success => { },
          err => {
            if (err.status === 401) {
              this.authService.logout();
            } else if (err.status === 403) {
              this.router.navigateByUrl('/forbidden');
            } else {
              return;
            }
          }
        )
      );
  }
}
