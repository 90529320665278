<div class="notify-message" *ngIf="notifications?.length !== 0" [ngClass]="className" [@animateToasts] [@.disabled]="disableAnimations">
  <div [ngClass]="{'pointer': dismissOnClick}">
    <div class="alert" role="alert" *ngFor="let notification of notifications; let i = index"
         [ngClass]="'alert-'+ notification.style" (click)="onNotificationClick(i)" [@animateToasts]>
      <button type="button" class="close" aria-label="Close" (click)="removeNotification(i)">
        <span aria-hidden="true">&times;</span>
      </button>
      <p>{{ notification.message }} </p>
    </div>
  </div>
</div>
