import {
  Component, Input,
  OnInit
} from '@angular/core';
import {
  NgbActiveModal,
  NgbCalendar,
  NgbDate,
  NgbDateAdapter,
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbModal
} from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {DataProviderService} from '../../../services/data/data-provider.service';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, first, map, share, switchMap, tap} from 'rxjs/operators';
import {concat, Observable, of, Subject, timer} from 'rxjs';
import {Client, Order, OrderLine, Shipper} from '../../../models/sale';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n, toApiDate} from '../../../shared/datepicker';
import {DatePipe, formatDate} from '@angular/common';
import {StockRecord} from '../../../models/stock';
import {ClientsService} from '../../../components/sale/clients.service';
import {OrderService} from '../../../components/sale/order.service';
import {NotifyMessageService} from '../../../shared/notify-message/notify-message.service';


@Component({
  selector: 'app-ordered-products-create-modal',
  templateUrl: './ordered-products-create-modal.component.html',
  styleUrls: ['./ordered-products-create-modal.component.scss'],
  providers: [
    DatePipe,
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class OrderedProductsCreateModalComponent implements OnInit {
  @Input() orderLine: OrderLine;
  public orderLines: OrderLine[] = [];

  public isLoading = false;
  public createOrderedProductsForm: FormGroup;
  public createReservedProductsForm: FormGroup;
  public shipperList: Shipper[];
  public stockRecordList: StockRecord[];
  public isProductOrderNotRequired = false;
  public shipperInput$ = new Subject<string>();
  public stockRecordInput$ = new Subject<string>();
  public selectLoading = false;
  public today = formatDate(new Date(), 'dd.MM.yyyy', 'en');
  public order: Order;
  public exchangeRates = undefined;

  constructor(public activeModal: NgbActiveModal,
              private calendar: NgbCalendar,
              private modalService: NgbModal,
              private formBuilder: FormBuilder,
              private dataProvider: DataProviderService,
              private orderService: OrderService,
              private notifierService: NotifyMessageService,
  ) {
  }

  public ngOnInit() {
    this.init();
  }

  public createProductOrder() {
    this.isLoading = true;
    const data = Object.assign({}, this.createOrderedProductsForm.value);
    const reserve_data = Object.assign({}, this.createReservedProductsForm.value);
    if (this.createOrderedProductsForm.valid) {
      data.expected_date = toApiDate(data.expected_date);
      this.dataProvider.createProductOrder(data).pipe(
        first(),
      )
        .subscribe(() => {
            this.createOrderedProductsForm.reset();
          }
        );
    }
    if (this.createReservedProductsForm.valid) {
      this.dataProvider.createReservedStockRecord(reserve_data['stock_record_id'], reserve_data).pipe(
        first(),
      )
        .subscribe(() => {
            this.createReservedProductsForm.reset();
          }
        );
    }
    this.isLoading = false;
    setTimeout(() => this.activeModal.close(), 2000);
  }

  public getShippers() {
    this.dataProvider.getShipperList()
      .pipe(
        first(),
        map(resp => {
          return resp.results;
        }))
      .subscribe(
        response => {
          this.shipperList = response;
        }
      );
  }

  public isProductOrderRequiredChange() {
    if (this.isProductOrderNotRequired) {
      this.createOrderedProductsForm.disable();
    } else {
      this.createOrderedProductsForm.enable();
    }
  }

  public changeProductOrderRequired() {
    this.isLoading = true;
    const data = {'is_product_order_required': !this.isProductOrderNotRequired};
    this.dataProvider.changeOrderLine(this.orderLine.id, data).pipe(
      first(),
      finalize(() => {
          this.isLoading = false;
        }
      ))
      .subscribe(() => {
          this.activeModal.close();
        }
      );
  }

  private init() {
    this.getShippers();
    this.getStockRecordList();
    this.getOrder();
    this.getRates();
    this.createOrderedProductsForm = this.formBuilder.group({
      order_line: [this.orderLine.id, Validators.required],
      shipper: [null, Validators.required],
      quantity: [
        this.orderLine.quantity - this.orderLine.reserved_quantity - this.orderLine.ordered_quantity - this.orderLine.shipped_quantity,
        Validators.required
      ],
      comment: [null],
      expected_date: [this.today, Validators.required],
    });
    this.createReservedProductsForm = this.formBuilder.group({
      stock_record_id: [null, Validators.required],
      order_line: [this.orderLine.id, Validators.required],
      quantity: [null, Validators.required],
    });
  }

  private getOrder() {
    this.orderService.getOrder(this.orderLine.order_id)
      .subscribe(
        response => {
          this.order = response;
        }
      );
  }

  public getStockRecordList() {
    this.isLoading = true;
    this.dataProvider.getStockRecordList(this.orderLine.product.id)
      .pipe(
        first(),
        map(resp => resp.map(
          (item: any) => {
            item.bind_label = `${item.address.name} (${item.quantity || 0}) (${item.country.code})`;
            return item;
          }
        )))
      .subscribe(
        response => {
          this.stockRecordList = response;
          this.isLoading = false;
        }
      );
  }

  public getOrderEstimate() {
    return this.orderLine.quantity - this.orderLine.reserved_quantity - this.orderLine.ordered_quantity -
      (Number(this.createReservedProductsForm.value.quantity) || 0) - (Number(this.createOrderedProductsForm.value.quantity) || 0) -
      this.orderLine.shipped_quantity;
  }

  public getOrderEstimateWithoutMinus() {
    const estimate = this.getOrderEstimate();
    if (estimate < 0) {
      return 0;
    } else {
      return estimate;
    }
  }

  public getRates() {
    const now = new Date()
    this.dataProvider.getExchangeRates({'request_date': now.toISOString().slice(0, 10)}).pipe(
      first())
      .subscribe(resp => {
        this.exchangeRates = resp;
      });
  }

  public getRecommendedPrice() {
    if (!this.order) {
      return `USD: - / EUR: - / CNY: -`
    }

    if (this.order.currency == "RUB") {
      if (!this.exchangeRates) {
      return `USD: - / EUR: - / CNY: -`
      }
      const usd = this.orderLine.price * 1.2 / this.exchangeRates['rates']['USD']['value'].toFixed(4)
      const eur = this.orderLine.price * 1.2 / this.exchangeRates['rates']['EUR']['value'].toFixed(4)
      const cny = this.orderLine.price * 1.2 / this.exchangeRates['rates']['CNY']['value'].toFixed(4)
      return `USD: ${usd.toFixed(4)}
      EUR: ${eur.toFixed(4)}
      CNY: ${cny.toFixed(4)}`
    }
    else if (this.order.currency == "USD") {
      return `USD ${this.orderLine.price * 1.2}`
    }
    else if (this.order.currency == "EUR") {
      return `EUR ${this.orderLine.price * 1.2}`
    }
  }

  public getOrderToStock() {
    const estimate = this.getOrderEstimate();
    if (estimate >= 0) {
      return 0;
    } else {
      return Math.abs(estimate);
    }
  }

  public isFormsValid() {
    const isReserveFormInvalid = (
      (Number(this.createReservedProductsForm.value.quantity) > 0) &&
      !this.createReservedProductsForm.valid) ||
      (Number(this.createReservedProductsForm.value.quantity) > this.orderLine.product.available_quantity);
    let isOrderFormInvalid = false;
    if (!this.createOrderedProductsForm.disabled) {
      isOrderFormInvalid = (Number(this.createOrderedProductsForm.value.quantity) > 0) && !this.createOrderedProductsForm.valid;
    }
    if (isReserveFormInvalid || isOrderFormInvalid) {
      return false;
    } else {
      return this.createReservedProductsForm.valid || this.createOrderedProductsForm.valid;
    }
  }

}
