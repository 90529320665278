import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManufacturersListComponent } from './manufacturers-list/manufacturers-list.component';
import { ManufacturersListRoutingModule } from './manufacturers-list-routing.module';
import { LoadingMessageModule } from '../../shared/loading-message/loading-message.module';
import { NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { ManufacturerCreateModalComponent } from './manufacturer-create-modal/manufacturer-create-modal.component';
import {
  ReactiveFormsModule
} from '@angular/forms';

const components = [
  ManufacturersListComponent,
  ManufacturerCreateModalComponent,
];

@NgModule({
  imports: [
    CommonModule,
    LoadingMessageModule,
    ManufacturersListRoutingModule,
    NgbPaginationModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ...components,
  ],
})
export class ManufacturersListModule {

}
