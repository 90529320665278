import {
  Component, Input,
  OnInit
} from '@angular/core';
import {NgbActiveModal, NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { DataProviderService } from '../../../services/data/data-provider.service';
import {finalize, first, map} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {ProductOrderDetail, Shipper} from '../../../models/sale';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, fromApiDate, I18n, toApiDate} from '../../../shared/datepicker';
import {DatePipe, formatDate} from '@angular/common';


@Component({
  selector: 'app-ordered-products-create-modal',
  templateUrl: './ordered-products-update-modal.component.html',
  styleUrls: ['./ordered-products-update-modal.component.scss'],
  providers: [
    DatePipe,
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class OrderedProductsUpdateModalComponent implements OnInit {
  @Input() productOrder: ProductOrderDetail;

  public isLoading = false;
  public updateOrderedProductsForm: FormGroup;
  public shipperList: Shipper[];
  public isProductOrderNotRequired = false;
  public shipperInput$ = new Subject<string>();
  public selectLoading = false;
  public today = formatDate(new Date(), 'dd.MM.yyyy', 'en');

  constructor(public activeModal: NgbActiveModal,
              private calendar: NgbCalendar,
              private formBuilder: FormBuilder,
              private dataProvider: DataProviderService,
              public formatter: NgbDateParserFormatter,
  ) {
  }

  public ngOnInit() {
    console.log(this.productOrder);
    this.init();
  }

  public updateProductOrder() {
    this.isLoading = true;
    const data = Object.assign({}, this.updateOrderedProductsForm.value);
    data.expected_date = toApiDate(data.expected_date);
    this.dataProvider.updateProductOrder(this.productOrder.id, data).pipe(
      first(),
      finalize(() => {
          this.isLoading = false;
        }
      ))
      .subscribe(() => {
          this.updateOrderedProductsForm.reset();
          this.activeModal.close();
        }
      );
  }

  public getShippers() {
    this.dataProvider.getShipperList()
      .pipe(
        first(),
        map(resp => {
            return resp.results;
          }))
      .subscribe(
        response => {
          this.shipperList = response;
        }
      );
  }

  private init() {
    this.getShippers();
    this.updateOrderedProductsForm = this.formBuilder.group({
      shipper: [this.productOrder.shipper.id, Validators.required],
      quantity: [this.productOrder.quantity, Validators.required],
      comment: [this.productOrder.comment, Validators.required],
      expected_date: [fromApiDate(this.productOrder.expected_date), Validators.required],
    });
  }

}
