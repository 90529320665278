<button (click)="goBack()" class="btn btn-primary">Назад</button>


<app-loading-message *ngIf="loading">
  Загрузка данных
</app-loading-message>

<div *ngIf="!loading"
     class="my-3 my-md-5 col-12 card">

  <div class="page-header">
    <h1 class="page-title">
      <span class="">{{ shipment.number }}</span>
    </h1>
    <div class="card-options d-flex">
      <button (click)="exportLetter()" type="button" class="btn btn-info mr-4" [ngClass]="{'sticky' : isSticky}">
        <i class="fe fe-plus-square"></i> Сформировать
      </button>
    </div>
  </div>

  <div class="card-header justify-content-between">
  </div>
  <div class="table-responsive">
    <table class="table card-table table-vcenter text-nowrap">
      <thead>
      <tr>
        <th>Наименование</th>
        <th>Количество</th>
        <th>Категория</th>
      </tr>
      </thead>
      <tbody>

      <ng-container *ngFor="let item of shipment.records">
        <tr class="alert-info">
          <td>{{item.product.name}}</td>
          <td>{{item.quantity}}</td>
          <td>{{item.product.category.name || defaultVal}}</td>
        </tr>

        <tr>
          <td colspan="5">
            <table>
              <tr>
                <th class="text-center" style="width: 1%; white-space: nowrap;">Номер счета</th>
                <th class="text-center" style="width: 1%; white-space: nowrap;">Количество</th>
                <th class="text-center" style="width: 1%; white-space: nowrap;">В резерве</th>
                <th class="text-center" style="width: 1%; white-space: nowrap;">Отгружено</th>
                <th class="text-center" style="width: 1%; white-space: nowrap;">Клиент</th>
              </tr>
              <tr *ngFor="let line of item.product.order_lines; index as i">
              <tr *ngFor="let line of item.product.order_lines; index as i" [ngClass]="{
                    'alert-success': line.is_selected_to_letter
                    }">
                <td>
                  <p class="font-w600 mb-1">{{ line.order.order_number }}</p>
                </td>
                <td>
                  <p class="font-w600 mb-1">{{ line.quantity || '-' }}</p>
                </td>
                <td>
                  <p class="font-w600 mb-1">{{ line.reserved_quantity || '-' }}</p>
                </td>
                <td>
                  <p class="font-w600 mb-1">{{ line.shipped_quantity || '-' }}</p>
                </td>
                <td>
                  <p class="font-w600 mb-1">{{ line.order.client }}</p>
                </td>
                <td>
                  <p title="{{ line.order.addition_info }}"
                    class="font-w600 mb-1"
                    style="max-width: 150px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                    {{ line.order.addition_info }}
                  </p>
                </td>
                <td>
                  <div class="form-check">
                    <label class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" checked=""
                             [(ngModel)]="line.is_selected_to_letter">
                      <span class="custom-control-label"></span>
                      <input *ngIf="line.is_selected_to_letter" type="text" class="form-control w-9"
                             [(ngModel)]="line.qty_to_letter" appOnlyNumber>
                    </label>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </ng-container>

      </tbody>
    </table>
  </div>
</div>
