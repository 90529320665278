import { Type } from '@angular/core';


export class Configuration {
  public title: string;
  public value?: string;
  public url?: string;
}


export abstract class ConfigurationBase<T> {
  constructor(
    public value: T,
  ) {
  }
}

type ConfigurationWithCreate<T extends typeof ConfigurationBase> = Type<T> & {
  create: (source: Configuration) => T;
};

export const configurationsConstructors = new Map<string, ConfigurationWithCreate<any>>();

function Register<T>(name: string) {
  return (target: ConfigurationWithCreate<any>) => {
    configurationsConstructors.set(name, target);
  };
}

class StringConfiguration extends ConfigurationBase<string> {
  public static create<T extends typeof StringConfiguration>(
    this: T,
    source: Configuration,
  ): InstanceType<T> {
    return new this(source.value) as InstanceType<T>;
  }
}

class FileConfiguration extends ConfigurationBase<string> {
  public static create<T extends typeof StringConfiguration>(
    this: T,
    source: Configuration,
  ): InstanceType<T> {
    return new this(source.url) as InstanceType<T>;
  }
}

class NumberConfiguration extends ConfigurationBase<number> {
  public static create<T extends typeof NumberConfiguration>(
    this: T,
    source: Configuration,
  ): InstanceType<T> {
    return new this(Number(source.value)) as InstanceType<T>;
  }
}

class BooleanConfiguration extends ConfigurationBase<boolean> {
  public static create<T extends typeof BooleanConfiguration>(
    this: T,
    source: Configuration,
  ): InstanceType<T> {
    /* Boolean(JSON.parse(source.value)) приводит '1' к `true`, а '0' к `false` */
    return new this(
      Boolean(
        typeof source.value === 'string' ?
          JSON.parse(source.value) :
          source.value,
      ),
    ) as InstanceType<T>;
  }
}

@Register('UI_LOGO')
export class LogoConfiguration extends FileConfiguration {
}
