import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoadingMessageModule} from '../../shared/loading-message/loading-message.module';
import {NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { OrderedProductsListComponent } from './ordered-products-list/ordered-products-list.component';
import {OrderedProductsListRoutingModule} from './ordered-products-list-routing.module';
import {OrderedProductsCreateModalComponent} from './ordered-products-create-modal/ordered-products-create-modal.component';
import {DirectivesModule} from '../../shared/directives/directives.module';
import {OrderedProductsFilterComponent} from './ordered-products-filter/ordered-products-filter.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {RequieredToOrderListComponent} from './requiered-to-order-list/requiered-to-order-list.component';
import {OrderedProductsCloseModalComponent} from './ordered-products-close-modal/ordered-products-close-modal.component';
import {OrderedProductsUpdateModalComponent} from './ordered-products-update-modal/ordered-products-update-modal.component';
import {ModalCloseProductOrderComponent} from './overdue-orders-list/modal-close-product-order/modal-close-product-order.component';
import {OverdueOrderListComponent} from './overdue-orders-list/overdue-order-list.component';
import {OrderedProductsDeleteModalComponent} from './ordered-products-delete-modal/ordered-products-delete-modal.component';

@NgModule({
  imports: [
    CommonModule,
    LoadingMessageModule,
    DirectivesModule,
    FormsModule,
    OrderedProductsListRoutingModule,
    NgbPaginationModule,
    NgbModule,
    NgSelectModule,
    ReactiveFormsModule,
  ],
  declarations: [
    OrderedProductsListComponent,
    OrderedProductsCreateModalComponent,
    OrderedProductsUpdateModalComponent,
    OrderedProductsFilterComponent,
    OverdueOrderListComponent,
    RequieredToOrderListComponent,
    OrderedProductsCloseModalComponent,
    OrderedProductsDeleteModalComponent,
    ModalCloseProductOrderComponent,
  ],
})
export class OrderedProductsListModule {
}
