<div class="modal-header">
  <h4 class="modal-title">Создание товара</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">x
  </button>
</div>
<div class="modal-body">

  <app-product-create [formConfig]="productFormConfig"
                      (OnCancel)="onProductCreateCancel($event)"
                      (OnProductCreate)="onProductCreate($event)"></app-product-create>
</div>

