<div class="col-12">
  <div class="card">
    <div class="card-header justify-content-between">
      <h3 class="card-title">Список платежей</h3>
      <div class="card-options">
        <button (click)="openUploadModal()" type="button" class="btn btn-outline-info mr-4">
          <i class="fe fe-plus-square"></i> Загрузить файл банка
        </button>
        <button (click)="openCreateModal()" type="button" class="btn btn-outline-info mr-4">
          <i class="fe fe-plus-square"></i> Добавить платеж
        </button>
      </div>
    </div>
    <div class="filters">
      <app-order-filter></app-order-filter>
    </div>
    <div class="table-responsive">
      <table class="table card-table table-vcenter text-nowrap table-hover">
        <thead>
        <tr>
          <th>Номер счета</th>
          <th>Номер п/п</th>
          <th>Дата п/п</th>
          <th>Сумма</th>
          <th>Сумма в рублях</th>
          <th>Валюта счета</th>
          <th>Курс</th>
          <th>Сумма счета</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngIf="isLoading">
          <td colspan="5">
            <app-loading-message>
              Получение списка платежей
            </app-loading-message>
          </td>
        </tr>
        <ng-container *ngIf="!isLoading">
          <ng-container *ngFor="let item of paymentsList; let i = index">
            <tr (click)="navigateOrderDetail(item.order)">
              <td>
                {{item.order_number}}
              </td>
              <td>
                {{item.number}}
              </td>
              <td>
                {{item.payment_date|date: 'dd.MM.yyyy'}}
              </td>
              <td>
                {{item.amount}}
              </td>
              <td>
                {{item.original_amount}}
              </td>
              <td>
                {{item.order_currency}}
              </td>
              <td>
                {{item.exchange_rate}}
              </td>
              <td>
                {{item.order_amount}}
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="!paymentsList.length">
            <td class="text-center">
              Список пуст
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!--<ngb-pagination class="d-flex justify-content-center"-->
<!--                [(page)]="paginationPage"-->
<!--                [pageSize]="pageSize"-->
<!--                [collectionSize]="collectionSize"-->
<!--                (pageChange)="updatePage($event)"-->
<!--                [maxSize]="5">-->
<!--</ngb-pagination>-->
