<form [formGroup]="filtersForm" action="">
  <div class="row">
    <div class="col-12 col-md-6 form-group">
      <div class="input-icon mb-3">
        <input formControlName="product_name" type="text" class="form-control" placeholder="Наименование товара">
        <span class="input-icon-addon"><i class="fe fe-search"></i></span>
      </div>
    </div>
    <div class="col-12 col-md-6 form-group">
      <div class="input-icon mb-3">
        <input formControlName="order_number" type="text" class="form-control" placeholder="Номер счета">
        <span class="input-icon-addon"><i class="fe fe-search"></i></span>
      </div>
    </div>
    <div class="col-12 col-md-6 form-group">
      <div class="input-icon mb-3">
        <input formControlName="client_legal_name" type="text" class="form-control" placeholder="Название контрагента">
        <span class="input-icon-addon"><i class="fe fe-search"></i></span>
      </div>
    </div>
    <div class="col-12 col-md-6 form-group">
      <div class="input-icon mb-3">
        <input formControlName="client_inn" type="text" class="form-control" placeholder="ИНН контрагента">
        <span class="input-icon-addon"><i class="fe fe-search"></i></span>
      </div>
    </div>

    <div class="col-12 col-md-6 form-group">
      <ng-container [formGroup]="filtersForm">
        <ng-select [items]="orderStatuses"
                   class="form-control custom-select"
                   [searchable]="true"
                   [clearable]="true"
                   bindLabel="title"
                   bindValue="code"
                   formControlName="status"
                   placeholder="Статус"
                   required>
        </ng-select>
      </ng-container>
    </div>

    <div class="form-group hidden">
      <div class="input-group">
        <input name="datepicker"
               class="form-control invisible"
               ngbDatepicker
               #datepicker="ngbDatepicker"
               [autoClose]="'outside'"
               [footerTemplate]="footerTemplate"
               (dateSelect)="onDateSelection($event)"
               [displayMonths]="2"
               [dayTemplate]="t"
               outsideDays="hidden"
               [startDate]="created_at_gte!"/>
        <ng-template #t let-date let-focused="focused">
          <span class="custom-day"
                [class.focused]="focused"
                [class.range]="isRange(date)"
                [class.faded]="isHovered(date) || isInside(date)"
                (mouseenter)="hoveredDate = date"
                (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
    <div class="col-12 col-md-3 form-group">
      <div class="input-group">
        <input formControlName="created_at_gte"
               class="form-control"
               placeholder="Дата создания от"
               #dpFromDate
               name="dpFromDate"
               [value]="formatter.format(created_at_gte)"
               (input)="created_at_gte = validateInput(created_at_gte, dpFromDate.value)"
               type="text">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar"
                  type="button"
                  (click)="datepicker.toggle()">
            <i class="fe fe-calendar"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3 form-group">
      <div class="input-group">
        <input formControlName="created_at_lte"
               class="form-control"
               placeholder="Дата создания до"
               #dpToDate
               name="dpToDate"
               [value]="formatter.format(created_at_lte)"
               (input)="created_at_lte = validateInput(created_at_lte, dpToDate.value)"
               type="text">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar"
                  type="button"
                  (click)="datepicker.toggle()">
            <i class="fe fe-calendar"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="form-group hidden">
      <div class="input-group">
        <input name="datepicker"
               class="form-control invisible"
               ngbDatepicker
               #updateDatepicker="ngbDatepicker"
               [autoClose]="'outside'"
               [footerTemplate]="footerTemplate"
               (dateSelect)="onUpdatedDateSelection($event)"
               [displayMonths]="2"
               [dayTemplate]="t1"
               outsideDays="hidden"
               [startDate]="updated_at_gte!"/>
        <ng-template #t1 let-date let-focused="focused">
          <span class="custom-day"
                [class.focused]="focused"
                [class.range]="isUpdatedRange(date)"
                [class.faded]="isUpdatedHovered(date) || isUpdatedInside(date)"
                (mouseenter)="hoveredUpdatedDate = date"
                (mouseleave)="hoveredUpdatedDate = null">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>

    <div class="col-12 col-md-3 form-group">
      <ng-container [formGroup]="filtersForm">
        <ng-select [items]="companies"
                   class="form-control custom-select"
                   [searchable]="true"
                   [clearable]="true"
                   bindLabel="name"
                   bindValue="id"
                   formControlName="company"
                   placeholder="Компания"
                   required>
        </ng-select>
      </ng-container>
    </div>

    <div class="col-12 col-md-3 form-group">
      <ng-container [formGroup]="filtersForm">
        <ng-select [items]="userList"
                   class="form-control custom-select"
                   [searchable]="true"
                   [clearable]="true"
                   bindLabel="username"
                   bindValue="username"
                   formControlName="creator"
                   placeholder="Менеджер"
                   required>
        </ng-select>
      </ng-container>
    </div>

    <div class="col-12 col-md-3 form-group">
      <div class="input-group">
        <input formControlName="updated_at_gte"
               class="form-control"
               placeholder="Дата обновления от"
               #dpFromUpdateDate
               name="dpFromUpdateDate"
               [value]="formatter.format(updated_at_gte)"
               (input)="updated_at_gte = validateInput(updated_at_gte, dpFromUpdateDate.value)"
               type="text">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar"
                  type="button"
                  (click)="updateDatepicker.toggle()">
            <i class="fe fe-calendar"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-3 offset form-group">
      <div class="input-group">
        <input formControlName="updated_at_lte"
               class="form-control"
               placeholder="Дата обновления до"
               #dpToUpdateDate
               name="dpToUpdateDate"
               [value]="formatter.format(updated_at_lte)"
               (input)="updated_at_lte = validateInput(updated_at_lte, dpToUpdateDate.value)"
               type="text">
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar"
                  type="button"
                  (click)="updateDatepicker.toggle()">
            <i class="fe fe-calendar"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="col-6 col-md-6 form-group">
      <div class="form-check">
        <input formControlName="with_issues"
               class="form-check-input"
               type="checkbox"
               value=""
               id="withIssuesCheckbox">
        <label class="form-check-label" for="withIssuesCheckbox">
          Только счета с проблемами
        </label>
      </div>
    </div>

    <div class="col-6">
      <button class="btn btn-primary btn-sm float-right m-0" (click)="clearFilter()">Очистить</button>
    </div>

  </div>
</form>

<ng-template #footerTemplate>
  <hr class="my-0">
  <!--  <button class="btn btn-primary btn-sm m-2 float-left" (click)="filtersForm.controls.payment_date_gte.setValue(today);filtersForm.controls.payment_date_lte.setValue(today);">Сегодня</button>-->
  <button class="btn btn-secondary btn-sm m-2 float-right" (click)="datepicker.close(); updateDatepicker.close()">
    Закрыть
  </button>
</ng-template>
