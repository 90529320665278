import { Component, OnDestroy, OnInit, Directive } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

import {FiltersService} from '../services/filters.service';
import {Filters} from '../../../lib/constants';

import {Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, share, tap} from 'rxjs/operators';

@Directive()
export abstract class FiltersComponentDirective implements OnInit, OnDestroy {

  public filtersForm: FormGroup;

  private _subscription: Subscription;

  protected constructor(
    private filtersService: FiltersService,
    public formBuilder: FormBuilder,
  ) {
    this._subscription = new Subscription();
    this.createForm();
  }

  ngOnInit() {
    this.initFilters();
    this.subscribeToChanges();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  getFiltersForm() {
    return new FormGroup({});
  }

  public clearFilter() {
    this.filtersForm.reset();
  }

  private initFilters(): void {
    const queryParams = this.filtersService.getQueryParameters();
    Object.keys(queryParams).forEach(param => {
      if (this.filtersForm.controls[param]) {
        this.filtersForm.controls[param].setValue(queryParams[param]);
      }
    });
    this.filtersService.filterParams.next(queryParams);
  }

  private setFilterParams(params) {
    const previousParams = this.filtersService.filterParams.value;

    Object.keys(params).forEach(paramKey => {
      if (previousParams[paramKey] || '' !== params[paramKey]) {
        this.filtersService.updateQueryParameters(
          Object.assign(params,
            {
              [Filters.PAGE]: '1'
            })
        );
        return;
      }
    });
  }

  private subscribeToChanges(): void {
    this._subscription.add(
      this.filtersForm.valueChanges.pipe(
        debounceTime(200),
        distinctUntilChanged(),
      ).subscribe(params => this.setFilterParams(params))
    );
  }

  createForm() {
    this.filtersForm = this.getFiltersForm();
  }

}
