import {Component, OnInit} from '@angular/core';
import {FiltersComponentDirective} from '../../../shared/filters/components/filters-component.directive';
import {DataProviderService} from '../../../services/data/data-provider.service';
import {FiltersService} from '../../../shared/filters/services/filters.service';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbDate, NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n} from '../../../shared/datepicker';
import {formatDate} from '@angular/common';
import {first} from 'rxjs/operators';
import {DictItem} from '../../../models/sale';

@Component({
  selector: 'app-order-filter',
  templateUrl: './payments-filter.component.html',
  styleUrls: ['./payments-filter.component.scss'],
  providers: [
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class PaymentsFilterComponent extends FiltersComponentDirective implements OnInit {
  public today = formatDate(new Date(), 'dd.MM.yyyy', 'en');
  public payment_date_gte: NgbDate;
  public payment_date_lte: NgbDate | null = null;
  public hoveredDate: NgbDate | null = null;
  public orderStatuses: DictItem[] = [];

  constructor(
    private dataProvider: DataProviderService,
    private stockFiltersService: FiltersService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    filtersService: FiltersService,
    formBuilder: FormBuilder,
  ) {
    super(
      filtersService,
      formBuilder,
    );
    this.payment_date_gte = calendar.getToday();
    this.payment_date_lte = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getStatuses();
  }

  private getStatuses() {
    this.dataProvider.getOrderStatuses().pipe(
      first(),
    )
      .subscribe(resp => {
          this.orderStatuses = resp;
        }
      );
  }

  getFiltersForm() {
    return this.formBuilder.group({
      order_number: ['', Validators.required],
      order_status: ['', Validators.required],
      payment_date_lte: ['', Validators.required],
      payment_date_gte: ['', Validators.required],
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.payment_date_gte && !this.payment_date_lte) {
      this.payment_date_gte = date;
    } else if (this.payment_date_gte && !this.payment_date_lte && date.after(this.payment_date_gte)) {
      this.payment_date_lte = date;
    } else if (this.payment_date_gte && !this.payment_date_lte && date.equals(this.payment_date_gte)) {
      this.payment_date_lte = this.payment_date_gte;
    } else {
      this.payment_date_lte = null;
      this.payment_date_gte = date;
    }
    this.filtersForm.controls.payment_date_gte.setValue(this.formatter.format(this.payment_date_gte));
    this.filtersForm.controls.payment_date_lte.setValue(this.formatter.format(this.payment_date_lte));
  }

  isHovered(date: NgbDate) {
    return this.payment_date_gte &&
      !this.payment_date_lte &&
      this.hoveredDate &&
      date.after(this.payment_date_gte) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.payment_date_lte && date.after(this.payment_date_gte) && date.before(this.payment_date_lte);
  }

  isRange(date: NgbDate) {
    return date.equals(this.payment_date_gte) ||
      (this.payment_date_lte && date.equals(this.payment_date_lte)) ||
      this.isInside(date) ||
      this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

}
