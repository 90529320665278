import {Component, Input, OnInit} from '@angular/core';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, finalize, first, map, switchMap, tap} from 'rxjs/operators';
import {DataProviderService} from '../../../../services/data/data-provider.service';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Order, OrderLine} from '../../../../models/sale';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NotifyMessageService} from '../../../../shared/notify-message/notify-message.service';
import {AuthService} from '../../../../services/auth/auth.service';
import {Payment} from '../../../../models/types';

@Component({
  selector: 'app-modal-shipping',
  templateUrl: './modal-delete-payment.component.html',
  styleUrls: ['./modal-delete-payment.component.scss']
})
export class ModalDeletePaymentComponent implements OnInit {
  @Input() payment: Payment;
  @Input() order: Order;

  loading = false;
  error = '';

  constructor(
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,

    private authService: AuthService,
    private notifierService: NotifyMessageService,
    private dataProvider: DataProviderService,
    ) { }

  ngOnInit() {
  }

  public deletePayment(): void {
    this.loading = true;

    this.dataProvider.deletePayment(this.order.id, {id: this.payment.id}).pipe(
      first(),
      finalize(() => this.loading = false),
    )
      .subscribe(
        res => {
          this.activeModal.close();
        },
        err => {
          this.error = 'Ошибка удаления'; console.log(err);
          this.notifierService.notify('Ошибка удаления', 'danger');
        },
      );
  }


}
