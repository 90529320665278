import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {DataProviderService} from '../../../../services/data/data-provider.service';
import {FiltersService} from '../../../../shared/filters/services/filters.service';
import {FiltersComponentDirective} from '../../../../shared/filters/components/filters-component.directive';

@Component({
  selector: 'app-product-filter',
  templateUrl: './product-filter.component.html',
  styleUrls: ['./product-filter.component.scss']
})
export class ProductFilterComponent extends FiltersComponentDirective {
  loading = false;

  constructor(
    private dataProvider: DataProviderService,
    private stockFiltersService: FiltersService,
    filtersService: FiltersService,
    formBuilder: FormBuilder,
  ) {
    super(
      filtersService,
      formBuilder,
    );
  }

  getFiltersForm() {
    return this.formBuilder.group({
      clean_name: ['', Validators.required],
      manufacturer: ['', Validators.required],
      id: ['', Validators.required],
    });
  }


}
