import {Component, HostListener, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {formatDate, Location} from '@angular/common';
import {catchError, debounceTime, distinctUntilChanged, finalize, first, map, switchMap, tap} from 'rxjs/operators';

import {DataProviderService} from '../../../services/data/data-provider.service';
import {Client, DictItem, Order, OrderLine} from '../../../models/sale';
import {NotifyMessageService} from '../../../shared/notify-message/notify-message.service';
import {NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n, NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {ModalShipmentComponent} from './modal-shipment/modal-shipment.component';
import {AuthService} from '../../../services/auth/auth.service';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {AddProductModalComponent} from '../add-product-modal/add-product-modal.component';
import {Constants, OrderStatus} from '../../../lib/constants';
import {CalculatedOrder, SaleService} from '../../../services/sale/sale.service';
import {concat, Observable, of, Subject} from 'rxjs';
import {ProductService} from '../../stock/product/product.service';
import {Currency, Payment, PaymentTypes, Unit} from '../../../models/types';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n, toApiDate} from '../../../shared/datepicker';
import {ModalDeletePaymentComponent} from './modal-delete-payment/modal-delete-payment.component';
import {OrderedProductsCreateModalComponent} from '../../../modules/ordered-products-list/ordered-products-create-modal/ordered-products-create-modal.component';
import {PaymentType} from '../../../models/enums';
import {ModalIssuesCreateComponent} from './modal-issues-create/modal-issues-create.component';
import {ModalRecalculateComponent} from './modal-recalculate/modal-recalculate.component';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class OrderDetailComponent implements OnInit {
  client: Client;
  clientList$: Observable<Client[]>;
  clientInput$ = new Subject<string>();
  selectLoading = false;
  public infoIsCollapsed = true;
  public productOrdersIsCollapsed = true;
  public paymentsIsCollapsed = true;
  public currencyTypes$: Observable<Currency[]>;
  public paymentTypes$: Observable<PaymentTypes[]>;
  public paymentsList: Payment[];
  public orderStatuses: DictItem[] = [];
  public exchangeRates = undefined;
  public today = formatDate(new Date(), 'dd.MM.yyyy', 'en');
  public orderStatus = OrderStatus;
  public paymentType = PaymentType;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private calendar: NgbCalendar,
    private location: Location,
    private modalService: NgbModal,
    private modalConfig: NgbModalConfig,
    private authService: AuthService,
    private dataProvider: DataProviderService,
    private notifierService: NotifyMessageService,
    public productService: ProductService,
    public saleService: SaleService,
  ) {
    modalConfig.backdrop = 'static';
  }


  public changeOrderForm: FormGroup;
  public createPaymentForm: FormGroup;
  order: Order;
  orderLines: OrderLine[];
  calculatedOrder = new CalculatedOrder(0, 0, 0);

  isChangeMode = false;
  isOrderChanged = false;
  loading = true;
  error = '';

  public isSelectToSnipmentActionVisible(item: OrderLine): boolean {
    const quantity = item.product.quantity || 0;
    const reserved_quantity = item.reserved_quantity || 0;
    return this.authService.groupMatch(['CRM_ADMIN']) && (quantity !== 0 || reserved_quantity !== 0);
  }

  public isSnipmentActionVisible(item: OrderLine): boolean {
    const quantity = item.product.quantity || 0;
    const reserved_quantity = item.reserved_quantity || 0;
    return this.authService.groupMatch(['STOCK_ADMIN']) && (quantity !== 0 || reserved_quantity !== 0);
  }

  ngOnInit() {
    this.route.params.subscribe(() => this.initOrder());
    this.getClients();
    this.getPayments();
    this.productService.getProducts();
    this.currencyTypes$ = this.saleService.getCurrencyTypes();
    this.paymentTypes$ = this.saleService.getPaymentTypes();
  }

  public hasUnsavedData() {
    return !!this.isOrderChanged;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.hasUnsavedData()) {
      $event.returnValue = 'На странице присутствуют несохраненные данные, покинуть страницу?';
    }
  }

  public openAddProductModal() {
    const modalRef = this.modalService.open(AddProductModalComponent);
    modalRef.result.then((newProducts) => {
      newProducts = newProducts.filter(product => {
        const isInList = this.orderLines.find(item => item.product.id === product.id);
        return product && product.id && !isInList;
      });

      newProducts.forEach(item => {
        const newLine: OrderLine = {
          product: item,
          quantity: 0,
          price: 0.00,
          is_new: true,
          shipped_quantity: 0,
        } as unknown as OrderLine;

        this.orderLines.push(newLine);
        this.isOrderChanged = true;
      });
    }, () => {
    });
  }

  public openClientModal(content) {
    this.modalService.open(content).result.then((result) => {
      this.changeOrderForm.addControl('client', new FormControl(result.id, Validators.required));
      this.isOrderChanged = true;
    }, (reason) => {
    });
  }

  public openCreateOrderModal(orderLine: OrderLine): void {
    const modalRef = this.modalService.open(OrderedProductsCreateModalComponent);
    modalRef.componentInstance.orderLine = orderLine;
    modalRef.result.then((result) => {
      this.getOrder(this.route.snapshot.paramMap.get('id'));
    }, (reason) => {
      console.log(reason);
    });
  }

  public openPaymentModal(content) {
    this.createPaymentForm = this.formBuilder.group({
      order: [this.route.snapshot.paramMap.get('id'), Validators.required],
      number: [null, Validators.required],
      amount: [null, Validators.required],
      payment_date: [this.today, Validators.required],
      payment_type: [PaymentType.DEFAULT, Validators.required],
      exchange_rate: [0],
    });
    this.getRates();

    this.modalService.open(content).result.then((result) => {
      this.createPayment();
    }, (reason) => {
    });

  }

  public getClients() {
    this.clientList$ = concat(
      of([]), // default items
      this.clientInput$.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => this.selectLoading = true),
        switchMap(term => this.dataProvider.getClients({'legal_name': term}).pipe(
          map(res => res.map(
            (item: any) => {
              item.clientInfo = `${item.legal_name} (${item.city} ИНН: ${item.INN})`;
              return item;
            }
          )),
          catchError(() => of([])), // empty list on error
          tap(() => this.selectLoading = false)
        ))
      )
    );
  }

  private getOrder(id, params?): void {
    this.loading = true;

    this.dataProvider.getOrder(id, params).pipe(
      map(order => {
          order.lines.forEach(line => {
            line.readOnly = line.shipped_quantity > 0;
            line.editable = false;
            line.cost = Number((line.price * line.quantity).toFixed(2));
          });
          return order;
        }
      ))
      .subscribe(
        order => {
          this.order = order;
          this.orderLines = order.lines;
          this.getProductOrders(this.orderLines);
          this.client = this.order.client;
          this.changeOrderForm.controls['addition_info'].setValue(this.order.addition_info);
          this.changeOrderForm.controls['currency'].setValue(this.order.currency);
          if (!this.isOrderEditable()) {
            this.changeOrderForm.controls['addition_info'].disable();
          }
          this.client.clientInfo = `${this.client.legal_name} (${this.client.city} ИНН: ${this.client.INN})`;
          this.calculatedOrder = new CalculatedOrder(this.order.sub_total, this.order.total, this.order.vat_total);

          // Показываем примечание, если оно не пустое
          if (this.order.addition_info) {
            this.infoIsCollapsed = false;
          }

          this.loading = false;
        }
      );
  }

  public selectToShipment(orderLines) {
    const data = {
      'lines': orderLines.filter(line => line.is_selected_to_shipment).map(line => line.id),
    };

    this.loading = true;

    this.dataProvider.selectShipment(this.order.id, data).pipe(
      finalize(() => this.loading = false),
    )
      .subscribe(
        order => {
          this.notifierService.notify('Товары отмечены к отгрузке', 'success');
        },
        err => {
          console.log(err);
          this.notifierService.notify('Произошла ошибка', 'danger');
        },
      );
  }

  public changeStatus(status) {
    const data = {
      'status': status
    };

    this.dataProvider.changeOrderStatus(this.order.id, data).pipe(
      finalize(() => {
        this.loading = false;
        this.isChangeMode = false;
        this.initOrder();
      }),
    )
      .subscribe(
        order => {
          this.notifierService.notify('Статус счета изменен', 'success', 4000);
        },
        err => {
          console.log(err);
          this.notifierService.notify('Произошла ошибка при смене статуса', 'danger', 4000);
        },
      );
  }

  public copyOrder() {

    this.dataProvider.copyOrder(this.order.id)
      .subscribe(
        order => {
          this.router.navigate(['/sale', 'order', order.id]);
          this.notifierService.notify('Счет скопирован', 'success', 4000);
        },
        err => {
          console.log(err);
          this.notifierService.notify('Ошибка копирования счета', 'danger', 4000);
        },
      );
  }

  public shipmentModalOpen(orderLine) {
    const modalRef = this.modalService.open(ModalShipmentComponent);
    modalRef.componentInstance.orderLine = orderLine;
    modalRef.componentInstance.order = this.order;
    modalRef.result.then(result => {
    }, () => {
      console.log('Backdrop click');
    });
  }

  public openCreateIssueModal() {
    const modalRef = this.modalService.open(ModalIssuesCreateComponent);
    modalRef.componentInstance.order = this.order;
    modalRef.result.then(result => {
    }, () => {
      console.log('Backdrop click');
    });
  }

  public openRecalculateModal() {
    const modalRef = this.modalService.open(ModalRecalculateComponent);
    modalRef.componentInstance.order = this.order;
    modalRef.result.then(result => {
      this.getOrder(this.route.snapshot.paramMap.get('id'));
    }, () => {
      console.log('Backdrop click');
    });
  }

  public deletePaymentModalOpen(payment) {
    const modalRef = this.modalService.open(ModalDeletePaymentComponent);
    modalRef.componentInstance.payment = payment;
    modalRef.componentInstance.order = this.order;
    modalRef.result.then(result => {
      this.getPayments();
    }, () => {
      console.log('Backdrop click');
    });
  }

  public orderChange(line) {
    this.isOrderChanged = true;
    line.price = Number(line.price).toFixed(2);
    line.cost = Number((line.price * line.quantity).toFixed(2));
    this.calculatedOrder = this.saleService.calculate(this.orderLines);
  }

  public isLineRemoveAvaliable(line: OrderLine): boolean {
    return line.editable && this.order.status === OrderStatus.CREATED;
  }

  public isOrderEditable(): boolean {
    return this.order.status === OrderStatus.CREATED;
  }

  public isLineFieldsEditable(line: OrderLine): boolean {
    return line.is_new || line.editable;
  }

  public isLineChangeProductEditable(line: OrderLine): boolean {
    return !!line.productEditable;
  }

  public toggleLineChangeProductEditable(line: OrderLine): boolean {
    return line.productEditable = !line.productEditable;
  }

  public toggleIncludeOfertaParameter(event) {
    this.isOrderChanged = true;
    if (event.target.checked) {
      this.changeOrderForm.controls['parameters'].setValue({});
      return;
    } else {
      this.changeOrderForm.controls['parameters'].setValue({'include_oferta': false});
    }
  }

  public quantityInc(product) {
    product.quantity++;
    this.isOrderChanged = true;
    this.orderChange(product);
  }

  public quantityDec(product) {
    product.quantity--;
    this.isOrderChanged = true;
    this.orderChange(product);
  }

  public allowChangeOrder() {
    this.isChangeMode = !this.isChangeMode;
    this.orderLines.filter((item: any) => !item.readOnly).forEach(line => {
      const isShipped = line.is_shipped || line.shipped_quantity !== 0 || line.is_selected_to_shipment;
      line.editable = this.isChangeMode && !isShipped;
      if (!this.isChangeMode) {
        line.removed = false;
        line.productEditable = false;
      }
    });
  }

  public getRates() {
    this.dataProvider.getExchangeRates().pipe(
      first())
      .subscribe(resp => {
        this.exchangeRates = resp;
        this.setExchangeRate();
      });
  }

  public getProductOrders(orderLines: OrderLine[]) {
    orderLines.forEach(item => {
      this.dataProvider.getProductOrders(item.product.id).pipe(
        first())
        .subscribe(resp => {
          item.overallProductOrders = resp;
        });
    });
  }

  public setExchangeRate() {
    const currency = this.order.currency;
    const payment_date = this.createPaymentForm.get('payment_date').value;
    if (!currency || !payment_date) {
      return;
    }
    this.dataProvider.getExchangeRates({'request_date': toApiDate(payment_date)}).pipe(
      first()).subscribe(resp => {
        this.createPaymentForm.controls['exchange_rate'].setValue(resp['rates'][currency]['value'].toFixed(4));
      }
    );
  }

  public getPayments() {
    this.dataProvider.getOrderPayments(this.route.snapshot.paramMap.get('id'))
      .pipe(
        first(),
      )
      .subscribe(
        response => {
          this.paymentsList = response;
        }
      );
  }

  public createPayment() {
    const data = this.createPaymentForm.value;
    data.payment_date = toApiDate(data.payment_date);
    this.dataProvider.createPayment(this.order.id, data).pipe(
      finalize(() => {
          this.createPaymentForm.reset();
          this.getPayments();
        }
      ))
      .subscribe(
        order => {
          this.notifierService.notify('Платеж успешно изменен', 'success', 4000);
        },
        err => {
          this.error = 'Ошибка создания';
          this.notifierService.notify('Ошибка добавления платежа', 'danger', 4000);
        },
      );
  }

  public removeProductFromOrder(line) {
    this.isOrderChanged = true;
    line.removed = !line.removed;
  }

  public saveOrder(): void {
    this.loading = true;

    const control = <FormArray>this.changeOrderForm.controls.lines;

    this.orderLines.forEach(item => {
      control.push(this.formBuilder.group({
        id: item.id,
        product: item.product.id,
        price: item.price,
        quantity: item.quantity,
        comment: item.comment,
        removed: item.removed || false,
        is_new: item.is_new || false
      }));
    });

    this.dataProvider.changeOrder(this.order.id, this.changeOrderForm.value).pipe(
      finalize(() => {
          this.loading = false;
          this.changeOrderForm.reset();
          this.initOrder();
        }
      ))
      .subscribe(
        order => {
          this.notifierService.notify('Счет успешно изменен', 'success', 4000);
        },
        err => {
          this.error = 'Ошибка создания';
          this.notifierService.notify('Ошибка изменения счета', 'danger', 4000);
        },
      );
  }

  public removeReservation(item: OrderLine): void {
    this.loading = true;

    this.dataProvider.orderLineRemoveReservation(item.id).pipe(
      first(),
      finalize(() => {
          this.loading = false;
          this.initOrder();
        }
      ))
      .subscribe(
        order => {
          this.notifierService.notify('Резерв успешнео удален', 'success', 4000);
        },
        err => {
          this.error = 'Ошибка создания';
          this.notifierService.notify('Ошибка удаления резерва', 'danger', 4000);
        },
      );
  }

  public getTotalReversingPayments() {
    if (!this.paymentsList) {
      return 0;
    }
    return this.paymentsList.filter(
      payment => payment.payment_type === PaymentType.REVERSING
    ).map(p => p['amount']).reduce((acc, value) => acc + Number(value), 0);
  }

  public getTotalPayments() {
    if (!this.paymentsList) {
      return 0;
    }
    const totalPayments = this.paymentsList.filter(
      payment => payment.payment_type !== PaymentType.REVERSING
    ).map(p => p['amount']).reduce((acc, value) => acc + Number(value), 0);
    return totalPayments - this.getTotalReversingPayments();
  }

  public getTotalOrdered(item: OrderLine) {
    if (!item.product_orders) {
      return 0;
    }
    return item.product_orders.map(p => p['quantity']).reduce((acc, value) => acc + Number(value), 0);
  }

  public leftToPay() {
    const canceledLinesTotal = this.orderLines.filter(
      line => line.is_canceled === true
    ).map(
      line => line.cost + Number((line.price * line.quantity / 100 * Constants.VAT_CONSTANT).toFixed(2))
    ).reduce(
      (acc, value) => acc + Number(value), 0
    );
    return this.order.total - canceledLinesTotal - this.getTotalPayments();
  }

  public getReversingTotalPayments() {
    return this.paymentsList.filter(
      payment => payment.payment_type === PaymentType.REVERSING
    ).map(p => p['amount']).reduce((acc, value) => acc + Number(value), 0);
  }

  public goBack(): void {
    this.location.back();
  }

  public goToIssues(): void {
    this.router.navigate(['issues'], {
        queryParams: {
          order_number: this.order.order_number
        }
      }
    );
  }

  private getStatuses() {
    this.dataProvider.getOrderStatuses().pipe(
      first(),
    )
      .subscribe(resp => {
          this.orderStatuses = resp;
        }
      );
  }

  private initOrder() {
    this.isChangeMode = false;
    this.isOrderChanged = false;
    this.changeOrderForm = this.formBuilder.group({
      lines: this.formBuilder.array([]),
      addition_info: [''],
      currency: ['', Validators.required],
      parameters: [{}],
    });
    this.getOrder(this.route.snapshot.paramMap.get('id'));
    this.getStatuses();
  }
}
