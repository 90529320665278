<div class="col-12">
  <div class="card">
    <div class="table-responsive">
      <div class="row">

        <div class="col-12">
          <h3 class="card-title text-center mt-1 mb-1">Позиции для заказа</h3>
          <table class="table card-table table-vcenter text-nowrap table-hover">
            <thead>
            <tr>
              <th>Наименование товара</th>
              <th>Коментарий</th>
              <th>В счете</th>
              <th>На складе (в пути)</th>
              <th>Заказано по счету</th>
              <th>Заказано всего</th>
              <th>Резерв</th>
              <th>Отгружено</th>
              <th>К заказу</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngIf="isLoading">
              <td colspan="5">
                <app-loading-message>
                  Получение списка позиций
                </app-loading-message>
              </td>
            </tr>
            <ng-container *ngIf="!isLoading">
              <ng-container *ngFor="let item of orderLines; let i = index">
                <tr [ngClass]="{
                  'alert-info': item.is_duplicated
                }">
                  <td (click)="navigateOrderDetail(item.order_id)">
                    {{item.product.name}}
                  </td>
                  <td>
                    {{item.comment}}
                  </td>
                  <td>
                    {{item.quantity}}
                  </td>
                  <td>
                    {{item.product.available_quantity}} ({{item.product.transit_quantity}}) / {{item.product.external_quantity}}
                  </td>
                  <td>
                    {{item.ordered_quantity}}
                  </td>
                  <td>
                    {{item.total_ordered_quantity}}
                  </td>
                  <td>
                    {{item.reserved_quantity}}
                  </td>
                  <td>
                    {{item.shipped_quantity}}
                  </td>
                  <td>
                    {{item.quantity - item.reserved_quantity - item.ordered_quantity - item.shipped_quantity}}
                  </td>
                  <td>
                    <button class="btn btn-outline-primary"
                            type="button"
                            (click)="openCreateModal(item)">
                      <i class="fe fe-plus-square"></i>
                    </button>
                  </td>
                </tr>
              </ng-container>
              <tr *ngIf="!orderLines.length">
                <td class="text-center">
                  Список пуст
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>
</div>
