import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IssuesListComponent } from './issues-list/issues-list.component';
import { IssuesRoutingModule } from './issues-routing.module';
import { LoadingMessageModule } from '../../shared/loading-message/loading-message.module';
import {NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import { IssuesCreateModalComponent } from './issues-create-modal/issues-create-modal.component';
import {
  ReactiveFormsModule
} from '@angular/forms';
import {NgSelectModule} from '@ng-select/ng-select';
import {IssuesUpdateModalComponent} from './issues-update-modal/issues-update-modal.component';
import {IssuesFilterComponent} from './issues-filter/issues-filter.component';

const components = [
  IssuesListComponent,
  IssuesCreateModalComponent,
  IssuesUpdateModalComponent,
  IssuesFilterComponent,
];

@NgModule({
  imports: [
    CommonModule,
    LoadingMessageModule,
    IssuesRoutingModule,
    NgbPaginationModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgbModule,
  ],
  declarations: [
    ...components,
  ],
})
export class IssuesModule {

}
