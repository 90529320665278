import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, finalize, first, map, switchMap, tap} from 'rxjs/operators';

import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';

import {DataProviderService} from '../../../services/data/data-provider.service';
import {DictItem} from '../../../models/stock';
import {Client, DeliveryTime, OrderLine} from '../../../models/sale';
import {NotifyMessageService} from '../../../shared/notify-message/notify-message.service';
import {AddProductModalComponent} from '../add-product-modal/add-product-modal.component';
import {Unit} from '../../../models/types';
import {CalculatedOrder, SaleService} from '../../../services/sale/sale.service';
import {ProductService} from '../../stock/product/product.service';
import {ClientsService} from '../clients.service';
import {CompanyService} from '@app/components/sale/company.service';
import {Company} from '@app/models/types/company.interface';

@Component({
  selector: 'app-order-create',
  templateUrl: './order-create.component.html',
  styleUrls: ['./order-create.component.scss']
})
export class OrderCreateComponent implements OnInit {
  // clients$: Observable<ProductList[]>;
  createOrderForm: FormGroup;
  error = '';
  selectLoading = false;
  loading = false;
  infoIsCollapsed = true;
  clientInput$ = new Subject<string>();
  defaultOrderInfo: string;
  orderProducts: any[] = <any>[];
  client: Client;
  chosenCompany: Company;
  category: DictItem;
  public clientList: Client[];
  public companies: Company[];
  public deliveryTimeList: DeliveryTime[];
  public selectedDeliveryTime: DeliveryTime;
  public customDeliveryTime: string;
  calculatedOrder = new CalculatedOrder(0, 0, 0);
  currencyTypes = [
    {'title': 'Рубли', 'code': 'RUB'},
    {'title': 'Доллары', 'code': 'USD'},
    {'title': 'Евро', 'code': 'EUR'},
  ];
  unitTypes: Unit[];

  constructor(
    private formBuilder: FormBuilder,
    private cd: ChangeDetectorRef,

    public productService: ProductService,
    public clientsService: ClientsService,
    public companyService: CompanyService,
    public saleService: SaleService,
    private dataProvider: DataProviderService,
    private notifierService: NotifyMessageService,

    private modalService: NgbModal,
    private modalConfig: NgbModalConfig,
  ) {
    modalConfig.backdrop = 'static';
  }

  ngOnInit() {
    this.preloadClients();
    this.preloadCompanies();
    this.preloadDeliveryTime();
    this.loadDefaultOrderInfo();

    this.createOrderForm = this.formBuilder.group({
      lines: this.formBuilder.array([]),
      client: [null, Validators.required],
      company: [null, Validators.required],
      currency: [this.currencyTypes[0]['code'], Validators.required],
      addition_info: [''],
      parameters: [{}],
    });

    this.getUnitTypes();
  }

  public hasUnsavedData() {
    return !!this.orderProducts.length;
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (this.hasUnsavedData()) {
      $event.returnValue = 'На странице присутствуют несохраненные данные, покинуть страницу?';
    }
  }

  get f() {
    return this.createOrderForm.controls;
  }

  public openAddProductModal() {
    const modalRef = this.modalService.open(AddProductModalComponent);
    modalRef.result.then((newProducts) => {
      const existingProducts = newProducts.filter(product => {
        const isInList = this.orderProducts.find(item => item.product.id === product.id);
        return product && product.id && isInList;
      });
      newProducts = newProducts.filter(product => {
        const isInList = this.orderProducts.find(item => item.product.id === product.id);
        return product && product.id && !isInList;
      });
      newProducts.forEach(item => {
        const newLine: OrderLine = {
          product: item,
          quantity: 0,
          price: 0.00,
          is_new: true,
          comment: null,
          shipped_quantity: 0,
        } as OrderLine;

        this.orderProducts.push(newLine);
      });

      if (existingProducts.length > 0) {
        this.notifierService.notify(`Следующие товары уже в счете: ${existingProducts.map(e => e.name).join()} `, 'warning');
      }
    }, () => {
    });
  }

  public changeOrderLine(orderLine: OrderLine) {
    orderLine.productEditable = false;
  }

  public isLineChangeProductEditable(line: OrderLine): boolean {
    return !!line.productEditable;
  }

  public toggleLineChangeProductEditable(line: OrderLine): boolean {
    return line.productEditable = !line.productEditable;
  }

  public getUnitTitle(unitCode) {
    const foundUnit = this.unitTypes.find(
      unit => unit.code && unit.code.toUpperCase() === unitCode.toUpperCase(),
    );
    return foundUnit ? foundUnit.name : '';
  }

  public onClientCreateCancel(modal) {
    modal.dismiss();
  }

  public onClientCreate(client: any, modal: any) {
    this.client = client;
    modal.dismiss();
  }

  public removeProductFromOrder(index) {
    this.orderProducts.splice(index, 1);
    this.calculatedOrder = this.saleService.calculate(this.orderProducts);
  }

  private preloadClients() {
    this.clientsService.getClients()
      .subscribe(
        response => {
          this.clientList = response;
        }
      );
  }

  private preloadCompanies() {
    this.companyService.getCompanies()
      .subscribe(
        response => {
          this.companies = response;
          const defaultCompany = response.find((c) => c.is_default === true);
          if (defaultCompany) {
            this.createOrderForm.controls['company'].setValue(defaultCompany.id);
          }
        }
      );
  }

  private preloadDeliveryTime() {
    this.dataProvider.getDeliveryTimeList()
      .pipe(
        first(),
      )
      .subscribe(
        response => {
          this.deliveryTimeList = response;
          this.selectedDeliveryTime = response.find(
            item => item.code === 'STANDARD',
          );
        }
      );
  }

  public isCustomDeliveryTimeVisible(): boolean {
    return this.selectedDeliveryTime && this.selectedDeliveryTime.code === 'PRODUCTION';
  }

  public createOrder(): void {
    this.loading = true;
    const control = <FormArray>this.createOrderForm.controls.lines;

    this.orderProducts.forEach(item => {
      control.push(this.formBuilder.group({
        product: item.product.id,
        price: item.price,
        comment: item.comment || null,
        quantity: item.quantity
      }));
    });

    this.f['addition_info'].setValue(this.getAdditionInfo());

    this.dataProvider.createOrder(this.createOrderForm.value).pipe(
      finalize(() => {
        control.clear();
        this.loading = false;
      }),
    )
      .subscribe(
        order => {
          this.orderProducts = [];
          this.notifierService.notify('Счет успешно создан', 'success');
        },
        err => {
          this.error = 'Ошибка создания';
          console.log(err);
          this.notifierService.notify('Ошибка создания счета', 'danger');
        },
      );
  }

  private getAdditionInfo() {
    if (this.selectedDeliveryTime.code === 'PRODUCTION') {
      return `${this.selectedDeliveryTime.description.replace(
        '{{delivery_time}}', this.customDeliveryTime
      )}. ${this.f['addition_info'].value}`;
    } else {
      return `${this.selectedDeliveryTime.description}. ${this.f['addition_info'].value}`;
    }
  }

  public orderChange(product) {
    product.price = Number(product.price).toFixed(2);
    product.cost = Number((product.price * product.quantity).toFixed(2));
    this.calculatedOrder = this.saleService.calculate(this.orderProducts);
  }

  public quantityInc(product) {
    product.quantity++;
    this.orderChange(product);
  }

  public quantityDec(product) {
    product.quantity--;
    this.orderChange(product);
  }

  public toggleIncludeOfertaParameter(event) {
    if (event.target.checked) {
      this.createOrderForm.controls['parameters'].setValue({});
      return;
    } else {
      this.createOrderForm.controls['parameters'].setValue({'include_oferta': false});
    }
  }

  modalClientOpen(content) {
    this.modalService.open(content).result.then((result) => {
      this.createOrderForm.controls['client'].setValue(result.id);
    }, () => {
    });
  }

  private getUnitTypes() {
    this.dataProvider.getUnitTypes().subscribe(
      (result) => {
        this.unitTypes = result;
      }
    );
  }

  private loadDefaultOrderInfo() {
    this.dataProvider.getGeneralPreferences()
      .pipe(
        first(),
      )
      .subscribe(
        response => {
          this.defaultOrderInfo = response.find(x => x['title'] === 'ORDER_DEFAULT_INFO')['value'];
          this.f['addition_info'].setValue(this.defaultOrderInfo);
        }
      );
  }

}
