export abstract class PaginatorPage<T> {
  private static MAX_RESOLVE_ERRORS = 5;

  public count: number;
  public next: string;
  public previous: string;

  public abstract results: Array<T>;

  /**
   * @param {(page: number) => Promise<PaginatorPage<T>>} pageResolver
   * @returns {Promise<Array<T>>}
   */
  public static async resolveAllPages<T>(
    pageResolver: (page: number) => Promise<PaginatorPage<T>>,
  ): Promise<Array<T>> {
    const results = [];
    let totalCount: number = undefined;
    let pageIndex: number = 1;
    let errorsCount: number = 0;
    do {
      const page = await pageResolver(pageIndex);
      if (!page) {
        errorsCount++;
        continue;
      }
      if (!(totalCount !== undefined)) {
        totalCount = page.count;
      }
      results.push(...page.results);
      pageIndex++;
    } while (results.length < totalCount && errorsCount < PaginatorPage.MAX_RESOLVE_ERRORS);
    return results;
  }
}
