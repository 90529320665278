<div class="modal-header">
  <h4 class="modal-title">Добавление товара</h4>
  <button type="button"
          class="close"
          aria-label="Close"
          (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="productForm"
        class="products-form">
      <ng-select [items]="productService.products$ | async"
                 autofocus
                 placeholder="Товар"
                 bindLabel="name"
                 [addTag]="true"
                 addTagText="Добавить"
                 [multiple]="true"
                 [hideSelected]="true"
                 [loading]="productService.selectLoading"
                 [typeahead]="productService.searchQuery$"
                 formControlName="selectedProducts"
                 (clear)="clearCreateProductForm()"
                 (add)="addNewTag($event)">
      </ng-select>
  </form>

  <h4 *ngIf="newProduct"
      class="modal-title">Добавление {{ newProduct }}</h4>

  <app-product-create *ngIf="isCreateProductFormVisible"
                      [productName]="newProduct"
                      [formConfig]="productFormConfig"
                      (OnCancel)="onProductCreateCancel($event)"
                      (OnProductCreate)="onProductCreate($event)"
                      class="product-create"></app-product-create>

</div>
<div class="modal-footer">
  <button type="button"
          class="btn btn-success"
          [disabled]="productForm.invalid || isCreateProductFormVisible"
          (click)="addProduct()">Добавить
  </button>
</div>
