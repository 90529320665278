import {Injectable} from '@angular/core';
import {DataProviderService} from '../../services/data/data-provider.service';
import {first, map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {Order} from '../../models/sale';
import {User} from '../../models/user';


@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private dataProvider: DataProviderService,
  ) {
  }

  public getOrder(id, params?): Observable<Order> {
    return this.dataProvider.getOrder(id, params)
      .pipe(
        first()
      );
  }

  public getUsers(params?): Observable<User[]> {
    return this.dataProvider.getUsers(params)
      .pipe(
        first()
      );
  }

}
