import { Injectable } from '@angular/core';
import {DataProviderService} from '../../../services/data/data-provider.service';


@Injectable({
  providedIn: 'root'
})
export class StockShipmentService {

  constructor(
    private dataProvider: DataProviderService,
  ) { }

  static getFileNameFromHttpResponse(response) {
    const contentDispositionHeader = response.headers.get('Content-Disposition');
    const result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }

  static downloadFile(body, options, filename) {
    const blob = new Blob([body], options);
    const link = document.createElement('a');
    // Browsers that support HTML5 download attribute
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  public getStockShipments() {
    return this.dataProvider.getStockShipments();
  }

  public getStockShipmentDetail(id) {
    return this.dataProvider.getStockShipmentsDetail(id);
  }

  public exportShipmentLetter(data) {
    return this.dataProvider.exportShipmentLetter(data);
  }

}
