<td>
  <input class="form-control" type="text" [(ngModel)]="product.name">
</td>
<!--<td><span class="text-muted">{{ product.category.name }}</span></td>-->
<td class="text-muted">
  <ng-select [items]="productService.categoryList"
             autofocus
             placeholder="Категория"
             bindLabel="name"
             [clearable]="false"
             [hideSelected]="true"
             [loading]="productService.selectLoading"
             [(ngModel)]="product.category">
  </ng-select>
</td>
<td class="text-muted">
  <ng-select [items]="productService.manufacturerList"
             autofocus
             placeholder="Производитель"
             bindLabel="name"
             [clearable]="false"
             [hideSelected]="true"
             [loading]="productService.selectLoading"
             [(ngModel)]="product.manufacturer">
  </ng-select>
</td>
<td>
  {{ product.quantity }}
</td>
<td>
  {{ product.transit_quantity }}
</td>
<td>
  {{ product.reserved_quantity }}
</td>
<td>
  {{ product.ordered_quantity }}
</td>
<td>
    <ng-select [items]="this.unitTypes$ | async"
               class="form-control custom-select"
               [required]="true"
               [searchable]="true"
               [clearable]="false"
               [(ngModel)]="product.unit"
               bindLabel="name"
               bindValue="code"
               required>
    </ng-select>
</td>
<td>
  {{ product.created_at | date: 'dd.MM.yyyy'  }}
</td>
<td>
  <button (click)="saveProduct(product.id, product)" type="button" class="btn btn-outline-success mr-4">
    <i class="fe fe-save"></i> Сохранить
  </button>
  <button (click)="toggleToDisplay()" type="button" class="btn btn-outline-dark mr-4">
    <i class="fe fe-edit"></i> Назад
  </button>
</td>
