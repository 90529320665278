<form [formGroup]="addStockRecordForm">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Добавление количества к {{ product.name }} </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <div class="form-group" [ngClass]="{ 'is-invalid': submitted && f.quantity.errors }">
      <label class="form-label">Количество</label>
      <input type="text" formControlName="quantity" class="form-control" placeholder="Введите количество">
      <div *ngIf="submitted && f.quantity.errors" class="invalid-feedback">
        <div *ngIf="f.quantity.errors.required">Введите количество</div>
      </div>
    </div>

    <label class="form-label">Страна производитель</label>
    <ng-select formControlName="country"
               [items]="countryList"
               placeholder="Страна производитель"
               bindLabel="code"
               bindValue="id"
               [addTag]="false"
               [hideSelected]="true">
    </ng-select>

    <label class="form-label">Адрес на складе</label>
    <ng-select formControlName="address"
               [items]="stockRecordAddresses"
               placeholder="Адрес на складе"
               bindLabel="name"
               bindValue="id"
               [addTag]="false"
               [hideSelected]="true">
    </ng-select>
  </div>
  <div class="modal-footer">
    <img *ngIf="loading" class="pl-2" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpK
            SkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2M
            IOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlF
            UYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmK
            twHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0
            R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6Twf
            wyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSl
            KAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKA
            AAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="/>
    <button [disabled]="loading" type="submit" class="btn btn-outline-success" (click)="onSubmit()">
      Добавить
    </button>
  </div>
</form>
