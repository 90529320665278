import {Component, OnInit} from '@angular/core';
import {FiltersComponentDirective} from '../../../shared/filters/components/filters-component.directive';
import {DataProviderService} from '../../../services/data/data-provider.service';
import {FiltersService} from '../../../shared/filters/services/filters.service';
import {FormBuilder, Validators} from '@angular/forms';
import {NgbDate, NgbCalendar, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n} from '../../../shared/datepicker';
import {formatDate} from '@angular/common';
import {first} from 'rxjs/operators';
import {DictItem} from '../../../models/sale';
import {IssuesService} from '../issues.service';
import {IssueStates} from '../../../models/types/issue.interface';

@Component({
  selector: 'app-issue-filter',
  templateUrl: './issues-filter.component.html',
  styleUrls: ['./issues-filter.component.scss'],
  providers: [
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class IssuesFilterComponent extends FiltersComponentDirective implements OnInit {
  public today = formatDate(new Date(), 'dd.MM.yyyy', 'en');
  public start_date: NgbDate;
  public end_date: NgbDate | null = null;
  public hoveredDate: NgbDate | null = null;
  public issueStates: IssueStates[] = [];

  constructor(
    private dataProvider: DataProviderService,
    private stockFiltersService: FiltersService,
    private issueService: IssuesService,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    filtersService: FiltersService,
    formBuilder: FormBuilder,
  ) {
    super(
      filtersService,
      formBuilder,
    );
    this.start_date = calendar.getToday();
    this.end_date = calendar.getNext(calendar.getToday(), 'd', 10);
  }

  ngOnInit() {
    super.ngOnInit();
    this.getStatuses();
  }

  private getStatuses() {
    this.issueService.getIssueStates().pipe(
      first(),
    )
      .subscribe(resp => {
          this.issueStates = resp;
        }
      );
  }

  getFiltersForm() {
    return this.formBuilder.group({
      order_number: ['', Validators.required],
      state: ['', Validators.required],
      end_date: ['', Validators.required],
      start_date: ['', Validators.required],
    });
  }

  onDateSelection(date: NgbDate) {
    if (!this.start_date && !this.end_date) {
      this.start_date = date;
    } else if (this.start_date && !this.end_date && date.after(this.start_date)) {
      this.end_date = date;
    } else if (this.start_date && !this.end_date && date.equals(this.start_date)) {
      this.end_date = this.start_date;
    } else {
      this.end_date = null;
      this.start_date = date;
    }
    this.filtersForm.controls.start_date.setValue(this.formatter.format(this.start_date));
    this.filtersForm.controls.end_date.setValue(this.formatter.format(this.end_date));
  }

  isHovered(date: NgbDate) {
    return this.start_date &&
      !this.end_date &&
      this.hoveredDate &&
      date.after(this.start_date) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.end_date && date.after(this.start_date) && date.before(this.end_date);
  }

  isRange(date: NgbDate) {
    return date.equals(this.start_date) ||
      (this.end_date && date.equals(this.end_date)) ||
      this.isInside(date) ||
      this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

}
