import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParameterCodec, HttpParams, HttpSentEvent} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {STORAGE_NAME} from './constants';
import {catchError, tap} from 'rxjs/operators';


export interface ApiResponse<T = any> {
  success: boolean;
  result: T;
}

class CustomHttpParamEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  prefix = '';

  constructor(
    private http: HttpClient,
    // public storage: StorageService,
    ) { }

  private static async buildResult(request: Observable<any>): Promise<ApiResponse> {
    try {
      return {
        success: true,
        result: await request.toPromise(),
      };
    } catch (e) {
      return {
        success: false,
        result: e,
      };
    }
  }

  public delete (
    method: Array<string | number>,
  ): Observable<any> {
    return this.http
      .delete<any>(this.prefix + method.join('/'), this.buildOptions())
      .pipe(
        catchError(this.handleError('delete', []))
      );
  }

  public put (
    method: Array<string | number>,
    params: any = null,
    contentType?: string,
    options?: any,
  ): Observable<any> {
    return this.http
      .put<any>(this.prefix + method.join('/'), params, options || this.buildOptions(contentType))
      .pipe(
        catchError(this.handleError('post', []))
      );
  }

  private buildHeaders(contentType?: string) {
    let  headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
    });

    if (contentType && contentType === 'FORM_DATA') {
      headers = headers.delete('Content-Type');
    }

    // if (this.storage) {
    const token = localStorage.getItem(STORAGE_NAME.CURRENT_USER_TOKEN) || '';
    if (token) {
      const authHeader = `Token ${token}`;
      headers = headers.append('Authorization', authHeader);
    }
    // }

    return headers;
  }

  buildOptions(contentType?: string) {
    return {
      withCredentials: true,
      headers: this.buildHeaders(contentType),
    };
  }

  public get (
    method: Array<string | number>,
    params?: any,
    options?: any,
  ): Observable<any> {
    const encoded_params = new HttpParams({ fromObject: params, encoder: new CustomHttpParamEncoder() });
    return this.http
      .get<any>(this.prefix + method.join('/'), Object.assign(options || this.buildOptions(), {params: encoded_params}))
      .pipe(
        catchError(this.handleError('get', []))
      );
  }

  public post (
    method: Array<string | number>,
    params: any = null,
    contentType?: string,
    options?: any,
  ): Observable<any> {
    return this.http
      .post<any>(this.prefix + method.join('/'), params, options || this.buildOptions(contentType))
      .pipe(
        catchError(this.handleError('post', []))
      );
  }

  public patch (
    method: Array<string | number>,
    params: any = null,
    contentType?: string,
    options?: any,
  ): Observable<any> {
    return this.http
      .patch<any>(this.prefix + method.join('/'), params, options || this.buildOptions(contentType))
      .pipe(
        catchError(this.handleError('post', []))
      );
  }

  public getExternal (
    method: Array<string | number>,
    params: any = null,
    contentType?: string,
    options?: any,
  ): Observable<any> {
    const encoded_params = new HttpParams({ fromObject: params, encoder: new CustomHttpParamEncoder() });
    return this.http
      .get<any>(method.join('/'), Object.assign(options || this.buildOptions(), {params: encoded_params}))
      .pipe(
        catchError(this.handleError('get', []))
      );
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      throw error;
    };
  }

}
