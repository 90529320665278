import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {DataProviderService} from '../../../services/data/data-provider.service';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {debounceTime, distinctUntilChanged, first, map, switchMap} from 'rxjs/operators';
import {PaymentList} from '../../../models/types';
import {FormBuilder} from '@angular/forms';
import {PaymentsCreateModalComponent} from '../payments-create-modal/payments-create-modal.component';
import {FiltersService} from '../../../shared/filters/services/filters.service';
import {PaymentsUploadModalComponent} from '../payments-upload-modal/payments-upload-modal.component';

@Component({
  selector: 'app-payments-list',
  templateUrl: './payments-list.component.html',
  styleUrls: ['./payments-list.component.scss']
})
export class PaymentsListComponent implements OnInit, OnDestroy {
  public isLoading = false;
  public paymentsList: PaymentList[] = [];
  public pageSize = 50;
  public collectionSize = undefined;
  private _subscription: Subscription;

  constructor(
    private dataProvider: DataProviderService,
    private filtersService: FiltersService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private modalConfig: NgbModalConfig,
  ) {
    this._subscription = new Subscription();
    modalConfig.backdrop = 'static';
  }

  ngOnInit() {
    this.subscribe();
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  public openCreateModal(): void {
    const modalRef = this.modalService.open(PaymentsCreateModalComponent);
    modalRef.result.finally(async () => {
      this.getList().pipe(first()).subscribe();
    });
  }

  public openUploadModal(): void {
    const modalRef = this.modalService.open(PaymentsUploadModalComponent, { size: 'lg' });
    modalRef.result.finally(async () => {
      this.getList().pipe(first()).subscribe();
    });
  }

  private subscribe() {
    this._subscription.add(
      this.filtersService.filterParams.pipe(
        debounceTime(200),
        distinctUntilChanged(),
        switchMap(params => this.getList(params))
      ).subscribe()
    );
  }

  private getList(params?): Observable<any> {
    this.isLoading = true;
    return this.dataProvider.getPayments(params)
      .pipe(
        first(),
        map(resp => {
          this.paymentsList = resp.results;
          this.collectionSize = resp.count;
          this.isLoading = false;
        }));
  }

  public navigateOrderDetail(id): void {
    this.router.navigate(['/sale', 'order', id]);
  }

}
