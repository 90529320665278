import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {OrderedProductsListComponent} from './ordered-products-list/ordered-products-list.component';
import {RequieredToOrderListComponent} from './requiered-to-order-list/requiered-to-order-list.component';
import {OverdueOrderListComponent} from './overdue-orders-list/overdue-order-list.component';

const routes: Routes = [
  {
    path: 'ordered',
    component: OrderedProductsListComponent
  },
  {
    path: 'required-to-order',
    component: RequieredToOrderListComponent
  },
  {
    path: 'overdue',
    component: OverdueOrderListComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class OrderedProductsListRoutingModule { }
