import {Injectable} from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';

import {AuthService} from '../services/auth/auth.service';
import {STORAGE_NAME} from '../lib/constants';
import {Router} from '@angular/router';
import {tap} from 'rxjs/operators';
import {NotifyMessageService} from '../shared/notify-message/notify-message.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private authService: AuthService,
    private notifierService: NotifyMessageService,
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request.clone()).pipe(
      tap(
        success => {
        },
        err => {
          if (err instanceof HttpErrorResponse && err.error instanceof Blob && err.error.type === 'application/json') {
            this.notifierService.notify('Не удалость выгрузить файл, проверьте фильтры', 'danger');
            return throwError(err);
          }
          if (err.status === 400) {
            const error = err.error.message || err.error || null;
            if (!error) {
              this.notifierService.notify('Системная ошибка, обратитесь к администратору', 'danger');
              return throwError(error);
            }
            // console.log(error.text());
            this.notifierService.notify(error.text().message, 'danger');
          } else {
            const error = err.error.message || err.message;
            this.notifierService.notify('Системная ошибка, обратитесь к администратору', 'danger');
            return throwError(error);
          }
        }
      )
    );
  }
}
