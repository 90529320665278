import { Component, OnInit } from '@angular/core';

import {DataProviderService} from '../../../services/data/data-provider.service';
import {StockRecordAddress} from '../../../models/stock';
import {concat, of} from 'rxjs';
import {Router} from '@angular/router';

@Component({
  selector: 'app-stock-address',
  templateUrl: './stock-record-address.component.html',
  styleUrls: ['./stock-record-address.component.scss']
})
export class StockRecordAddressComponent implements OnInit {
  stockRecordAddresses: StockRecordAddress[];
  loading = false;

  constructor(
    private router: Router,
    private dataProvider: DataProviderService,
    ) { }

  getStockAddress(params?): void {
    this.loading = true;

    this.dataProvider.getStockAddress(params)
      .subscribe(
        addresses => {
          this.stockRecordAddresses = addresses;
          this.loading = false;
        },
        err  => {
          of([]);
          console.log(err);
        },
      );
  }

  navigateStockAddressDetail(id): void {
    this.router.navigate(['/stock', 'address', id]);
  }

  ngOnInit() {
    this.getStockAddress();
  }

}
