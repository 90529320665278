import {
  Component,
  OnInit
} from '@angular/core';
import {NgbActiveModal, NgbDateAdapter, NgbDateParserFormatter, NgbDatepickerI18n} from '@ng-bootstrap/ng-bootstrap';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { DataProviderService } from '../../../services/data/data-provider.service';
import {catchError, debounceTime, distinctUntilChanged, filter, finalize, first, map, share, switchMap, tap} from 'rxjs/operators';
import {concat, Observable, of, Subject} from 'rxjs';
import {Client, Order, Shipper} from '../../../models/sale';
import {DatePipe, formatDate} from '@angular/common';
import {CustomAdapter, CustomDateParserFormatter, CustomDatepickerI18n, I18n, toApiDate} from '../../../shared/datepicker';
import {StockService} from '../../../services/stock/stock.service';

@Component({
  selector: 'app-issues-create-modal',
  templateUrl: './issues-create-modal.component.html',
  styleUrls: ['./issues-create-modal.component.scss'],
  providers: [
    DatePipe,
    I18n, {provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n},
    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}
  ],
})
export class IssuesCreateModalComponent implements OnInit {
  public isLoading = false;
  public createIssueForm: FormGroup;
  public orderList$: Observable<Order[]>;
  public orderInput$ = new Subject<string>();
  public shippersList: Shipper[];
  public shippersInput$ = new Subject<string>();
  public selectLoading = false;
  public today = formatDate(new Date(), 'dd.MM.yyyy', 'en');

  constructor(public activeModal: NgbActiveModal,
              private formBuilder: FormBuilder,
              private stockService: StockService,
              private dataProvider: DataProviderService,
  ) {
  }

  public ngOnInit() {
    this.init();
  }

  public createIssue() {
    this.isLoading = true;
    const data = Object.assign({}, this.createIssueForm.value);
    data.control_date = toApiDate(data.control_date);
    this.dataProvider.createIssue(data)
      .pipe(finalize(() => this.isLoading = false))
      .subscribe(
        () => {
          this.activeModal.close();
        }
      );
  }

  public onCancel() {
    this.activeModal.close();
  }

  private init() {
    this.getOrders();
    this.preloadShippers();
    this.createIssueForm = this.formBuilder.group({
      description: [null, Validators.required],
      control_date: [null, Validators.required],
      order_id: [null, Validators.required],
      shipper_id: [null],
      performer: [null],
    });
  }

  public getOrders() {
    this.orderList$ = concat(
      of([]), // default items
      this.orderInput$.pipe(
        filter(term => {
          return term !== null && term.length >= 3;
        }),
        distinctUntilChanged(),
        debounceTime(400),
        share(),
        tap(() => this.selectLoading = true),
        switchMap(term => this.dataProvider.getOrderList({'order_number': term}).pipe(
          map(resp => {
            return resp.results;
          }),
          catchError(() => of([])), // empty list on error
          tap(() => this.selectLoading = false),
        ))
      ),
    );
  }

  public preloadShippers() {
    this.stockService.getShippers()
      .pipe(
        first(),
        map(resp => {
          return resp.results;
        }))
      .subscribe(
        resp => {
          this.shippersList = resp;
        }
      );
  }

}
