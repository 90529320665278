import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {LogoConfiguration} from '@app/services/configuration/configurations';
import {eventToPromise} from '@app/shared/utils';
import {ConfigurationsService} from '@app/services/configuration/configurations.service';

interface NavLink {
  path: string;
  title: string;
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit {
  public isMenuCollapsed = true;
  public links: NavLink[] = [
    {
      path: '/stock/product',
      title: 'Товары',
    },
    {
      path: '/stock/address',
      title: 'Адреса',
    },
    {
      path: '/stock/manufacturers',
      title: 'Производители',
    },
    {
      path: '/stock/to-shipment',
      title: 'Товары к отгрузке',
    },
    {
      path: '/stock/shipments',
      title: 'Посылки',
    }
  ];
  public logoUrl: string;

  constructor(
    public authService: AuthService,
    private configurationsService: ConfigurationsService,
    ) { }

  ngOnInit() {
    this.initLayoutValues();
  }

  private async initLayoutValues(): Promise<void> {

    if (!this.configurationsService.get(LogoConfiguration)) {
      console.log(1);
      await eventToPromise(this.configurationsService.onPreferencesUpdate);
    }
    this.logoUrl = this.configurationsService.getValue(LogoConfiguration);
  }

  logOut () {
    this.authService.logout();
  }

}
