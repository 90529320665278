<div class="modal-header">
  <h4 class="modal-title">Перерасчет с коеффициентом</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">x
  </button>
</div>
<div class="modal-body">

  <div class="create-form">
    <form [formGroup]="recalculateForm">

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label"
               for="coefficient">Коеффициент</label>
        <div class="col-8">
          <input formControlName="coefficient"
                 class="form-control"
                 placeholder="Введите коеффициент"
                 type="text"
                 id="coefficient"
                 appOnlyNumber/>
        </div>
      </div>

      <div class="form-group row mb-2">
        <label class="col-4 col-form-label">Валюта</label>
        <div class="col-8">
          <ng-select [items]="this.currencyTypes$ | async"
                     class="form-control custom-select"
                     [required]="true"
                     [searchable]="true"
                     [clearable]="false"
                     formControlName="currency"
                     bindLabel="title"
                     bindValue="code"
                     required>
          </ng-select>

        </div>
      </div>


      <div class="row justify-content-end mr-0">
        <button type="button"
                class="btn btn-outline-danger mr-2"
                [disabled]="isLoading"
                (click)="onCancel()">Отменить
        </button>
        <button type="submit"
                class="btn btn-outline-success"
                [disabled]="isLoading || !recalculateForm.valid"
                (click)="recalculate()">Пересчитать
        </button>
      </div>

    </form>

    <ng-template #footerTemplate>
      <hr class="my-0">
      <button class="btn btn-primary btn-sm m-2 float-left"
              (click)="createIssueForm.controls.payment_date.setValue(today); d.close()">Сегодня
      </button>
      <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d.close()">Закрыть</button>
    </ng-template>

  </div>
</div>

