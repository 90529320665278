<div class="page-header">
  <h1 class="page-title">
    {{ title }}
  </h1>

  <div class="col-12">
    <div class="card">
      <div class="card-header justify-content-between">
        <h3 class="card-title">Продукты</h3>
        <div class="card-options">
          <button (click)="createModalOpen()" type="button" class="btn btn-outline-info mr-4">
            <i class="fe fe-plus-square"></i> Добавить продукт
          </button>
        </div>
      </div>
      <div class="filters">
          <app-product-filter></app-product-filter>
      </div>
      <div class="table-responsive">
        <table class="table card-table table-vcenter text-nowrap">
          <thead>
          <tr>
            <th>Наимнование</th>
            <th>Категория</th>
            <th>Производитель</th>
            <th class="w-1">На складе</th>
            <th class="w-1">В пути</th>
            <th class="w-1">В резерве</th>
            <th class="w-1">Заказано</th>
            <th class="w-1">ед. изм</th>
            <th>Создано</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr *ngIf="loading">
            <td colspan="5">
              <app-loading-message>
                Получение товаров
              </app-loading-message>
            </td>
          </tr>
          <ng-container *ngIf="!loading">
            <ng-container *ngFor="let product of products; let i = index">
              <tr *ngIf="isRowDisplay(i)" app-table-display [product]="product" (toggleEdit)="toggleEdit(i);"></tr>
              <tr *ngIf="!isRowDisplay(i)" app-table-edit [product]="product" (toggleDisplay)="toggleEdit(i);"></tr>
            </ng-container>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <ngb-pagination class="d-flex justify-content-center" (pageChange)="paginationPage = $event" [pageSize]="pagination.per_page" [(page)]="pagination.current_page" [collectionSize]="pagination.count" [maxSize]="5"></ngb-pagination>

  </div>

</div>
