import { SafeHtml } from '@angular/platform-browser';

/** Notification Class */
export class Notification {

  id: string;
  message: string;
  style: string;
  duration: number;

  constructor(message: string, style?: string, duration?: number) {
    this.id = '_' + Math.random().toString(36).substr(2, 9); // generate a random number
    this.message = message;
    this.style = style || 'info';
    this.duration = duration;
  }
}

export interface INotification {
  id: string;
  message: string | SafeHtml;
  style?: string;
  duration?: number;
}
